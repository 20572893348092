import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { CloseOutlined } from '@material-ui/icons';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(.5),
    color: theme.palette.secondary,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle>
      <Typography variant="h4">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseOutlined />
      </IconButton>
    </MuiDialogTitle>
  );
});

const NotificationDialog = ({
  open,
  title,
  children,
  width,
  handlePrimary,
  handleClose }) => {
  return (
    <Dialog
      maxWidth={width ? width : 'sm'}
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogTitle onClose={handleClose}>
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePrimary} color='primary'>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
export default NotificationDialog;