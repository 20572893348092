const initState = {
  users: [],
  user: {},
  tenant: {},
  toast: null,
  isProcessing: false,
  isLoaded: false,
};

export const tenantReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_TENANT_STATE':
  case 'RESET_USER_STATE':
    return { 
      ...initState
    };
  case 'RESET_TOAST_STATE':
    return {
      ...state,
      toast: null, 
      isProcessing: false,
    };
  case 'PROCESSING':
    return {
      ...state,
      toast: { type: 'LOADING', message: 'Please wait ...'},
      isProcessing: true,
    };
  case 'FETCH_TENANT':
    return {
      ...state,
      tenant: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,
      isLoaded: true, // This is to make sure we avoid additional fetch calls.
    };  
  case 'FETCH_TENANT_USERS':
    return {
      ...state,
      users: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,
      isLoaded: true, // This is to make sure we avoid additional fetch calls.
    };
  case 'UPDATE_TENANT_SETTINGS':
    return {
      ...state,
      tenant: action.payload,
      toast: {type: 'SUCCESS', message: 'Tenant settings updated successfully.'},
      isProcessing: false,
    };
  case 'UPDATE_TENANT':
    return {
      ...state,
      tenant: action.payload,
      toast: {type: 'SUCCESS', message: 'Tenant updated successfully.'},
      isProcessing: false,
    };
  case 'ADD_USER':
    return {
      ...state,      
      users: [...state.users, action.payload],
      toast: { type: 'SUCCESS', message: 'User saved successfully.'},
      isProcessing: false,
    };     
  case 'UPDATE_USER':
    return {
      ...state,      
      users: [...state.users.filter(item => item.email !== action.payload.email), Object.assign({}, action.payload)],
      user: action.payload,
      toast: { type: 'SUCCESS', message: 'User updated successfully.' },
      isProcessing: false,
    };
  case 'PASSWORD_RESET':      
    return {
      ...state,      
      users: [...state.users.filter(item => item.email !== action.payload.email), Object.assign({}, action.payload)],
      user: action.payload,
      toast: { type: 'SUCCESS', message: 'Password Updated.'},
      isProcessing: false,
    };    
  case 'USER_ERROR':
  case 'TENANT_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};
