import { serviceHandler } from './service-executor';
const NOTIFICATION_URL = '/api/notifications';

export const getOrderStatusCount = (orderStatus) => {
  const URL = `/api/orders/status/${orderStatus}`;
  return serviceHandler(URL, 'GET');
};

export const getInvoiceStatusCount = (status) => {
  const URL = `/api/invoices/status/${status}`;
  return serviceHandler(URL, 'GET');
};

export const getBillStatusCount = (status) => {
  const URL = `/api/bills/status/${status}`;
  return serviceHandler(URL, 'GET');
};

/* Notification Services */
export const getOrderNotifications = (orderId) => {
  const URL = `${NOTIFICATION_URL}/orders/${orderId}`;
  return serviceHandler(URL, 'GET');
};

export const getLatestNotifications = () => {
  const URL = `${NOTIFICATION_URL}/latest`;
  return serviceHandler(URL, 'GET');
};