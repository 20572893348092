import Moment from 'moment';
import { formatEpochToDateTime } from 'utils/formatter.js';

export class Validate {

  static parseOrderSummary(order) {
    const { expenses } = order;

    const summary = {
      broker: order.broker,
      brokerOrderNumber: order.brokerOrderNumber,
      orderType: order.orderType,
      orderRate: order.orderRate,
      fuelCharges: order.fuelCharges,
      fuelChargesType: order.fuelChargesType,
      appointment: order.appointment,
      notes: order.notes,
      expenses: expenses,
    };
    return summary;
  }

  static parseOrderOrigin(input) {
    const origin = {
      name: input,
      appointment: formatEpochToDateTime(input.appointment),
      isOpenTime: input.openTime,
      openAppointment: input.openAppointment,
      puNumber: input.puNumber,
      puLocation: input.puLocation,
      notes: input.notes,
    };
    return origin;
  }

  static parseOrderDestination(input) {
    const destination = {
      name: input,
      appointment: formatEpochToDateTime(input.appointment),
      isOpenTime: input.openTime,
      openAppointment: input.openAppointment,
      referenceNumber: input.referenceNumber,
      notes: input.notes,
    };
    return destination;
  }

  /* Called when we edit order form */
  static parseDispatchOrder(order) {
    const { id, broker, container, chassis, expenses, legs } = order;
    /* Parse Order to construct OrderForm */
    const dispatchOrder = {};
    dispatchOrder.id = id;
    dispatchOrder.summary = {
      broker: broker,
      brokerOrderNumber: order.brokerOrderNumber,
      orderType: order.orderType,
      orderRate: order.orderRate,
      fuelCharges: order.fuelCharges,
      fuelChargesType: order.fuelChargesType,
      appointment: order.appointment,
      notes: order.notes,
      expenses: expenses,
    };
    if (legs) {
      const { origins, destinations } = legs[legs.length - 1]; // Need to check this again.
      dispatchOrder.origin = {
        name: origins.filter(t => t.originalOrigin === true).map(t => t),
        appointment: origins.filter(t => t.originalOrigin === true).map(t => formatEpochToDateTime(t.appointment)),
        isOpenTime: origins.filter(t => t.originalOrigin === true).map(t => t.openTime),
        openAppointment: origins.filter(t => t.originalOrigin === true).map(t => t.openAppointment),
        puNumber: origins.filter(t => t.originalOrigin === true).map(t => t.puNumber),
        puLocation: origins.filter(t => t.originalOrigin === true).map(t => t.puLocation),
        notes: origins.filter(t => t.originalOrigin === true).map(t => t.notes),
      };

      dispatchOrder.originTabs = origins.filter(t => t.originalOrigin === true).map((t, index) => {
        return (`Origin ${index + 1}`);
      });

      dispatchOrder.destination = {
        name: destinations.filter(t => t.originalDestination).map(t => t),
        appointment: destinations.filter(t => t.originalDestination).map(t => formatEpochToDateTime(t.appointment)),
        isOpenTime: destinations.filter(t => t.originalDestination).map(t => t.openTime),
        openAppointment: destinations.filter(t => t.originalDestination).map(t => t.openAppointment),
        referenceNumber: destinations.filter(t => t.originalDestination).map(t => t.referenceNumber),
        notes: destinations.filter(t => t.originalDestination).map(t => t.notes),
      };
      dispatchOrder.destinationTabs = destinations.filter(t => t.originalDestination).map((c, index) => {
        return (`Destination ${index}+1`);
      });
    }
    if (container) {
      dispatchOrder.container = {
        line: container,
        deliveryContainerNumber: container.deliveryContainerNumber,
        pickupContainerNumber: container.pickupContainerNumber,
        notes: container.notes,
        lastFreeDate: container.lastFreeDate === '0' ? null : container.lastFreeDate,
        bookingNumber: container.bookingNumber,
        isCustomDropLocation: container.isCustomDropLocation,
        dropLocation: container.dropLocation,
      };
    }
    if (chassis) {
      dispatchOrder.chassis = {
        company: chassis,
        chassisNumber: chassis.chassisNumber,
        chassisSplit: chassis.isChassisSplit,
      };
    }
    return dispatchOrder;
  }

  static validateFormData(field, data) {
    switch (field) {
    case 'name':
    case 'company':
    case 'firstName':
    case 'lastName':
    case 'fullName':
    case 'licenceNumber':
      return Validate.checkName(data);
    case 'email':
      return Validate.checkEmail(data);
    case 'phone':
      return Validate.checkPhone(data);
    case 'type':
      return Validate.checkType(data);
    case 'accountPlan':
      return Validate.checkAccountPlan(data);
    default:
    }
  }

  static checkType(type) {
    return type.length > 0;
  }

  static checkName(name) {
    return name && name.length >= 1;
  }

  /**
   * Utility method that checks if passed value is valid email
   *
   * @method testEmail
   * @param value
   * @returns {boolean}
   */

  static checkEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  static checkPhone(phone) {
    return phone.length === 14;
  }

  static validateStartAndEndDate(start, end) {
    return Moment(start).isBefore(Moment(end));
  }


  /**
   * Utility method to validate size of characters in the field
   *
   * @method testSize
   * @param {String} value
   * @param {Array} size - Array containing min and max size of the field
   * @returns {boolean}
   */
  static testSize(value, size) {
    let passed = true,
      valueLength = value.length;

    if (valueLength < size.min) {
      passed = false;
    } else {
      //If min size reached, check for max size
      if (size.max) {
        if (valueLength > size.max) {
          passed = false;
        }
      }
    }
    return passed;
  }

  static testSpecialCharacter(value) {
    if (value.length > 0) {
      let re = /[^A-Za-z0-9]+/;
      return !re.test(value);
    }
    return false;
  }

  static testPasswordMatch(password1, password2) {
    if (password1 && password2) {
      return password1 === password2;
    } else {
      return false;
    }
  }

  static testValidBrokerOrderNumber(value) {
    const re = new RegExp('[^A-Za-z0-9 _-]+[^\\s-]');
    return re.test(value);
  }

  static testNonNegativeNumber(number) {
    return Number(number) >= 0;
  }

  static testNonZeroNumber(number) {
    return Number(number) > 0;
  }

  static testOrderAppointmentDate(value) {
    const date = Moment.utc(value).format('MM/DD/YYYY');
    const nowDate = Moment.utc(Moment.now()).format('MM/DD/YYYY');
    return Moment(date).isSameOrAfter(nowDate);
  }

  static isDateAfter(date1, date2) {
    const ramp = Moment.utc(date1);//.format('MM/DD/YYYY');
    const consignee = Moment.utc(date2);//.format('MM/DD/YYYY');
    return Moment(consignee).isSameOrAfter(ramp);
  }

  static validateOrderSummary(order) {
    const { summary } = order;
    // Validate Order Summary
    if (!summary.broker) {
      return 'Broker missing. Please select broker from the dropdown.';
    }
    if (summary.orderType === null) {
      return 'Order Type missing.';
    }
    if (summary.appointment === null) {
      return 'Order Appointment Type missing';
    }
    if (!/[A-Za-z0-9 _-]+[^\s-]/.test(summary.brokerOrderNumber)) { // alphanumeric. no starting space.
      return 'Invalid Broker Order Number.';
    }
    if (!Validate.testNonNegativeNumber(summary.orderRate)) {
      return 'Invalid Order Rate. Order rate cannot be negative.';
    }
    if (!Validate.testNonZeroNumber(summary.orderRate)) {
      return 'Invalid Order Rate. Order rate cannot be 0.';
    }
    return 'SUCCESS';
  }

  static validateOrderLocation(order) {
    const { origin, destination } = order;
    let shipperCount = 0;
    let consigneeCount = 0;
    let isValidOrigin = true;
    let isValidDestination = true;

    if (origin.name) {
      origin.name.forEach(location => {
        if (location) {
          shipperCount++;
        } else {
          isValidOrigin = false;
        }
      });
    }

    if (!isValidOrigin) return 'Origin Location Missing';

    if (destination.name) {
      destination.name.forEach(location => {
        if (location) {
          consigneeCount++;
        } else {
          isValidDestination = false;
        }
      });
    }

    if (!isValidDestination) return 'Destination Location Missing';

    if (shipperCount === consigneeCount) {
      for (let idx = 0; idx < shipperCount; idx++) {
        if (!Validate.isDateAfter(origin.appointment[idx], destination.appointment[idx])) {
          return 'Load pickup appointment cannot be after delivery appointment. ';
        }
      }
    } else {
      const count = shipperCount < consigneeCount ? shipperCount : consigneeCount;
      for (let idx = 0; idx < count; idx++) {
        if (!Validate.isDateAfter(origin.appointment[idx], destination.appointment[idx])) {
          return 'Load pickup appointment cannot be after delivery appointment. ';
        }
      }
    }
    return 'SUCCESS';
  }

  static validateOrderEquipment(order) {
    const { summary, container, chassis } = order;
    if (summary.orderType === 'DELIVERY') {
      if (!container.deliveryContainerNumber || container.deliveryContainerNumber === null) {
        return 'Delivery Container Number missing.';
      }
    }
    if ((summary.orderType === 'DROP&PICKUP' || summary.orderType === 'PICKUP'))
      if (!container.pickupContainerNumber) {
        return 'Pickup Container Number missing. ';
      }
    if (!chassis.chassisNumber) {
      return 'Chassis Number missing. ';
    }
    return 'SUCCESS';
  }
}
