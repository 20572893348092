import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import CTStepper from 'components/CTStepper';
import { formatAppointment } from 'utils/formatter.js';

const ORDER_HISTORY = [
  { id: 'message', sortable: true, label: 'Message', show: true },
  { id: 'user', sortable: true, label: 'User', show: true },
  { id: 'timestamp', sortable: true, label: 'TimeStamp', show: true },
];

const OrderStats = ({ open,
  orderStats,
  orderHistory,
  title,
  fnOnCloseDialogHandler,
  ...rest }) => {
  return (
    title === 'Order Stats' ?
      renderOrderStatsStepper(orderStats) :
      <Card
        {...rest}>
        <PerfectScrollbar>
          {renderOrderNotification(orderHistory)}
        </PerfectScrollbar>
      </Card>
  );
};

const renderOrderStatsStepper = (orderStats) => {
  return <CTStepper stages={orderStats} />;
};

const renderOrderNotification = (orderHistory) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {ORDER_HISTORY.map(header => (
            <TableCell key={header.id}>
              {header.label}
            </TableCell>
          ))
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {orderHistory.map((data, idx) => (
          <TableRow
            hover
            key={idx}
          >
            <TableCell>
              {data.message}
            </TableCell>
            <TableCell>
              {data.username}
            </TableCell>
            <TableCell>
              {formatAppointment(data.timestamp)}
            </TableCell>
          </TableRow>
        )
        )}
      </TableBody>
    </Table>
  );
};

export default OrderStats;