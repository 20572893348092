import axios from 'axios';

// Global defaults

export const serviceHandler = (url, method, data, additionalHeaders) => {
  const headers = {};
  for(let key in additionalHeaders) {
    headers[key] = additionalHeaders[key];
  }
  // Auth Headers
  /* Login call doesn't need the token */
  if(!headers['X-Auth-Username']) {
    if(localStorage.getItem('AUTH_EXP') > Date.now()) {
      headers['X-Auth-Token']=localStorage.getItem('AUTH_TOKEN');
    } else {
      setTimeout(() => {
        window.location = '/login';
      },1500);
      return Promise.reject({message: 'Session Expired', code: 401}).finally(() => { 
        window.location = '/login';
      });
    }
  }
  const config = {
    headers: headers
  };
  // Call the Handlers
  switch (method.toUpperCase()) {
  case 'GET':
    return axios.get(url, config);
  case 'GET_BLOB':
    config['responseType'] = 'arraybuffer';
    return getBlobServiceHandler(url, config);
  case 'POST':
    return axios.post(url, data, config);
  case 'PUT':
    return axios.put(url, data, config);
  case 'DELETE':
    return axios.delete(url, config);
  default:
    break;
  }
};
const getBlobServiceHandler = (url, config) => {
  axios.get(url, config)
    .then(response => {
      // response.data is an empty object
      const blob = new Blob([response.data], {
        type: 'application/pdf',
      });
      const file = new Blob([blob], { type: 'application/pdf' });
      var fileURL = window.URL.createObjectURL(file);
      window.open(fileURL);      
    });
};
