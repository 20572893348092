import { serviceHandler } from './service-executor';

const REPORT_API = '/api/reports';

export const getConsolidatedReport = (startDate, endDate) => {
  const URL = `/api/reports/consolidated?startDate=${startDate}&endDate=${endDate}`;
  return serviceHandler(URL, 'GET');
};

export const getOrderReportByDate = (date) => {
  const URL = `/api/reports/date/${date}`;
  return serviceHandler(URL, 'GET');
};

export const getReportByType = async (type, startDate, endDate) => {
  const URL = `${REPORT_API}/${type}?startDate=${startDate}&endDate=${endDate}`;
  return serviceHandler(URL, 'GET');
}
