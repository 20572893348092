
import React, { useEffect, useState } from 'react';
import { Formik, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import InputField from 'components/Input-Field/input-field';
import SelectField from 'components/Select-Field/select-field.js';
import {
  Box,
  Button,
  Grid,
  Typography,
  Paper,
  TextField,
  FormLabel,
} from '@material-ui/core';
import { AggregateOrderExpenses, calculateOrderTotal } from 'utils/calculations';
import { formatCurrency } from 'utils/formatter';
import CTTabs from 'components/CTTabs.js';
import OrderExpense from 'containers/expenses/order-expense.js';
import DataDialog from 'components/dialogs/DataDialog';
import 'rsuite/dist/rsuite.min.css';
import { DatePicker, Toggle } from 'rsuite';
import '../../../styles/rsuite-component.css';
import CustomSelectField from 'components/Select-Field/custom-select';

const IntermodalOrderForm = (props) => {
  const {
    isEdit,
    orderForm,
    formAction,
    companies,
    brokers,
    equipments,
    saveFormHandler,
    updateFormHandler,
    cancelDialogHandler,
  } = props;


  const [order, setOrder] = useState(orderForm);
  const { id, summary, origin, destination, container, chassis } = order;

  const [expense, setExpense] = useState(order.expense);
  const [expenseAction, setExpenseAction] = useState('Save');
  const [expenseList, setExpenseList] = useState(summary.expenses);
  const [isExpenseDialogOpen, setExpenseDialog] = useState(false);

  const [originTab, setOriginTab] = useState(origin.name.map((o, idx) => `Origin ${idx + 1}`));
  const [destinationTab, setDestinationTab] = useState(destination.name.map((o, idx) => `Destination ${idx + 1}`));

  const [currentDestinationTabIndex, setCurrentDestinationTabIndex] = useState(0);
  const [currentOriginTabIndex, setCurrentOriginTabIndex] = useState(0);

  useEffect(() => {
    console.log('Tab Index: ', currentOriginTabIndex, currentDestinationTabIndex);
  }, [currentOriginTabIndex, currentDestinationTabIndex]);

  const getDisplayLabel = (item, type) => {
    let primaryText, secondaryText;
    if (type === 'CONTAINER') {
      primaryText = item.line ? item.line : item.name;
      secondaryText = `Free Days: ${item.freeDays}`;
    } else if (type === 'CHASSIS') {
      primaryText = item.company ? item.line : item.name;
      secondaryText = `Rent: $${item.rent}`;
    } else if (type === 'DRIVER') {
      /* For driver dropdown in driver pay manager page */
      primaryText = item.fullName;
      secondaryText = item.phone;
    } else if (type === 'BROKER' || type === 'LOCATION') {
      primaryText = item.name;
      secondaryText = item.address && `${item.address.street},${item.address.city},${item.address.state},${item.address.country}`;
    }
    const reactSelectObj = {
      label:<Box key={`${type}:${primaryText}`}>
        <Typography variant='h6'>{primaryText}</Typography>
        <Typography variant='subtitle2' style={{fontSize: '11px'}}>{secondaryText}</Typography>
      </Box>,
      value: primaryText
    };
    return reactSelectObj;
  };

  const addNewTab = (tabType, values) => {
    if (tabType === 'ORIGIN') {
      const { summary, origin, destination, container, chassis } = values; // This is done because of enableReinitialize flag.

      const newOrigin = {};
      newOrigin.name = [...origin.name, ''];
      newOrigin.appointment = [...origin.appointment, new Date()];
      newOrigin.openAppointment = [...origin.openAppointment, ''];
      newOrigin.puNumber = [...origin.puNumber, ''];
      newOrigin.puLocation = [...origin.puLocation, ''];
      newOrigin.notes = [...origin.notes, ''];

      const tabCount = originTab ? originTab.length + 1 : 1;
      const updatedTabs = originTab.concat([`Origin ${tabCount}`]);

      //update the Origin to have new tab and origin and rest will be same.
      order.origin = newOrigin;
      order.summary = summary;
      order.destination = destination;
      order.container = container;
      order.chassis = chassis;

      setOrder(order);
      setCurrentOriginTabIndex(previous => previous + 1);
      setOriginTab(updatedTabs);
    } else {
      const { summary, origin, destination, container, chassis } = values; // This is done because of enableReinitialize flag.

      const newDestination = {};
      newDestination.name = [...destination.name, ''];
      newDestination.appointment = [...destination.appointment, new Date()];
      newDestination.openAppointment = [...destination.openAppointment, ''];
      newDestination.referenceNumber = [...destination.referenceNumber, ''];
      newDestination.notes = [...destination.notes, ''];

      const tabCount = destinationTab ? destinationTab.length + 1 : 1;
      const updatedTabs = destinationTab.concat([`Destination ${tabCount}`]);

      //update the Origin to have new tab and origin and rest will be same.
      order.destination = newDestination;
      order.summary = summary;
      order.origin = origin;
      order.container = container;
      order.chassis = chassis;

      setOrder(order);
      setCurrentDestinationTabIndex(previous => previous + 1);
      setDestinationTab(updatedTabs);
    }
  };

  const removeTab = (tabType, tab) => {
    if (tabType === 'ORIGIN') {
      const newOrigin = {};
      const { name, appointment, openAppointment, puNumber, puLocation, notes } = origin;
      const index = Number(tab.split(' ')[1]) - 1;
      newOrigin.name = name.slice(0, index);
      newOrigin.appointment = appointment.slice(0, index);
      newOrigin.openAppointment = openAppointment.slice(0, index);
      newOrigin.puNumber = puNumber.slice(0, index);
      newOrigin.puLocation = puLocation.slice(0, index);
      newOrigin.notes = notes.slice(0, index);

      const filteredTabs = originTab.filter((o_tab) => {return o_tab !== tab;});

      order.origin = newOrigin;
      setOrder(order);      
      setCurrentOriginTabIndex(previous => previous-1);
      setOriginTab(filteredTabs);
      
    } else if (tabType === 'DESTINATION') {
      const newDestination = {};
      const { name, appointment, openAppointment, referenceNumber, notes } = destination;
      const index = Number(tab.split(' ')[1]) - 1;
      newDestination.name = name.slice(0, index);
      newDestination.appointment = appointment.slice(0, index);
      newDestination.referenceNumber = referenceNumber.slice(0, index);
      newDestination.notes = notes.slice(0, index);
      newDestination.openAppointment = openAppointment.slice(0, index);

      const filteredTabs = destinationTab.filter((d_tab) => {
        return d_tab !== tab;
      });

      order.destination = newDestination;
      setOrder(order);
      setCurrentDestinationTabIndex(previous => previous-1);
      setDestinationTab(filteredTabs);
    }
  };

  const changeTab = (tabType, tabIndex) => {
    if (tabType === 'ORIGIN') {
      setCurrentOriginTabIndex(tabIndex);
    } else if (tabType === 'DESTINATION') {
      setCurrentDestinationTabIndex(tabIndex);
    }
  };

  /* Expenses */
  const editRow = (row) => {
    setExpense(row);
    setExpenseAction('Update');
  };

  const saveExpense = (expense) => {
    expense.id = expenseList.length + 1;
    setExpenseList([...expenseList, expense]);
    setExpense(Object.create(expense, order.expense));
  };

  const updateExpense = (expense) => {
    const index = expenseList.findIndex(e => e.id === expense.id);
    if (index !== -1) {
      const data = expenseList.map(item => item.id === expense.id ? { ...expense } : { ...item });
      setExpenseList(data);
      setExpense(Object.create(expense, order.expense));
    }
  };

  const deleteExpense = (expense) => {
    const index = expenseList.findIndex(e => e.id === expense.id);
    if (index !== -1) {
      const data = [...expenseList.filter((item) => item.id !== expense.id)];
      setExpenseList(data);
    }
  };

  const OrderSchema = Yup.object().shape({
    summary: Yup.object().shape({
      broker: Yup.string().required('Broker is required.'),
      brokerOrderNumber: Yup.string().required('Broker Order Number is required'),
      orderRate: Yup.number()
        .required('Order Rate is required')
        .min(0, 'Order Rate cannot be negative'),
      fuelCharges: Yup.number()
        .required('Fuel charges is required')
        .min(0, 'Fuel Charges cannot be negative'),
    }),
    origin: Yup.object().shape({
      name: Yup.string().required('Origin Name is required.'),
      appointment: Yup.string().required('Origin appointment is required'),
    }),
    destination: Yup.object().shape({
      name: Yup.string().required('Destination Name is required.'),
      appointment: Yup.string().required('Destination appointment is required')
    }),
    // container: Yup.object().shape({
    //   deliveryContainerNumber: Yup.string().required('Delivery Container Number is required.'),
    // }),
  });

  /* If Edit Order */
  if (isEdit) {
    summary.broker = getDisplayLabel(summary.broker, 'BROKER');
    origin.name = origin.name.map(item => getDisplayLabel(item, 'LOCATION'));
    destination.name = destination.name.map(item => getDisplayLabel(item, 'LOCATION'));
    if (container.line) {
      container.line = getDisplayLabel(container.line, 'CONTAINER');
    }
    if (chassis.company) {
      chassis.company = getDisplayLabel(chassis.company, 'CHASSIS');
    }
    if (chassis.pickupChassisCompany) {
      chassis.pickupChassisCompany = getDisplayLabel(chassis.company, 'CHASSIS');
    }
  }
  
  return (
    <Formik
      initialValues={{ summary, origin, destination, container, chassis }}
      enableReinitialize={true}
      validationSchema={OrderSchema}
      onSubmit={(values) => {
        const dispatchOrder = Object.create(null);
        //Check if expenses are added/updated/deleted when creating or updating order.
        values.summary.expenses = expenseList;
        dispatchOrder.summary = values.summary;
        dispatchOrder.summary.orderCreateDate = new Date();
        dispatchOrder.origin = values.origin;
        dispatchOrder.destination = values.destination;
        dispatchOrder.container = values.container;
        dispatchOrder.chassis = values.chassis;
        if (formAction === 'Save') {
          saveFormHandler(dispatchOrder);
        } else {
          dispatchOrder.id = id;
          updateFormHandler(dispatchOrder);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form>
          {/* SUMMARY SECTION  */}
          <Box display='flex' justifyContent='space-between'>
            <Paper
              elevation={1}
              style={{
                padding: '5px',
                marginRight: '5px',
                flex: 4
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} >
                  <Typography variant='subtitle2' style={{marginBottom: '6px'}}>{'Order Summary'}</Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <SelectField
                    fullWidth={true}
                    name='summary.orderType'
                    label={'Select Order Type'}
                    variant='outlined'
                    value={values.summary.orderType}
                    error={Boolean(
                      getIn(touched, 'summary.orderType') &&
                      getIn(errors, 'summary.orderType')
                    )}
                    helperText={Boolean(
                      getIn(touched, 'summary.orderType') &&
                      getIn(errors, 'summary.orderType')
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    options={['DELIVERY', 'PICKUP', 'DROP', 'DROP_PICK']}
                    margin='dense'
                    size='small'
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <FormLabel component='legend' style={{margin: '-5px 0px 5px'}}>Has Appointment</FormLabel>
                  <Toggle
                    name='summary.appointment'
                    value={values.summary.appointment}
                    checkedChildren='YES'
                    unCheckedChildren='NO'
                    defaultChecked={values.summary.appointment}                    
                    onChange={(val) => setFieldValue('summary.appointment', val)}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <CustomSelectField
                    type='BROKER'
                    id='brokers'
                    label='Broker Name'
                    name='summary.broker'
                    data={brokers.map(b => getDisplayLabel(b, 'BROKER'))}
                    value={values.summary.broker}
                    onChange={selected => setFieldValue('summary.broker', selected)}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <InputField
                    type='text'
                    label='Broker Order#'
                    name='summary.brokerOrderNumber'
                    value={values.summary.brokerOrderNumber}
                    variant='outlined'
                    fullWidth
                    error={Boolean(
                      getIn(touched, 'summary.brokerOrderNumber') &&
                      getIn(errors, 'summary.brokerOrderNumber')
                    )}
                    helperText={Boolean(
                      getIn(touched, 'summary.brokerOrderNumber') &&
                      getIn(errors, 'summary.brokerOrderNumber')
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputField
                    type='number'
                    label='Order Rate'
                    name='summary.orderRate'
                    value={values.summary.orderRate}
                    variant='outlined'
                    fullWidth
                    error={Boolean(
                      getIn(touched, 'summary.orderRate') &&
                      getIn(errors, 'summary.orderRate')
                    )}
                    helperText={Boolean(
                      getIn(touched, 'summary.orderRate') &&
                      getIn(errors, 'summary.orderRate')
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputField
                    type='number'
                    label='FSC'
                    name='summary.fuelCharges'
                    value={values.summary.fuelCharges}
                    variant='outlined'
                    fullWidth
                    error={Boolean(
                      getIn(touched, 'summary.fuelCharges') &&
                      getIn(errors, 'summary.fuelCharges')
                    )}
                    helperText={Boolean(
                      getIn(touched, 'summary.fuelCharges') &&
                      getIn(errors, 'summary.fuelCharges')
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                  <Toggle
                    name='summary.fuelChargesType'
                    value={values.summary.fuelChargesType}
                    checkedChildren={'$'}
                    unCheckedChildren={'%'}
                    defaultChecked={values.summary.fuelChargesType === '$'}
                    onChange={checked => {
                      const val = checked ? '$' : '%';
                      setFieldValue('summary.fuelChargesType', val);
                    }
                    }
                    style={{ margin: '6px -50px', color: 'red' }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography
                    color='primary'
                    style={{
                      textDecoration: 'underline',
                      '&:hover': {
                        cursor: 'pointer'
                      },
                    }}
                    onClick={() => setExpenseDialog(true)}
                  >
                    ADD EXPENSE
                  </Typography>
                  <Typography variant='h6'>{formatCurrency(AggregateOrderExpenses(expenseList))}</Typography>
                  <DataDialog
                    open={isExpenseDialogOpen}
                    title={'Expense'}
                    width={'md'}
                    onCloseHandler={() => setExpenseDialog(false)}
                  >
                    <OrderExpense
                      action={expenseAction}
                      expense={expense}
                      expenseList={expenseList}
                      fnOnSaveHandler={(expense) => saveExpense(expense)}
                      fnOnUpdateHandler={(expense) => updateExpense(expense)}
                      fnOnEditHandler={(expense) => editRow(expense)}
                      fnOnDeleteHandler={(expense) => deleteExpense(expense)}
                    />
                  </DataDialog>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant='subtitle2'>Order Total</Typography>
                  <Typography variant='h6'>{formatCurrency(calculateOrderTotal(values.summary.orderRate, values.summary.fuelCharges, values.summary.fuelChargesType, expenseList))}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputField
                    type='text'
                    label='Order Notes'
                    name='summary.notes'
                    value={values.summary.notes}
                    variant='outlined'
                    fullWidth
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper
              elevation={1}
              style={{
                padding: '5px',
                marginLeft: '5px',
                flex: 1
              }}
            >
              <Typography variant='subtitle2'>{'Upload Documents'}</Typography>
            </Paper>
          </Box>

          {/* ORIGIN SECTION  */}
          <Box display='flex' justifyContent='space-between'>
            <Paper
              elevation={1}
              style={{
                padding: '5px',
                marginTop: '5px',
                marginRight: '5px',
                flex: 1,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} >
                  <Typography variant='subtitle2' style={{marginBottom: '6px'}}>{'Order Origin'}</Typography>
                </Grid>                
                <Grid item xs={12} md={12}>
                  <CTTabs
                    labels={originTab}
                    tabIndex={currentOriginTabIndex}
                    addNewButtonEnabled={true}
                    removeButtonEnabled={true}
                    fnOnAddTabHandler={() => addNewTab('ORIGIN', values)}
                    fnOnRemoveTabHandler={tab => removeTab('ORIGIN', tab)}
                    fnOnTabChangeHandler={tabIndex => changeTab('ORIGIN', tabIndex)}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <CustomSelectField
                    type='LOCATION'
                    id='origins'
                    label='Origin Name'
                    name={`origin.name[${currentOriginTabIndex}]`}
                    data={companies.map(c => getDisplayLabel(c, 'LOCATION'))}
                    value={values.origin.name[currentOriginTabIndex]}
                    onChange={selected => setFieldValue(`origin.name[${currentOriginTabIndex}]`, selected)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DatePicker
                    format='MM/dd/yyyy hh:mm aa'
                    showMeridiem
                    showWeekNumbers
                    name={`origin.appointment[${currentOriginTabIndex}]`}
                    value={values.origin.appointment[currentOriginTabIndex]}
                    error={Boolean(
                      getIn(touched, 'origin.appointment') &&
                      getIn(errors, 'origin.appointment')
                    )}
                    helperText={Boolean(
                      getIn(touched, 'origin.appointment') &&
                      getIn(errors, 'origin.appointment')
                    )}
                    onChange={(date) => setFieldValue(`origin.appointment[${currentOriginTabIndex}]`, date)}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputField
                    type='text'
                    label='Open Appointment'
                    name={`origin.openAppointment[${currentOriginTabIndex}]`}
                    value={values.origin.openAppointment[currentOriginTabIndex]}
                    variant='outlined'
                    fullWidth
                    error={Boolean(touched.openAppointment && errors.openAppointment)}
                    helperText={touched.openAppointment && errors.openAppointment}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>                
                <Grid item xs={12} sm={3}>
                  <InputField
                    type='text'
                    label='Enter PU-Number'
                    name={`origin.puNumber[${currentOriginTabIndex}]`}
                    value={values.origin.puNumber[currentOriginTabIndex]}
                    variant='outlined'
                    fullWidth
                    error={Boolean(touched.puNumber && errors.puNumber)}
                    helperText={touched.puNumber && errors.puNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputField
                    type='text'
                    label='Enter PU-Location'
                    name={`origin.puLocation[${currentOriginTabIndex}]`}
                    value={values.origin.puLocation[currentOriginTabIndex]}
                    variant='outlined'
                    fullWidth
                    error={Boolean(touched.puLocation && errors.puLocation)}
                    helperText={touched.puLocation && errors.puLocation}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>              
                <Grid item xs={12} sm={6}>
                  <InputField
                    label='Notes / Comments'
                    type='text'
                    name={`origin.notes[${currentOriginTabIndex}]`}
                    value={values.origin.notes[currentOriginTabIndex]}
                    variant='outlined'
                    fullWidth
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>
              </Grid>
            </Paper>
            {/* DESTINATION SECTION  */}
            <Paper
              elevation={3}
              style={{
                padding: '5px',
                marginTop: '5px',
                marginLeft: '5px',
                flex: 1,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} >
                  <Typography variant='subtitle2' style={{marginBottom: '6px'}}>{'Order Destination'}</Typography>
                </Grid>                
                <Grid item xs={12} md={12}>
                  <CTTabs
                    labels={destinationTab}
                    tabIndex={currentDestinationTabIndex}
                    addNewButtonEnabled={true}
                    removeButtonEnabled={true}
                    fnOnAddTabHandler={() => addNewTab('DESTINATION', values)}
                    fnOnRemoveTabHandler={(tab) => removeTab('DESTINATION', tab)}
                    fnOnTabChangeHandler={(tabIndex) => changeTab('DESTINATION', tabIndex)}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <CustomSelectField
                    type='LOCATION'
                    id='destinations'
                    label='Destination Name'
                    name={`destination.name[${currentDestinationTabIndex}]`}
                    data={companies.map(c => getDisplayLabel(c, 'LOCATION'))}
                    value={values.destination.name[currentDestinationTabIndex]}
                    onChange={selected => setFieldValue(`destination.name[${currentDestinationTabIndex}]`, selected)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DatePicker
                    format='MM/dd/yyyy hh:mm aa'
                    showMeridiem
                    showWeekNumbers
                    name={`destination.appointment[${currentDestinationTabIndex}]`}
                    value={values.destination.appointment[currentDestinationTabIndex]}
                    error={Boolean(
                      getIn(touched, 'destination.appointment') &&
                      getIn(errors, 'destination.appointment')
                    )}
                    helperText={Boolean(
                      getIn(touched, 'destination.appointment') &&
                      getIn(errors, 'destination.appointment')
                    )}
                    onChange={(date) => setFieldValue(`destination.appointment[${currentDestinationTabIndex}]`, date)}
                    required={true}                    
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputField
                    type='text'
                    label='Reference Number'
                    name={`destination.referenceNumber[${currentDestinationTabIndex}]`}
                    value={values.destination.referenceNumber[currentDestinationTabIndex]}
                    variant='outlined'
                    fullWidth
                    error={Boolean(touched.referenceNumber && errors.referenceNumber)}
                    helperText={touched.referenceNumber && errors.referenceNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>               
                <Grid item xs={12} sm={3}>
                  <InputField
                    type='text'
                    label='Open Appointment'
                    name={`destination.openAppointment[${currentDestinationTabIndex}]`}
                    value={values.destination.openAppointment[currentDestinationTabIndex]}
                    variant='outlined'
                    fullWidth
                    error={Boolean(touched.openAppointment && errors.openAppointment)}
                    helperText={touched.openAppointment && errors.openAppointment}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    label='Notes / Comments'
                    type='text'
                    name={`destination.notes[${currentDestinationTabIndex}]`}
                    value={values.destination.notes[currentDestinationTabIndex]}
                    variant='outlined'
                    fullWidth
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>

          {/* EQUIPMENT SECTION  */}
          <Box display='flex' justifyContent='space-between'>
            {/* CONTAINER */}
            <Paper
              elevation={3}
              style={{
                padding: '5px',
                marginTop: '5px',
                marginRight: '5px',
                flex: 1,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} >
                  <Typography variant='subtitle2' style={{marginBottom: '6px'}}>{'Container'}</Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <CustomSelectField
                    type='CONTAINER'
                    id='containers'
                    label='Container Line'
                    name='container.line'
                    data={equipments.filter(e=>e.type==='CONTAINER').map(b => getDisplayLabel(b, 'CONTAINER'))}
                    value={values.container.line}
                    onChange={selected => setFieldValue('container.line', selected)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputField
                    type='text'
                    label='Delivery Container Number'
                    name='container.deliveryContainerNumber'
                    value={values.container.deliveryContainerNumber}
                    variant='outlined'
                    fullWidth
                    error={Boolean(
                      getIn(touched, 'container.deliveryContainerNumber') &&
                      getIn(errors, 'container.deliveryContainerNumber')
                    )}
                    helperText={Boolean(
                      getIn(touched, 'container.deliveryContainerNumber') &&
                      getIn(errors, 'container.deliveryContainerNumber')
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                    disabled={values.summary.orderType === 'PICKUP'}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id='date'
                    label='Last Free Day'
                    type='date'
                    name='container.lastFreeDate'
                    defaultValue={values.container.lastFreeDate}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth={true}
                    margin='dense'
                    variant='outlined'
                    error={Boolean(touched.lastFreeDate && errors.lastFreeDate)}
                    helperText={touched.lastFreeDate && errors.lastFreeDate}
                    style={{
                      marginTop: '0px'
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputField
                    type='text'
                    label='Pickup Container Number'
                    name='container.pickupContainerNumber'
                    value={values.container.pickupContainerNumber}
                    variant='outlined'
                    fullWidth
                    error={Boolean(
                      getIn(touched, 'container.pickupContainerNumber') &&
                      getIn(errors, 'container.pickupContainerNumber')
                    )}
                    helperText={Boolean(
                      getIn(touched, 'container.pickupContainerNumber') &&
                      getIn(errors, 'container.pickupContainerNumber')
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                    disabled={values.summary.orderType === 'DELIVERY'}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputField
                    type='text'
                    label='Booking Number'
                    name='container.bookingNumber'
                    onChange={handleChange}
                    value={values.container.bookingNumber}
                    fullWidth={true}
                    required={false}
                    disabled={values.summary.orderType !== 'PICKUP'}
                    margin='dense'
                    size='small'
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormLabel component='legend' style={{margin: '-5px 0px 5px'}}>Is DropLocation</FormLabel>
                  <Toggle
                    name='container.isCustomDropLocation'
                    value={values.container.isCustomDropLocation}
                    checkedChildren='YES'
                    unCheckedChildren='NO'
                    defaultValue={false}                    
                    onChange={(val) => setFieldValue('container.isCustomDropLocation', val)}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    type='text'
                    name='container.dropLocation'
                    label='Drop Location'
                    value={values.container.dropLocation}
                    fullWidth={true}
                    disabled={values.container.isCustomDropLocation === false}
                    error={Boolean(touched.dropLocation && errors.dropLocation)}
                    helperText={touched.dropLocation && errors.dropLocation}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    type='text'
                    name='container.notes'
                    label='Container Notes'
                    value={values.container.notes}
                    fullWidth={true}
                    required={false}
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>
              </Grid>
            </Paper>
            {/* CHASSIS */}
            <Paper
              elevation={3}
              style={{
                padding: '5px',
                marginTop: '5px',
                marginLeft: '5px',
                flex: 1,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} >
                  <Typography variant='subtitle2' style={{marginBottom: '6px'}}>{'Chassis'}</Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <CustomSelectField
                    type='CHASSIS'
                    id='chassis'
                    label='Chassis Company'
                    name='chassis.company'
                    data={equipments.filter(e=>e.type==='CHASSIS').map(b => getDisplayLabel(b, 'CHASSIS'))}
                    value={values.chassis.company}
                    onChange={selected => setFieldValue('chassis.company', selected)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputField
                    type='text'
                    label='Chassis Number'
                    name='chassis.chassisNumber'
                    value={values.chassis.chassisNumber}
                    variant='outlined'
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormLabel component='legend' style={{margin: '-5px 0px 5px'}}>Chassis Split</FormLabel>
                  <Toggle
                    name='chassis.isChassisSplit'
                    value={values.chassis.isChassisSplit}
                    checkedChildren='YES'
                    unCheckedChildren='NO'
                    defaultValue={false}                    
                    onChange={(val) => setFieldValue('chassis.isChassisSplit', val)}
                    fullWidth={true}
                  />
                </Grid>
                {values.summary.orderType === 'DROP_PICK' ? <React.Fragment>
                  <Grid item xs={12} sm={5}>
                    <CustomSelectField
                      type='CHASSIS'
                      id='chassis'
                      label='Pickup Chassis Company'
                      name='chassis.pickupChassisCompany'
                      data={equipments.filter(e=>e.type==='CHASSIS').map(b => getDisplayLabel(b, 'CHASSIS'))}
                      value={values.chassis.pickupChassisCompany}
                      onChange={selected => setFieldValue('chassis.pickupChassisCompany', selected)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputField
                      type='text'
                      label='Pickup Chassis Number'
                      name='chassis.pickupChassisNumber'
                      value={values.chassis.pickupChassisNumber}
                      variant='outlined'
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      margin='dense'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormLabel component='legend' style={{margin: '-5px 0px 5px'}}>Pickup Chassis Split</FormLabel>
                    <Toggle
                      name='chassis.isPickupChassisSplit'
                      value={values.chassis.isPickupChassisSplit}
                      checkedChildren='YES'
                      unCheckedChildren='NO'
                      defaultValue={false}                      
                      onChange={(val) => setFieldValue('chassis.isPickupChassisSplit', val)}                      
                    />
                  </Grid>
                </React.Fragment> : null}
              </Grid>
            </Paper>
          </Box>

          <Box display='flex' justifyContent='flex-end' p={2}>
            <Button onClick={() => cancelDialogHandler()}>Cancel</Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
            >
              {formAction}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
function mapStatesToProps(state) {
  // Whatevet is returned will show up as props
  const { brokers } = state.brokerReducer;
  const { companies } = state.companyReducer;
  const { equipments } = state.equipmentReducer;
  return {
    brokers, companies, equipments
  };
}

export default connect(mapStatesToProps)(IntermodalOrderForm);