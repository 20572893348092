import React from 'react';
import {
  Box,
  Typography,

} from '@material-ui/core';
import theme from 'theme';

const ToastHandler = (props) => {
  const { toast, type } = props;

  const getToastColor = (type) => {
    switch (type) {
    case 'INFO':
      return theme.palette.info.main;
    case 'WARNING':
      return theme.palette.warning.main;
    case 'ERROR':
      return theme.palette.error.main;
    case 'SUCCESS':
      return theme.palette.success.main;
    }
  };
  return (
    <Box      
      hidden={toast === null || toast.message === undefined}
    >
      <Typography 
        style={{
        background: toast.message === "" ? 'none' : getToastColor(type),
        margin: 'auto',
        textAlign: 'center',
        letterSpacing: '1px',
        color: '#fff',
        padding: '8px',
        width: 'fit-content',
        textTransform: 'capitalize',
        borderRadius: '3px',
        fontWeight: 600,
      }}
      >
        {toast.message}
      </Typography>
    </Box>
  );
};

export default ToastHandler;
