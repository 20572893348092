import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Box,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import { EXPENSE_TYPE, BOOLEAN_OPTION } from 'utils/constant.js';
import InputField from 'components/Input-Field/input-field';
import SelectField from 'components/Select-Field/select-field';
import { DataGrid } from '@mui/x-data-grid';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';

const OrderExpense = ({action,
  expense,
  expenseList,
  fnOnSaveHandler,
  fnOnUpdateHandler,
  fnOnEditHandler,
  fnOnDeleteHandler,
}) => {
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  const columns = [
    { field: 'id', headerName: 'Id' },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    { field: 'expenseDate', headerName: 'Expense Date', flex: 1 },
    { field: 'expenseType', headerName: 'Type', flex: 1 },
    { field: 'billableAmount', headerName: 'Bill Amount', flex: 1 },
    { field: 'companyShare', headerName: 'Company Share', flex: 1 },
    { field: 'totalAmount', headerName: 'Total', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton size='small' onClick={() => editRow(params.row)}>
              <EditOutlined fontSize='small' color='primary' />
            </IconButton>
            <IconButton size='small' onClick={() => deleteRow(params.row)}>
              <DeleteOutline fontSize='small' color='secondary' />
            </IconButton>
          </>
        );
      },
    },
  ];

  const formik = useFormik({
    initialValues: { ...expense },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().min(2).max(100).required('Expense Name is required.'),
      billableAmount: Yup.number().required('Expense Amount is required'),
      expenseType: Yup.string().max(16).required('Expense Type is required.'),
    }),

    onSubmit: values => {
      const trimmed_data = {};
      Object.keys(values).forEach(key => trimmed_data[key] = typeof (values[key]) === 'string' ? values[key].trim() : values[key]);
      action === 'Save' ? fnOnSaveHandler(trimmed_data) : fnOnUpdateHandler(trimmed_data);
    },
  });

  const editRow = (row) => {
    formik.initialValues = { ...row };
    fnOnEditHandler(row);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmationDialogOpen(true);
  };

  const processDelete = () => {
    fnOnDeleteHandler(selectedRow);
    setConfirmationDialogOpen(false);
  };

  const renderExpenseForm = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <InputField
              label='Expense Name'
              name='name'
              value={formik.values.name}
              variant='outlined'
              fullWidth
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              label='Billable Amount'
              name='billableAmount'
              type='number'
              value={formik.values.billableAmount}
              variant='outlined'
              fullWidth
              error={Boolean(formik.touched.billableAmount && formik.errors.billableAmount)}
              helperText={formik.touched.billableAmount && formik.errors.billableAmount}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              label='Company Share'
              name='companyShare'
              type='number'
              value={formik.values.companyShare}
              variant='outlined'
              fullWidth
              error={Boolean(formik.touched.companyShare && formik.errors.companyShare)}
              helperText={formik.touched.companyShare && formik.errors.companyShare}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label='Select ExpenseType'
              name='expenseType'
              select
              value={formik.values.expenseType}
              variant='outlined'
              fullWidth
              error={Boolean(formik.touched.expenseType && formik.errors.expenseType)}
              helperText={formik.touched.expenseType && formik.errors.expenseType}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={EXPENSE_TYPE}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label='Is PerDay?'
              name='isPerDay'
              select
              value={formik.values.isPerDay}
              variant='outlined'
              fullWidth
              error={Boolean(formik.touched.isPerDay && formik.errors.isPerDay)}
              helperText={formik.touched.isPerDay && formik.errors.isPerDay}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={BOOLEAN_OPTION}
            />
          </Grid>
        </Grid>
        <Box
          display='flex'
          justifyContent='flex-end'
          p={2}>
          {action === 'Save' ?
            <Button
              type='submit'
              variant='contained'
              color='primary'
            >
              Save Expense
            </Button> :
            <Button
              type='submit'
              variant='contained'
              color='primary'
            >
              Update Expense
            </Button>
          }
        </Box>
      </form>
    );
  };

  return (
    <React.Fragment>
      <Box
        m='10px 0 0 0'
      >
        {renderExpenseForm()}
      </Box>
      <Box
        m='20px 0 0 0'
        height='40vh'
      >
        <DataGrid
          rows={expenseList || []}
          columns={columns}
          rowHeight={40}
          disableSelectionOnClick
        />
      </Box>
      {selectedRow &&
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          title={'Delete Expense'}
          handleConfirm={() => processDelete()}
          handleCancel={() => setConfirmationDialogOpen(false)}
          handleCloseFunc={() => setConfirmationDialogOpen(false)}
        >
          <Typography variant='subtitle2'>{`Do you want to Delete Expense: ${selectedRow && selectedRow.name} ?`}</Typography>
        </ConfirmationDialog>}
    </React.Fragment>
  );
};

export default OrderExpense;