/* eslint-disable no-case-declarations */
const initState = {
  invoices: [],
  searched: [],
  invoice: {},
  document: {},
  toast: null,
  isProcessing: false,  
};

let failed; 
let success;
let messages;
let paymentStatus;

export const invoiceReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_INVOICE_STATE':
    return { 
      ...initState,
    };
  case 'RESET_TOAST_STATE':
    return {
      ...state,
      toast: null, 
      isProcessing: false,
    };
  case 'PROCESSING_INVOICE':
    return {
      ...state,
      toast: { type: 'LOADING', message: 'Please wait ...'},
      isProcessing: true,
    };

  case 'FETCH_INVOICES':
    return {
      ...state,
      searched: [],
      invoices: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,
    };
  case 'SEARCH_INVOICES':
    return {
      ...state,
      searched: action.payload,
      toast: {type: Object.keys(action.payload).length === 0 ? 'WARNING' : 'SUCCESS', message: Object.keys(action.payload).length === 0 ? 'No Invoices Found.' : 'Found Invoices'},
      isProcessing: false,
    };
  /* Following code snippet works for data in the header */
  // case 'GENERATE_INVOICE':
  //   failed = [];  
  //   success = [];
  //   messages = [];     
  //   const header = action.payload.headers['generated-invoices'].substr(1, action.payload.headers['generated-invoices'].length-2);
  //   const generatedInvoices = header.split(','); //how many invoices we are generating.
  //   generatedInvoices.forEach(gInv => {
  //     const id = Number(gInv.split('=')[0]);
  //     const msg = gInv.split('=')[1];
  //     msg.includes('ERROR') ? failed.push(id) : success.push(id);
  //     messages.push(`Invoice ${id} ${msg}`);
  //   });
  //   return {
  //     ...state,
  //     invoices: [...state.invoices.map(inv => success.includes(inv.id) ? {...inv, paymentStatus: 'INVOICED'} : {...inv})],
  //     toast: { type: 'SUCCESS', action: action.type, message: messages},
  //     isProcessing: false,
  //   };
  case 'GENERATE_INVOICE':  
  case 'UN_INVOICE':
  case 'PROCESS_PAYMENT':
    failed = [];  
    success = [];
    messages = [];    
    Object.entries(action.payload).forEach(entry => {  
      const id = Number(entry[0]);
      const msg = entry[1];
      msg.includes('ERROR') ? failed.push(id) : success.push(id);
      messages.push(`Invoice ${id} ${msg}`);      
    });
    if(action.type === 'GENERATE_INVOICE') {
      paymentStatus = 'INVOICED';
    } else if(action.type === 'UN_INVOICE') {
      paymentStatus = 'UNPAID';
    } else {
      paymentStatus = 'RECEIVED';
    }
    return {
      ...state,
      invoices: [...state.invoices.map(inv => success.includes(inv.id) ? {...inv, paymentStatus: paymentStatus} : {...inv})],
      toast: { type: 'SUCCESS', action: action.type, message: messages},
      isProcessing: false,
    };

  case 'ADD_INVOICE':
    return {
      ...state,      
      invoices: [...state.invoices, action.payload],
      toast: { type: 'SUCCESS', message: 'Invoice saved successfully.'},
      isProcessing: false,
    };     
  case 'UPDATE_INVOICE':
    return {
      ...state,      
      invoices: [...state.invoices.filter(item => item.id !== action.payload.id), Object.assign({}, action.payload)], 
      toast: { type: 'SUCCESS', message: 'Invoice updated successfully.' },
      isProcessing: false,
    };
  case 'INVOICE_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};
