import React from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles} from '@material-ui/core';

import { connect } from 'react-redux';
import MenuDrawer from './drawer';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import { getRemainingPaymentDays } from 'utils/formatter.js';

const PAYMENT_MESSAGE = 'days left to make next payment. Please contact Chuck Truck team for payment arrangements.';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    flexShrink: 1,
  },
  contentContainer: {
    flexGrow: 12,
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    margin: '0px 10px',
    padding: '8px'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const DashboardLayout = ({ users, toast, tenant }) => {
  const classes = useStyles();    
  let toastElement = null;
  let loggedUserInitial = null;
  if(users) {
    const loggedUser = users.filter(u => u.email === localStorage.ACTIVE_USER_EMAIL)[0];
    if(loggedUser) {
      loggedUserInitial = loggedUser.firstName[0]+loggedUser.lastName[0];
    }
    if (tenant && tenant.nextPaymentDate) {
      const paymentDays = getRemainingPaymentDays(tenant.nextPaymentDate);
      if (paymentDays === 0 || paymentDays < 0) {
        toast.message = 'Payment Overdue. Please contact Chuck Truck team for payment arrangements.';
        toast.type = 'HALT';
      } else if (paymentDays < 3) {
        toast.message = `${paymentDays} ${PAYMENT_MESSAGE}`;
        toast.type = 'WARNING';
      }
      toastElement = paymentDays > 5 ? null : <ToastHandler component="DashboardLayout"
        toast={toast}
        type={toast.type} />;
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <MenuDrawer tenant={tenant}
          userInitial={loggedUserInitial} />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          {toastElement}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { loggedUser } = state.loginReducer;
  const { tenant, users, isProcessing, toast, } = state.tenantReducer;
  return {
    loggedUser,
    tenant,
    users,
    toast,
    isProcessing
  };
}


export default connect(mapStateToProps,null)(DashboardLayout);