import React from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { CloseOutlined } from '@material-ui/icons';
import ToastHandler from 'components/Toast-Handler/toast-handler';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, toast, isProcessing, onClose } = props;
  return (
    <MuiDialogTitle>
      <Box display='flex' justifyContent='space-between' style={{ margin: 0 }}>
        <Typography variant='h4'>{children}</Typography>
        {isProcessing && <CircularProgress style={{margin: 'auto'}} />}
        {toast && toast.type ? <ToastHandler toast={toast} type={toast.type} /> : ''}  { /*This needs to be included with all forms */}              
        <IconButton aria-label='close' size='small' onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </Box>
    </MuiDialogTitle>
  );
});

const DataDialog = ({
  open,
  title,
  children,
  width,
  isProcessing,
  toast,  
  onCloseHandler,
}) => {
  return (
    <Dialog
      maxWidth={width ? width : 'md'}
      fullWidth
      open={open}
      onClose={onCloseHandler}
    >
      <DialogTitle
        toast={toast}
        isProcessing={isProcessing}
        onClose={onCloseHandler}
      >
        {title}
      </DialogTitle>
      <Divider style={{ marginBottom: '5px' }} />
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};
export default DataDialog;