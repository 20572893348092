import { makeStyles } from '@material-ui/core/styles';

const globalStyle = makeStyles((theme) => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
  },
  'rootContainer': {
    paddingLeft: 8,
    paddingRight: 8
  },
  'cardContent': {
    display: 'flex',
    padding: 8,
    '&:last-child': {
      paddingBottom: 12
    }
  },
  'activeCardItem': {
    color: '#ff9100',
    backgroundColor: '#ff9100'
  },
  'actionIconButton': {
    padding: 4,
    color: theme.palette.primary.main,
  },
  'actionButton': {
    height: 35,
    minWidth: 'fit-content',
    marginLeft: 10,
    marginRight: 10,
  },
  'customPaymentTextBox': {
    minWidth: 75,
  },
  actionLink: {
    color: '#2196F3',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  formattedData: {
    textDecoration: 'underline dotted',
    letterSpacing: 1
  },
  table: {
    margin: 'auto'
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
  },
  tableHeaderCell: {
    color: '#000',
    fontWeight: 900,
  },
  tableCell: {
    color: '#000',
    fontSize: 12,
  },
  open: {
    backgroundColor: 'blue', 
    color: 'white'
  },
  progress: {
    backgroundColor: 'orange', 
    color: 'white'
  },
  yard: {
    backgroundColor: 'red', 
    color: 'white'
  },
  complete: {
    backgroundColor: 'green', 
    color: 'white'
  },
  invoiced: {
    backgroundColor: 'green', 
    color: 'white'
  },
  isSelected: {
    backgroundColor: '#2196f373'
  },
  fileDataRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  fileDesc: {
    marginTop: 0,
    marginBottom: 0,
    width: 225,
    height: 35
  },
  fileUploadBtn: {
    marginLeft: 5,
    marginRight: 0,
    height: 35
  },
  fileUploadSelectBtn: {
    height: 35
  },
  swimlaneBox: {
    marginTop: 8,
    width: '100%',
    minHeight: '300px',
    backgroundColor: '#fafafa',
    overflowY: 'visible',
  },
  swimlaneHeader: {
    borderBottom: ''
  },
  swimlaneBoxDivider: {
    backgroundImage: 'linear-gradient(90deg, hsl(0deg 0% 80%), hsl(0deg 0% 60%), hsl(0deg 0% 80%))'
  }
}));

export default globalStyle;