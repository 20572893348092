import React, { Component } from 'react';
import Page from 'components/Page';
import CTTabs from 'components/CTTabs';
import * as DriverAction from 'actions/driver-action';
import FileUploader from 'components/FileUploader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataDialog from 'components/dialogs/DataDialog';
import DriverForm from './driver-form';
import Topbar from 'containers/global/Topbar';
import { Box, Button, ButtonGroup, Chip, CircularProgress, Divider, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, EditOutlined, MoreVert } from '@material-ui/icons';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import Searchbar from 'containers/global/Searchbar';
import Notificationbar from 'containers/global/Notificationbar';
import theme from 'theme';

const TABS = ['AVAILABLE', 'UNAVAILABLE'];

class DriverPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      action: 'Save',
      tabIndex: 0,      
      isDataDialogOpen: false,
      isFileLoaderDialogOpen: false,
      anchorEl: null,
      selectedRow: null,
      isSearchEnabled: false,
      searchParameters: {},
    };
  }

  get formState() {
    return {
      driver: {
        fullName: '',
        email: '',
        phone: '',
        licenseNumber: '',
        licenseState: '',
        licenseExpiryDate: '',
        driverType: '',
        insuranceCarrier: '',
        insuredName: '',
        policyNumber: '',
        policyExpiryDate:'',
        certificateHolder:'',
        city: '',
        state: '',
        country: '',
        isAvailable: true,
      },
    };
  }

  columns = [
    { field: 'id', headerName: 'Id', flex: 0.5 },
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 1,
    },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'licenseNumber', headerName: 'License', flex: 1 },
    { field: 'licenseExpiryDate', headerName: 'LicenseExpiryDate', flex: 1 },
    { field: 'insuranceStatus', headerName: 'Insurance', flex: 1 },
    {
      field: 'city',
      headerName: 'City',
      flex: 1,
      valueGetter: (params) => params.row.address.city,
    },
    {
      field: 'state',
      headerName: 'State',
      flex: 1,
      valueGetter: (params) => params.row.address.state,
    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 1,
      valueGetter: (params) => params.row.address.country,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              size='small'
              color={'primary'}
              aria-label="more"
              aria-controls="long-menu"
              onClick={(evt) => this.handleMenuClick(evt, params.row)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.closeDialog}
            >
              <MenuItem key="edit" onClick={() => this.editRow(this.state.selectedRow)}>
                <EditOutlined fontSize="small" color='primary' />&nbsp;&nbsp;Edit Driver</MenuItem>
              <Divider />              
            </Menu>
          </>
        );
      },
    },
  ];

  /* Search */
  /* 
    1. User click on search icon to call executeSearch function.
    2. If status is 200, we will display the search results.
    3. A Chip with onDelete property will be created based on {key:value} in the search Parameters
  */
  executeSearch = (searchParameters) => {
    if (searchParameters && Object.entries(searchParameters).length > 0) {
      const value = searchParameters['name'];
      this.setState({
        isSearchEnabled : true,
        searchParameters: searchParameters
      });
      this.props.search(value);
    } else {
      this.setState({
        isSearchEnabled : false,
        searchParameters: {}
      });      
    }
  };

  /* Create Search Chip */
  createSearchChip = () => {
    const kvpair = Object.entries(this.state.searchParameters).map(([k, v]) => ({ 'key': k, 'value': v }));
    const chips = kvpair.map(item => {
      return <Chip key={item.key}
        label={item.value}
        onDelete={(evt) => this.deleteSearchChip(evt, item.key)}
        color='secondary'
        size='small'
      />;
    });
    return chips;
  };
  /* Delete Search Chip and execute Search */
  deleteSearchChip = (evt, key) => {
    const sp = this.state.searchParameters;    
    delete sp[key];

    // Execute search on remaining search items if any.
    this.executeSearch(sp);
  };

  saveForm = (driver) => {
    this.props.save(driver);
  };

  updateForm = (driver) => {
    this.props.update(driver);
  };

  toggleFormDialog = () => {
    const { isDataDialogOpen } = this.state;
    this.setState({ isDataDialogOpen: !isDataDialogOpen });
  };

  closeDialog = () => {
    this.setState({
      ...this.formState,
      isDataDialogOpen: false,
      isFileLoaderDialogOpen: false,
      anchorEl: null,
      selectedRow: null,
      action: 'Save'
    });
    /* Reset TOAST State */
    this.props.resetState('RESET_TOAST_STATE');    
  };

  editRow = (row) => {
    this.setState({
      isDataDialogOpen: true,
      action: 'Update',
      driver: { ...row, ...row.address }
    });
  };

  deleteRow = (row) => {
    alert('Delete Clicked: ' + row);
  };

  toggleActiveStatus = (id) => {
    this.props.changeStatus(id);
  };

  changeTab = (tabIndex) => {
    this.setState({ tabIndex });
  };

  handleMenuClick = (evt, data) => {
    this.setState({
      anchorEl: evt.currentTarget,
      selectedRow: data
    });
  };

  downloadTemplate = () => {
    const filename = 'driver_template.csv';
    const headers = ['name', 'email', 'phone', 'contact_name', 'street', 'city', 'state', 'country', 'zipcode', 'is_active'];
    let csvContent = 'data:text/csv;charset=utf-8,' + headers;
    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  };

  downloadDrivers = () => {
    const filename = 'drivers.csv';
    const { drivers } = this.props;

    const csvData = [];
    const headers = ['name', 'email', 'phone', 'contact_name', 'street', 'city', 'state', 'country', 'zipcode', 'is_active'];
    csvData.push(headers);

    drivers.forEach(item => {
      let arr = [item.name, item.email, item.phone, item.contactName, item.address.street, item.address.city, item.address.state, item.address.country, item.address.zipcode, item.status];
      csvData.push(arr);
    });
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvData.forEach(function (rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });
    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  };

  render() {
    const { isSearchEnabled } = this.state;
    const { drivers, searched, toast, isProcessing } = this.props;

    const selectedTab = TABS[this.state.tabIndex];
    const flag = selectedTab === 'AVAILABLE' ? true : false;

    // searched drivers only or all drivers
    let filteredData = [];
    const statusCounts = [];
    const tableData = isSearchEnabled ? searched : drivers;
    if (tableData) {
      filteredData = tableData ? tableData.filter(d => d.available === flag) : [];    
      statusCounts.push(
        {
          'status': 'AVAILABLE',
          'count': tableData.filter(d => d.available === true).length,
        },
        {
          'status': 'UNAVAILABLE',
          'count': tableData.filter(d => d.available === false).length,
        }
      );
    }

    return (
      <Page title="Drivers">
        <Box display={'flex'} justifyContent={'space-between'}>
          <Topbar
            title='DRIVER'
            subTitle='Manage Your Drivers'
          />
          <Searchbar
            searchType='SIMPLE'
            searchKey='name'
            searchParameters={this.state.searchParameters}
            fnOnSearchHandler={searchParams => this.executeSearch(searchParams)}
          >
          </Searchbar>
          <Notificationbar />
        </Box>
        <Box
          m='20px 0 0 0'
          height='75vh'
        >
          <Box style={{ margin: '10px 0' }}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Button
                variant='contained'
                color='primary'
                onClick={this.toggleFormDialog}
              >
                {'New Driver'}
              </Button>
              {isProcessing && <CircularProgress style={{margin: 'auto'}}/>}              
              {toast ? <ToastHandler toast={toast} type={toast.type} /> : null}              
              <ButtonGroup
                variant='outlined'
                color='secondary'
              >
                <Button onClick={() => this.setState({ isFileLoaderDialogOpen: true })}>
                  Import
                </Button>
                <Button onClick={this.downloadTemplate}>
                  Template
                </Button>
                <Button onClick={this.downloadDrivers}>Export</Button>
              </ButtonGroup>
            </Box>
          </Box>
          <Box display={'flex'}>
            <Box flex={1}>
              <CTTabs
                labels={TABS}
                counts={statusCounts}
                tabIndex={this.state.tabIndex}
                fnOnTabChangeHandler={this.changeTab}              
              />
            </Box>
            {isSearchEnabled &&
              <Box flex={1}>
                <Typography variant='h5' 
                  style={{ 
                    letterSpacing: 1,
                    color: theme.colors.grey,
                    textTransform: 'uppercase',
                    marginBottom: '2px'
                  }}
                >
                  {`${searched.length} records found`}
                </Typography>
              {this.createSearchChip()}
              </Box>
            }
          </Box>
          <DataGrid
            rows={filteredData}
            columns={this.columns}
            rowHeight={40}
            disableSelectionOnClick
          />
        </Box>
        <DataDialog
          open={this.state.isDataDialogOpen}
          title='Driver Form'
          toast={toast}
          isProcessing={isProcessing}
          onCloseHandler={this.closeDialog}
        >
          <DriverForm
            action={this.state.action}
            driver={this.state.driver}
            saveFormHandler={this.saveForm}
            updateFormHandler={this.updateForm}
          />
        </DataDialog>
        <DataDialog
          open={this.state.isFileLoaderDialogOpen}
          title='Import'
          toast={toast}
          isProcessing={isProcessing}          
          onCloseHandler={this.closeDialog}
        >
          <FileUploader
            name={'driver'}
          />
        </DataDialog>
      </Page>
    );
  }
}

function mapStatesToProps(state) {
  const { drivers, searched, isProcessing, toast } = state.driverReducer;
  return {
    drivers,
    searched,
    isProcessing,
    toast
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(DriverAction, dispatch);
}

// Promote DriverPage from a component to a container - it needs to know
// about this new dispatch method, selectDriver
export default connect(mapStatesToProps, mapDispatchToProps)(DriverPage);