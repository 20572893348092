import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Box,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { DRIVER_CHARGES_STATUS } from 'utils/constant.js';
import InputField from 'components/Input-Field/input-field';
import SelectField from 'components/Select-Field/select-field';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';

const LegCharge = ({ action,
  legCharge,
  legChargeList,
  fnOnSaveHandler,
  fnOnUpdateHandler,
  fnOnEditHandler,
  fnOnDeleteHandler
}) => {

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  
  const columns = [
    { field: 'id', headerName: 'Id' },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    { field: 'chargeDate', headerName: 'Date', flex: 1 },
    { field: 'amount', headerName: 'Amount', flex: 1 },
    { field: 'amountApproved', headerName: 'Amount Approved', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton size='small' onClick={() => editRow(params.row)}>
              <EditOutlined fontSize='small' color='primary' />
            </IconButton>
            <IconButton size='small' onClick={() => deleteRow(params.row)}>
              <DeleteOutline fontSize='small' color='secondary' />
            </IconButton>
          </>
        );
      },
    },
  ];

  const formik = useFormik({
    initialValues: { ...legCharge },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().max(100).required('Charge Name is required.'),
      amount: Yup.number().min(1).required('Charge Amount is required'),
      status: Yup.string().max(16).required('Charge Status is required.'),
    }),

    onSubmit: values => {
      const trimmed_data = {};
      Object.keys(values).forEach(key => trimmed_data[key] = typeof (values[key]) === 'string' ? values[key].trim() : values[key]);
      action === 'Save' ? fnOnSaveHandler(trimmed_data) : fnOnUpdateHandler(trimmed_data);
      resetForm();
    },
  });

  const editRow = (row) => {
    formik.initialValues = {...row};
    fnOnEditHandler(row);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmationDialogOpen(true);    
  };

  const renderLegChargeForm = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <InputField
              label='Charge Name'
              name='name'
              value={formik.values.name}
              variant='outlined'
              fullWidth
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              label='Charge Amount'
              name='amount'
              type='number'
              value={formik.values.amount}
              variant='outlined'
              fullWidth
              error={Boolean(formik.touched.amount && formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label='Select Charge Status'
              name='status'
              select
              value={formik.values.status}
              variant='outlined'
              fullWidth
              error={Boolean(formik.touched.status && formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={DRIVER_CHARGES_STATUS}
            />
          </Grid>
        </Grid>
        <Box
          display='flex'
          justifyContent='flex-end'
          p={2}>
          {action === 'Save' ?
            <Button
              type='submit'
              variant='contained'
              color='primary'
            >
              Save Charge
            </Button> :
            <Button
              type='submit'
              variant='contained'
              color='primary'
            >
              Update Charge
            </Button>
          }
        </Box>
      </form>
    );
  };

  return (
    <React.Fragment>
      <Box
        m='10px 0 0 0'
      >
        {renderLegChargeForm}
      </Box>
      <Box
        m='20px 0 0 0'
        height='50vh'
      >
        <DataGrid
          rows={legChargeList}
          columns={columns}
          rowHeight={40}
          disableSelectionOnClick
        />
      </Box>
      {selectedRow &&
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          title={'Delete Charge'}
          handleConfirm={() => fnOnDeleteHandler(selectedRow)}
          handleCancel={() => setConfirmationDialogOpen(false)}
          handleCloseFunc={() => setConfirmationDialogOpen(false)}
        >
          <Typography variant='subtitle2'>{`Do you want to Delete Charge: ${selectedRow && selectedRow.name} ?`}</Typography>
        </ConfirmationDialog>}      
    </React.Fragment>
  );
};

export default LegCharge;