import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Divider,
  Box,
  Link,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { formatAppointment, formatCurrency } from 'utils/formatter.js';
import { showOrderNetCalculations } from 'utils/calculations';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import globalStyle from 'styles/GlobalStyle';
import { green, red } from '@material-ui/core/colors';

const useStyles = globalStyle;

const getBGColor = (status) => {
  switch (status) {
  case 'RECEIVED':
  case 'RECEIVED_PARTIAL':
    return 'green';
  case 'UNPAID':
    return 'red';
  case 'INVOICED':
    return '#2196F3';
  }
};

const OTRGridView = ({ className,
  orders,
  fnViewOrderHandler,
  fnEditOrderHandler,
  fnDeleteRowHandler,
  fnOrderStatsHandler,
  fnOrderHistoryHandler,
  fnInvoiceHandler,
  fnMapHandler,
  fnFileUploadHandler,
  ...rest }) => {
  const classes = useStyles();

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleClick = (event, order) => {
    setSelectedOrder(order);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedOrder(null);
    setAnchorEl(null);
  };

  return (
    <>
      {orders.map(order => {
        const legCount = order.legs && order.legs.length;
        const origins = legCount > 0 ? order.legs[legCount - 1].origins : [];
        const destinations = legCount > 0 ? order.legs[legCount - 1].destinations : [];

        const trailerNumber = order.trailerNumber;
        const driver = order.driver ? order.driver.fullName : 'N.A';
        const orderNet = showOrderNetCalculations(order);
        const orderNetTooltipInformation = [];
        if (order.status === 'COMPLETE') {
          orderNetTooltipInformation.push(`Order Rate: ${orderNet.orderRate}`);
          orderNetTooltipInformation.push(<br />);
          orderNetTooltipInformation.push(`FSC: ${orderNet.fsc}`);
          orderNetTooltipInformation.push(<br />);
          orderNetTooltipInformation.push(`Expenses (Billable): ${orderNet.billableExpense}`);
          orderNetTooltipInformation.push(<br />);
          orderNetTooltipInformation.push(`Expenses (Company): ${orderNet.companyExpense}`);
          orderNetTooltipInformation.push(<br />);
          orderNetTooltipInformation.push(`Driver Pay: ${orderNet.driverPay}`);
          orderNetTooltipInformation.push(<br />);
        }
        return (
          <Card style={{ maxWidth: '100%', boxShadow: 'lg', marginTop: '8px' }} key={`key:order:${order.id}`}>
            <CardHeader style={{ padding: '4px' }}
              title={
                <Box display='flex' justifyContent='space-between'>
                  <Link variant='subtitle2' component='button' underline='hover' onClick={() => fnViewOrderHandler(order)}>
                    {order.displayOrderId}
                  </Link>
                  <Typography variant='h6'>{order.orderType}</Typography>
                  <Typography style={{ color: getBGColor(order.paymentStatus) }}>
                    {order.paymentStatus}
                  </Typography>
                </Box>
              }
              subheader={
                <Tooltip
                  title={orderNetTooltipInformation} arrow>
                  <Typography variant='h6' className={classes.formattedData}>
                    {order.orderNet > 0 ? <span style={{ color: green[500] }}>{formatCurrency(order.orderNet)}</span>
                      : <span style={{ color: red[500] }}>{formatCurrency(order.orderNet)}</span>}
                  </Typography>
                </Tooltip>
              }
              action={
                <Box display='flex'>
                  <IconButton aria-label="settings"
                    className={classes.actionIconButton}
                    onClick={(evt) => handleClick(evt, order)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem key={`view:${order.id}`} onClick={() => fnViewOrderHandler(selectedOrder)}><VisibilityOutlinedIcon fontSize="small" />&nbsp;&nbsp;View Order</MenuItem>
                    <Divider />
                    <MenuItem key={`edit:${order.id}`} onClick={() => fnEditOrderHandler(selectedOrder)}><EditOutlinedIcon fontSize="small" />&nbsp;&nbsp;Edit</MenuItem>
                    <Divider />
                    <MenuItem key={`delete:${order.id}`} onClick={() => fnDeleteRowHandler(selectedOrder)}><DeleteOutlinedIcon fontSize="small" />&nbsp;&nbsp;Delete</MenuItem>
                    <Divider />
                    <MenuItem key={`files:${order.id}`} onClick={() => fnFileUploadHandler(selectedOrder)}><AttachFileIcon fontSize="small" />&nbsp;&nbsp;Upload Document</MenuItem>
                    <Divider />
                    <MenuItem key={`stats:${order.id}`} onClick={() => fnOrderStatsHandler(selectedOrder)}><EqualizerOutlinedIcon fontSize="small" />&nbsp;&nbsp;Order Stats</MenuItem>
                    <Divider />
                    <MenuItem key={`notification:${order.id}`} onClick={() => fnOrderHistoryHandler(selectedOrder)}><NotificationsActiveOutlinedIcon fontSize="small" />&nbsp;&nbsp;Order History</MenuItem>
                    <Divider />
                    <MenuItem key={`history:${order.id}`}
                      disabled={!Boolean(['DELIVERED','TERMINAL', 'COMPLETE'].filter(st => st === order.status).length)}
                      onClick={() => fnInvoiceHandler(selectedOrder)}><ReceiptOutlinedIcon fontSize="small" />&nbsp;&nbsp;View Invoice</MenuItem>
                    <Divider />
                    <MenuItem key={`map:${order.id}`} onClick={(evt) => fnMapHandler(evt, selectedOrder)}><ExploreOutlinedIcon fontSize="small" />&nbsp;&nbsp;View Map</MenuItem>
                    <Divider />
                  </Menu>
                </Box>
              }
            />
            <CardContent style={{ padding: '4px' }}>
              {/* ROW 2 - BROKER INFO */}
              <Grid container>
                <Grid item md={7} sm={7} xs={6}>
                  <Typography variant='body1'>Broker</Typography>
                  <Tooltip title={order.broker.name} arrow>
                    <Typography variant='h6'>{order.broker.name}</Typography>
                  </Tooltip>
                </Grid>
                <Grid item md={5} sm={5} xs={6}>
                  <Typography>Broker Ref.#</Typography>
                  <Typography variant='h6'>{order.brokerOrderNumber}</Typography>
                </Grid>
              </Grid>
              {/* ROW 3 - ORIGIN INFO */}
              {origins.map(origin =>
                <Grid container key={`key:origin:${origin.id}`}>
                  <Grid item xs={7} key={`key:origin:${origin.id}`}>
                    <Typography variant='body1'>Origin</Typography>
                    <Tooltip
                      title={`${origin.address.city}, ${origin.address.state}`} arrow>
                      <Typography variant='h6'>{origin.name}</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={5} key={`key:origin2:${origin.id}`}>
                    <Typography variant='body1'>Appointment</Typography>
                    <Typography variant='h6'>{formatAppointment(origin.appointment)}</Typography>
                  </Grid>
                </Grid>
              )}
              {/* ROW 3 - DESTINATION INFO */}
              {destinations.map(destination =>
                <Grid container key={`key:destination:${origin.id}`}>
                  <Grid item xs={7} key={`key:destination:${destination.id}`}>
                    <Typography variant='body1'>Destination</Typography>
                    <Tooltip
                      title={`${destination.address.city}, ${destination.address.state}`} arrow>
                      <Typography variant='h6'>{destination.name}</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={5} key={`key:destination2:${destination.id}`}>
                    <Typography variant='body1'>Appointment</Typography>
                    <Typography variant='h6'>{formatAppointment(destination.appointment)}</Typography>
                  </Grid>
                </Grid>
              )}
              {/* ROW 4 - Trailer & Driver */}
              <Grid container>
                <Grid item md={7} sm={8} >
                  <Typography>Driver</Typography>
                  <Tooltip
                    title={driver} arrow>
                    <Typography variant='h6' className={classes.formattedData}>{driver}</Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5}>
                  <Typography>Trailer</Typography>
                  <Typography variant='h6'>{trailerNumber}</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent style={{
              padding: 4,
              paddingBottom: 0,
              marginBottom: 0,
              textAlign: 'center',
              '&:last-child': {
                paddingBottom: 0,
              },
            }}
            >
              <Typography>Notes</Typography>
              <Tooltip title={order.notes} aria-label="notes" arrow>
                <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{order.notes}</Typography>
              </Tooltip>
            </CardContent>
          </Card>
        );
      }
      )}
    </>
  );
};

OTRGridView.propTypes = {
  className: PropTypes.string
};

export default OTRGridView;