import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Box,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { USStates, CAProvinces, COUNTRIES } from 'utils/constant.js';
import InputField from 'components/Input-Field/input-field';
import SelectField from 'components/Select-Field/select-field';

const DriverForm = ({ action, driver, saveFormHandler, updateFormHandler }) => {

  const formik = useFormik({
    initialValues: { ...driver },

    validationSchema: Yup.object().shape({
      fullName: Yup.string().min(2).max(100).required('Driver Name is required.'),
      phone: Yup.string().max(15).required('Phone number is required'),
      licenseNumber: Yup.string().min(2).max(20).required('License Number is required.'),
      licenseExpiryDate: Yup.string().required('License Expiry Date is required.'),
      insuredName: Yup.string().min(2).max(100).required('Insured Name is required.'),
      policyNumber: Yup.string().min(2).max(20).required('Policy Number is required.'),
      policyExpiryDate: Yup.string().required('Policy Expiry Date is required.'),
      city: Yup.string().min(2).max(50).required('City Name is required.'),
      state: Yup.string().min(2).max(25).required('State Name is required.'),
      country: Yup.string().max(25).required('Country Name is required.'),
    }),

    onSubmit: values => {
      const trimmed_data = {};
      Object.keys(values).forEach(key => trimmed_data[key] = typeof (values[key]) === 'string' ? values[key].trim() : values[key]);
      action === 'Save' ? saveFormHandler(trimmed_data) : updateFormHandler(trimmed_data);
    },
  });

  const handlePhone = (e) => {
    if (e.target.name === 'phone') {
      var x = e.target.value
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      formik.handleChange(e);
      formik.setFieldValue('phone', e.target.value);
    }
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit} autoComplete='off'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <SelectField
              fullWidth
              name='driverType'
              label={'Select Driver Type'}
              variant='outlined'
              value={formik.values.driverType}
              error={Boolean(formik.touched.driverType && formik.errors.driverType)}
              helperText={formik.touched.driverType && formik.errors.driverType}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={['REGULAR', 'OWNER_OPERATOR', 'FULLTIME']}
              size='small'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              label="Driver Name"
              name="fullName"
              value={formik.values.fullName}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.fullName && formik.errors.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              label="Driver Phone Number"
              name="phone"
              type="tel"
              value={formik.values.phone}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.phone && formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              onBlur={formik.handleBlur}
              onChange={e => {
                handlePhone(e);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              label="Driver Email"
              name="email"
              type="email"
              value={formik.values.email}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectField
              label="Select Country"
              name="country"
              value={formik.values.country}
              variant="outlined"
              fullWidth         
              error={Boolean(formik.touched.country && formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={COUNTRIES}
            />
          </Grid>          
          <Grid item xs={12} md={3}>
            <InputField
              label="City"
              name="city"
              value={formik.values.city}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.city && formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectField
              label="Select State/Province"
              name="state"
              select
              value={formik.values.state}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.state && formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={formik.values['country'] === 'USA' ? USStates : CAProvinces}
            />
          </Grid>
        </Grid>
        <Box display='flex' justifyContent='space-between' m={'5px 0px'}>
          <Paper
            elevation={1}
            style={{
              padding: '5px',
              marginTop: '5px',
              marginRight: '3px',
              flex: 4
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography variant='subtitle2'>{'Driver License'}</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <InputField
                  label="License Number"
                  name="licenseNumber"
                  value={formik.values.licenseNumber}
                  variant="outlined"
                  fullWidth
                  error={Boolean(formik.touched.licenseNumber && formik.errors.licenseNumber)}
                  helperText={formik.touched.licenseNumber && formik.errors.licenseNumber}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <InputField
                  label="Expiry Date"
                  name="licenseExpiryDate"
                  value={formik.values.licenseExpiryDate}
                  type="date"
                  variant="outlined"
                  fullWidth
                  error={Boolean(formik.touched.licenseExpiryDate && formik.errors.licenseExpiryDate)}
                  helperText={formik.touched.licenseExpiryDate && formik.errors.licenseExpiryDate}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SelectField
                  label="License State/Province"
                  name="licenseState"
                  select
                  value={formik.values.licenseState}
                  variant="outlined"
                  fullWidth
                  error={Boolean(formik.touched.licenseState && formik.errors.licenseState)}
                  helperText={formik.touched.licenseState && formik.errors.licenseState}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  options={USStates}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SelectField
                  label="Select Country"
                  name="country"
                  select
                  value={formik.values.country}
                  variant="outlined"
                  fullWidth
                  error={Boolean(formik.touched.country && formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  options={COUNTRIES}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={1}
            style={{
              padding: '5px',
              marginTop: '5px',
              marginLeft: '3px',
              flex: 4
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography variant='subtitle2'>{'Driver Insurance'}</Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <InputField
                  label="Insurance Provider"
                  name="insuranceCarrier"
                  value={formik.values.insuranceCarrier}
                  variant="outlined"
                  fullWidth
                  error={Boolean(formik.touched.insuranceCarrier && formik.errors.insuranceCarrier)}
                  helperText={formik.touched.insuranceCarrier && formik.errors.insuranceCarrier}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <InputField
                  label="Insured Name"
                  name="insuredName"
                  value={formik.values.insuredName}
                  variant="outlined"
                  fullWidth
                  error={Boolean(formik.touched.insuredName && formik.errors.insuredName)}
                  helperText={formik.touched.insuredName && formik.errors.insuredName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>              
              <Grid item xs={6} md={6}>
                <InputField
                  label="Policy Number"
                  name="policyNumber"
                  value={formik.values.policyNumber}
                  variant="outlined"
                  fullWidth
                  error={Boolean(formik.touched.policyNumber && formik.errors.policyNumber)}
                  helperText={formik.touched.policyNumber && formik.errors.policyNumber}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <InputField
                  label="Expiry Date"
                  name="policyExpiryDate"
                  value={formik.values.policyExpiryDate}
                  type="date"
                  variant="outlined"
                  fullWidth
                  error={Boolean(formik.touched.policyExpiryDate && formik.errors.policyExpiryDate)}
                  helperText={formik.touched.policyExpiryDate && formik.errors.policyExpiryDate}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <InputField
                  label="Certificate Holder Name"
                  name="certificateHolder"
                  value={formik.values.certificateHolder}
                  variant="outlined"
                  fullWidth
                  error={Boolean(formik.touched.certificateHolder && formik.errors.certificateHolder)}
                  helperText={formik.touched.certificateHolder && formik.errors.certificateHolder}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>

        <Grid />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}>
          {action === 'Save' ?
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Save Driver
            </Button> :
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Update Driver
            </Button>
          }
        </Box>
      </form>
    </div >
  );
};
export default DriverForm;