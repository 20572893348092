const initState = {
  brokers: [],
  searched: [],
  broker: {},
  toast: null,
  isProcessing: false,
  isLoaded: false,
};

export const brokerReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_BROKER_STATE':
    return { 
      ...initState
    };
  case 'RESET_TOAST_STATE':
    return {
      ...state,
      toast: null, 
      isProcessing: false,
    };
  case 'PROCESSING_BROKER':
    return {
      ...state,
      toast: { type: 'LOADING', message: 'Please wait ...'},
      isProcessing: true,
    };

  case 'FETCH_BROKERS':    
    return {
      ...state,
      brokers: action.payload,      
      toast: {type: 'SUCCESS'},
      isProcessing: false,
      isLoaded: true, // This is to make sure we avoid additional fetch calls.
    };
  case 'ADD_BROKER':
    return {
      ...state,      
      brokers: [...state.brokers, action.payload],
      toast: { type: 'SUCCESS', message: 'Broker saved successfully.'},
      isProcessing: false,
    };     
  case 'UPDATE_BROKER':
    return {
      ...state,      
      brokers: state.brokers.map(broker => broker.id === action.payload.id ? {...action.payload } : { ...broker }),
      searched: state.searched.map(broker => broker.id === action.payload.id ? {...action.payload } : { ...broker }),
      toast: { type: 'SUCCESS', message: 'Broker updated successfully.' },
      isProcessing: false,
    };
  case 'SEARCH_BROKERS':
    return {
      ...state,
      searched: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,      
    };    
  case 'BROKER_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};
