import React from 'react';
import { Box, Typography } from '@material-ui/core';
import theme from 'theme';

const Header = ({ title, subtitle }) => {
  const colors = theme.colors;
  return (
    <Box>
      <Typography
        variant='h3'
        style={{
          color: colors.title,
          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
      <Typography variant='h5'
        style={{color: colors.subtitle}}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;