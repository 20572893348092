import React from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import IntermodalOrderPage from 'containers/Dispatch-Order/Intermodal/intermodal';
import BrokerPage from 'containers/Broker/broker-page';
import CompanyPage from 'containers/Company/company-page';
import DriverPage from 'containers/Driver/driver-page';
import EquipmentPage from 'containers/Equipment/equipment-page';
import InvoiceManager from 'containers/Accounting/invoice-manager';
import BillPayManager from 'containers/Accounting/billpay-manager';
import TenantPage from 'containers/tenant/tenant-page';
import ReportPage from 'containers/Report/report-page';

import {fetchBrokers} from 'actions/broker-action';
import {fetchCompanies} from 'actions/company-action';
import {fetchDrivers} from 'actions/driver-action';
import {fetchEquipments} from 'actions/equipment-action';
import { getTenant, listUsers, getSchedule } from 'actions/tenant-action';
import OtrDispatch from 'containers/Dispatch-Order/OTR/otr-dispatch';

const renderComponentPage = (component, location) => {
  switch (component) {
  case 'intermodal':
    return <IntermodalOrderPage location={location}/>;
  case 'longhaul':
    return < OtrDispatch location={location}/>;    
  case 'brokers':
    return <BrokerPage />;
  case 'companies':
    return <CompanyPage />;
  case 'drivers':
    return <DriverPage />;
  case 'equipments':
    return <EquipmentPage />;
  case 'accounting':
    return <InvoiceManager />;
  case 'paymanager':
    return <BillPayManager />;
  case 'settings':
    return <TenantPage />;
  case 'report':
    return <ReportPage />;
  default:
    return null;
  }
};

const DataContainer = (props) => {
  const { brokerReducer, companyReducer, driverReducer, equipmentReducer, tenantReducer, schedulerReducer } = props;
  if (!brokerReducer.isProcessing && !brokerReducer.isLoaded) {
    props.fetchBrokers();
  }
  if (!companyReducer.isProcessing && !companyReducer.isLoaded) {
    props.fetchCompanies();
  }

  if (!driverReducer.isProcessing && !driverReducer.isLoaded) {
    props.fetchDrivers();
  }

  if (!equipmentReducer.isProcessing && !equipmentReducer.isLoaded) {
    props.fetchEquipments();
  }

  if (!tenantReducer.isProcessing && !tenantReducer.isLoaded) {
    const tenantId = localStorage.getItem('CT_TENANTID');
    props.getTenant(tenantId);
    props.listUsers(tenantId);
  }

  // if(!schedulerReducer.isProcessing && !schedulerReducer.isLoaded) {
  //   props.getSchedule();
  // }

  const { component } = useParams();
  const location = useLocation();

  return (    
    renderComponentPage(component, location)
  );
};

const mapStatesToProps = state => ({
  brokerReducer: state.brokerReducer,
  companyReducer: state.companyReducer,
  driverReducer: state.driverReducer,
  equipmentReducer: state.equipmentReducer,
  tenantReducer: state.tenantReducer,
  schedulerReducer: state.schedulerReducer
});

function mapDispatchToProps(dispatch) {
  return {
    fetchBrokers: () => dispatch(fetchBrokers()),
    fetchCompanies: () => dispatch(fetchCompanies()),
    fetchDrivers: () => dispatch(fetchDrivers()),
    fetchEquipments: () => dispatch(fetchEquipments()),
    getTenant: (tenantId) => dispatch(getTenant(tenantId)),
    listUsers: (tenantId) => dispatch(listUsers(tenantId)),
    getSchedule: () => dispatch(getSchedule()),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(DataContainer);
