import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, getIn } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Box,
  Grid,
  Typography,
  FormLabel,
} from '@material-ui/core';

import InputField from 'components/Input-Field/input-field';
import SelectField from 'components/Select-Field/select-field.js';
import { Toggle } from 'rsuite';
import { AggregateOrderExpenses, calculateOrderTotal } from 'utils/calculations';
import { formatCurrency } from 'utils/formatter';
import CustomSelectField from 'components/Select-Field/custom-select';

const OrderSummary = (props) => {
  const {summary, brokers, updateFormHandler} = props;

  const {broker} = summary;
  const SummarySchema = Yup.object().shape({
    summary: Yup.object().shape({
      broker: Yup.string().required('Broker is required.'),
      brokerOrderNumber: Yup.string().required('Broker Order Number is required'),
      orderRate: Yup.number()
        .required('Order Rate is required')
        .min(0, 'Order Rate cannot be negative'),
      fuelCharges: Yup.number()
        .required('Fuel charges is required')
        .min(0, 'Fuel Charges cannot be negative'),
    }),
  });

  const getBrokerLabel = (broker) => {
    const primaryText = broker.name;
    const secondaryText = broker.address && `${broker.address.street},${broker.address.city},${broker.address.state},${broker.address.country}`;
    const reactSelectObj = {
      label:<Box>
        <Typography variant='h6'>{primaryText}</Typography>
        <Typography variant='subtitle2' style={{fontSize: '11px'}}>{secondaryText}</Typography>
      </Box>,
      value: primaryText
    };
    return reactSelectObj;
  };
  
  summary.broker = getBrokerLabel(broker);  
  return (
    <Formik
      initialValues={{ summary }}
      enableReinitialize
      validationSchema={SummarySchema}
      onSubmit={values => {
        const brokerName = values.summary.broker.value;
        values.summary.broker = brokerName;
        updateFormHandler(values.summary);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        setFieldValue,
        values
      }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={6} md={2}>
              <SelectField
                fullWidth={true}
                name='summary.orderType'
                label={'Select Order Type'}
                variant='outlined'
                value={values.summary.orderType}
                error={Boolean(
                  getIn(touched, 'summary.orderType') &&
                  getIn(errors, 'summary.orderType')
                )}
                helperText={Boolean(
                  getIn(touched, 'summary.orderType') &&
                  getIn(errors, 'summary.orderType')
                )}
                onBlur={handleBlur}
                onChange={handleChange}
                options={['DELIVERY', 'PICKUP', 'DROP', 'DROP_PICK']}
                margin='dense'
                size='small'
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormLabel component='legend'>Has Appointment</FormLabel>
              <Toggle
                name='summary.appointment'
                value={values.summary.appointment}
                checkedChildren='YES'
                unCheckedChildren='NO'
                defaultChecked={values.summary.appointment}
                color='rgb(227, 51, 113)'
                onChange={(val) => setFieldValue('summary.appointment', val)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <CustomSelectField
                type='BROKER'
                id='brokers'
                label='Broker Name'
                name='summary.broker'
                data={brokers.map(b => getBrokerLabel(b) )}
                value={values.summary.broker}
                onChange={selected => setFieldValue('summary.broker', selected)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <InputField
                type='text'
                label='Broker Order#'
                name='summary.brokerOrderNumber'
                value={values.summary.brokerOrderNumber}
                variant='outlined'
                fullWidth
                error={Boolean(
                  getIn(touched, 'summary.brokerOrderNumber') &&
                  getIn(errors, 'summary.brokerOrderNumber')
                )}
                helperText={Boolean(
                  getIn(touched, 'summary.brokerOrderNumber') &&
                  getIn(errors, 'summary.brokerOrderNumber')
                )}
                onBlur={handleBlur}
                onChange={handleChange}
                margin='dense'
                size='small'
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputField
                type='number'
                label='Order Rate'
                name='summary.orderRate'
                value={values.summary.orderRate}
                variant='outlined'
                fullWidth
                error={Boolean(
                  getIn(touched, 'summary.orderRate') &&
                  getIn(errors, 'summary.orderRate')
                )}
                helperText={Boolean(
                  getIn(touched, 'summary.orderRate') &&
                  getIn(errors, 'summary.orderRate')
                )}
                onBlur={handleBlur}
                onChange={handleChange}
                margin='dense'
                size='small'
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputField
                type='number'
                label='FSC'
                name='summary.fuelCharges'
                value={values.summary.fuelCharges}
                variant='outlined'
                fullWidth
                error={Boolean(
                  getIn(touched, 'summary.fuelCharges') &&
                  getIn(errors, 'summary.fuelCharges')
                )}
                helperText={Boolean(
                  getIn(touched, 'summary.fuelCharges') &&
                  getIn(errors, 'summary.fuelCharges')
                )}
                onBlur={handleBlur}
                onChange={handleChange}
                margin='dense'
                size='small'
              />
              <Toggle
                name='summary.fuelChargesType'
                value={values.summary.fuelChargesType}
                checkedChildren={'$'}
                unCheckedChildren={'%'}
                defaultChecked={values.summary.fuelChargesType === '$'}
                onChange={checked => {
                  const val = checked ? '$' : '%';
                  setFieldValue('summary.fuelChargesType', val);
                }
                }
                style={{ position: 'absolute', margin: '6px -50px', color: 'red' }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant='subtitle2'>Expense Total</Typography>
              <Typography variant='h6'>{formatCurrency(AggregateOrderExpenses(values.summary.expenses))}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant='subtitle2'>Order Total</Typography>
              <Typography variant='h6'>{formatCurrency(calculateOrderTotal(values.summary.orderRate, values.summary.fuelCharges, values.summary.fuelChargesType, values.summary.expenses))}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                type='text'
                label='Order Notes'
                name='summary.notes'
                value={values.summary.notes}
                variant='outlined'
                fullWidth
                error={Boolean(touched.notes && errors.notes)}
                helperText={touched.notes && errors.notes}
                onBlur={handleBlur}
                onChange={handleChange}
                margin='dense'
                size='small'
              />
            </Grid>
          </Grid>
          <Box display='flex' justifyContent='flex-end' p={2}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
            >
              {'Update'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
function mapStatesToProps(state) {
  // Whatevet is returned will show up as props
  const { brokers } = state.brokerReducer;
  return {
    brokers,
  };
}

export default connect(mapStatesToProps)(OrderSummary);
