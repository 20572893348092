import React, { useEffect } from 'react';
import Page from 'components/Page';

import TenantTable from './tenant-table';
import * as TenantService from '../../services/tenant-service';

import PaymentForm from '../stripe/payment-form';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { initializeStripePayment } from 'services/tenant-service.js';
import RegistrationForm from './registration-form';
import { Box, Button } from '@material-ui/core';
import Topbar from 'containers/global/Topbar';
import FormDialog from 'components/dialogs/DataDialog';

const TABLE_HEADER = [
  { id: 'Id', sortable: true, label: 'Id', show: true },
  { id: 'company', sortable: true, label: 'Company', show: true },
  { id: 'email', sortable: true, label: 'Email', show: true },
  { id: 'state', sortable: true, label: 'State', show: true },
  { id: 'country', sortable: true, label: 'Country', show: true },
  { id: 'plan', sortable: true, label: 'Plan', show: true },
  { id: 'registration_date', sortable: true, label: 'Register Date', show: true },
  { id: 'payment_date', sortable: true, label: 'Pay Date', show: true },
  { id: 'next_payment', sortable: true, label: 'Next Pay Date', show: true },
  { id: 'status', sortable: true, label: 'Status', show: true },
  { id: 'ordercnt', sortable: true, label: 'Order Cnt', show: true },
  { id: 'maxordercnt', sortable: true, label: 'Max Order Cnt', show: true },    
  { id: 'action', sortable: true, label: '', show: true },
];


const stripePromise = loadStripe('pk_test_51OGOwUC7RMqqI3r7AErRxKOaeoEZJp16qxAMBWrlr5wwBpRwA3RGRyPYzNB5mFNC3MRia2T80aqblyah6z6bTVU000PR39jJAr');
//OLD ACCT KEY -> pk_test_51MaQ3fFKqpVXUZ6BYtZMjoB1v6ru4QLqRJeClHHALCartW9r5gHdpmWjbZa1Gn3d6AIjKMCelRX5OKUba6b8XfG700qn4WtWEx');

const AdminDash = () => {
  const [tenants, setTenants] = React.useState([]);
  const [id, setId] = React.useState(null);
  const [registrationFormDialog, setRegistrationFormDialog] = React.useState(false);
  const [paymentDialog, setPaymentDialog] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState('');

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    (async () => {
      const tenants = await TenantService.list();      
      setTenants(tenants.data);
      console.log(tenants.data);
    })();
  }, [id]);
  
  const viewDetail = (tenant) => {
    alert(tenant);
  };

  const revertPayment = (tenant) => {
    alert(tenant);
  };

  /* Stripe Payment Dialog */
  const initializeTenantPayment = (tenant) => {
    const tenantPaymentModel = {
      planType: tenant.accountPlan,
      amount: tenant.paymentAmount,
    };
    initializeStripePayment(tenantPaymentModel).then(res => {
      if (res && res.status === 200) {
        setPaymentDialog(true);
        setClientSecret(res.data.clientSecret);        
      } else {
        alert('Unable to setup payment.');
      }
    });    
  };

  return (
    <Page title="Tenant">
      <Topbar
        title='ADMIN'
        subTitle='Manage Tenant Accounts'
      />
      <Box
        m='20px 0 0 0'
        height='75vh'
      >
        <Box style={{margin: '10px 0'}}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setRegistrationFormDialog(true)}
            >
              {'Register New Tenant'}
            </Button>
          </Box>
          <TenantTable
            tableData={tenants}
            tableHeader={TABLE_HEADER}
            checkBoxSelection={false}
            actionButtonSelection={true}
            fnOnViewDetailHandler={viewDetail}
            fnOnPaymentHandler={initializeTenantPayment}
            fnOnRevertPayHandler={revertPayment}
          />
        </Box>
      </Box>
      <FormDialog
        open={registrationFormDialog}
        title='Registration Form'
        onCloseHandler={() => setRegistrationFormDialog(false)}
      >
        <RegistrationForm
          action={'Sign Up Now'}
          // saveFormHandler={this.registerTenant}
        />
      </FormDialog>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentForm
            open={paymentDialog}
            fnOnCloseDialogHandler={() => setPaymentDialog(false)}
          />
        </Elements>)}
    </Page>
  );
};
export default AdminDash;