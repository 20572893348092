import { serviceHandler } from './service-executor';

const BROKER_URL = '/api/brokers';
// const delay = ms => new Promise(res => setTimeout(res, ms));

export const list = async () => {  
  let URL = `${BROKER_URL}/`;  
  return serviceHandler(URL, 'GET');
};

export const search = (name) => {
  const URL = `${BROKER_URL}/search?searchTerm=${name}`;
  return serviceHandler(URL, 'GET');
};

export const save = async broker => {
  const URL = `${BROKER_URL}/save`;
  return serviceHandler(URL, 'POST', broker);
};

export const update = async broker => {
  const URL = `${BROKER_URL}/update`;
  return serviceHandler(URL, 'POST', broker);
};

export const changeStatus = (id) => {
  let URL = `${BROKER_URL}/${id}/status`;
  return serviceHandler(URL, 'GET');
};