import _ from "lodash";

const initState = {
  orders: [],
  searched: [],
  order: {},
  orderLeg: {},
  toast: null,
  isProcessing: false,
};

const toastMessageByAction = {
  'ASSIGN_DRIVER': 'Driver assigned.',
  'UNASSIGN_DRIVER': 'Driver unassigned.',
  'CHANGE_DRIVER': 'Driver changed.',
  'CHANGE_DRIVER_RATE': 'Driver Rate changed',
  'SAVE_LEG_CHARGE': 'Charges saved successfully.',
  'UPDATE_LEG_CHARGE': 'Charges updated successfully.',
  'DELETE_LEG_CHARGE': 'Charges deleted.',
  'UPDATE_ORDER_LOCATION': 'Order location updated.',
  'UPDATE_ORDER_EQUIPMENT': 'Order equipment updated.',
  'UPDATE_ORDER_SUMMARY': 'Order updated',
  'UPDATE_ORDER_RATE': 'Order rate updated.',
  'SAVE_EXPENSE': 'Expense saved successfully.',
  'UPDATE_EXPENSE': 'Expense updated successfully.',
  'DELETE_EXPENSE': 'Expense deleted.',
};

export const orderReducer = (state = initState, action) => {
  const actionType = action.type;
  switch (actionType) {
  case 'RESET_ORDER_STATE':
    return {
      ...initState
    };
  case 'RESET_TOAST_STATE':
    return {
      ...state,
      toast: null, 
      isProcessing: false,
    };    
  case 'PROCESSING_ORDER':
    return {
      ...state,
      isProcessing: true,
      toast: { type: 'LOADING', message: 'Please wait ...'},
    };
  case 'FETCH_ORDERS':
    return {
      ...state,
      orders: action.payload,
      isProcessing: false,
      toast: { type: 'SUCCESS'},
    };
  case 'SEARCH_ORDER_SUCCESS':
    return {
      ...state,
      searched: action.payload,
      isProcessing: false,
      toast: { type: 'SUCCESS' },
    };
  case 'ADD_ORDER':
    return {
      ...state,
      orders: [...state.orders, action.payload],
      order: action.payload,
      isProcessing: false,
      toast: { type: 'SUCCESS', message: 'Order saved successfully.'},
    };    
  case 'UPDATE_ORDER':
    return {
      ...state,
      orders: state.orders.map(odr => odr.id === action.payload.id ? {...action.payload } : { ...odr }),
      searched: state.searched.map(odr => odr.id === action.payload.id ? {...action.payload } : { ...odr }),
      order: action.payload,
      isProcessing: false,
      toast: { type: 'SUCCESS', message: 'Order updated successfully.' },
    };
  case 'DELETE_ORDER':
    // eslint-disable-next-line no-case-declarations
    const id = Number(action.payload.substring(15));    
    return {
      ...state,
      orders: state.orders.filter(order => {return order.id !== id;}),
      searched: state.searched.filter(order => {return order.id !== id;}),
      order: null,
      isProcessing: false,
      toast: { type: 'SUCCESS', message: 'Order Deleted Successfully.'},
    };
  case 'DELETE_ORDER_LEG':
    return {
      ...state,
      orders: [...state.orders.filter(order => order.id !== Number(action.payload)),
        Object.assign({}, action.payload)],
      order: null,
      isProcessing: false,
      toast: { type: 'SUCCESS' },
    };
  case 'FETCH_CURRENT_ORDER':
  case 'SET_CURRENT_ORDER':
    return {
      ...state,
      orders: state.orders.map(order => {
        return order.id === (action.payload && action.payload.id) ? _.cloneDeep(action.payload) : { ...order };
      }),
      // update search orders only if we are on search table.
      searched: state.searched.map(order => {
        return order.id === (action.payload && action.payload.id) ? _.cloneDeep(action.payload) : { ...order };
      }),
      order: action.payload,
      isProcessing: false,
      toast: {type: 'SUCCESS'}
    };
  case 'STATUS_CHANGE':
    return {
      ...state,
      orders: state.orders.map((order) => order.id === action.payload.id ? { ...action.payload } : { ...order }),
      searched: state.searched.map((order) => order.id === action.payload.id ? { ...action.payload } : { ...order }),
      order: action.payload,
      isProcessing: false,
      toast: {type: 'SUCCESS'}
    };
  case 'FETCH_LEG':
    return {
      ...state,
      activeLeg: action.payload,
      toast: { type: 'SUCCESS' },
    };
  case 'UPDATE_ORDER_LOCATION':
  case 'UPDATE_ORDER_EQUIPMENT':
  case 'UPDATE_ORDER_SUMMARY':
  case 'UPDATE_ORDER_RATE':    
    return {
      ...state,
      orders: state.orders.map(order => order.id === action.payload.id ? { ...action.payload } : { ...order }),
      searched: state.searched.map(order => order.id === action.payload.id ? { ...action.payload } : { ...order }),
      order: action.payload,
      isProcessing: false,
      toast: { type: 'SUCCESS', message: toastMessageByAction[actionType]},
    };
  
  case 'SAVE_EXPENSE':
    // eslint-disable-next-line no-case-declarations
    const saved = {...state.order, expenses: [...state.order.expenses, {...action.payload}] };
    return {
      ...state,
      orders: state.orders.map(order => order.id === saved.id ? { ...saved } : { ...order }),
      // update search orders only if we are on search table.
      searched: state.searched.map(order => order.id === saved.id ? { ...saved } : { ...order }),
      order: saved,
      isProcessing: false,
      toast: {type: 'SUCCESS', message: toastMessageByAction[actionType]}
    };    
  case 'UPDATE_EXPENSE':
    // eslint-disable-next-line no-case-declarations
    const updated = {...state.order, expenses: state.order.expenses.map(exp => exp.id === action.payload.id ? {...action.payload } : {...exp})};
    return {
      ...state,
      orders: state.orders.map(order => order.id === updated.id ? { ...updated } : { ...order }),
      // update search orders only if we are on search table.
      searched: state.searched.map(order => order.id === updated.id ? { ...updated } : { ...order }),
      order: updated,
      isProcessing: false,
      toast: {type: 'SUCCESS', message: toastMessageByAction[actionType]}
    };
  case 'DELETE_EXPENSE':
    // eslint-disable-next-line no-case-declarations     
    const deleted = {...state.order, expenses: [...state.order.expenses.filter(exp => exp.id !== action.payload)]};
    return {
      ...state,
      orders: state.orders.map(order => order.id === deleted.id ? { ...deleted } : { ...order }),      
      // update search orders only if we are on search table.
      searched: state.searched.map(order => order.id === deleted.id ? { ...deleted } : { ...order }),
      order: deleted,
      isProcessing: false,
      toast: {type: 'SUCCESS', message: toastMessageByAction[actionType]}
    };
  case 'ASSIGN_DRIVER':
  case 'UNASSIGN_DRIVER':
  case 'CHANGE_DRIVER':
  case 'CHANGE_DRIVER_RATE':
  case 'SAVE_LEG_CHARGE':
  case 'UPDATE_LEG_CHARGE':
  case 'DELETE_LEG_CHARGE':
    // eslint-disable-next-line no-case-declarations    
    const data = {...state.order, legs: state.order.legs.map(leg => leg.id === action.payload.id ? Object.assign({}, action.payload) : {...leg})};
    return {
      ...state,
      orders: state.orders.map(order => order.id === data.id ? { ...data } : { ...order }),
      // update search orders only if we are on search table.
      searched: state.searched.map(order => order.id === data.id ? { ...data } : { ...order }),
      order: data,
      isProcessing: false,
      toast: {type: 'SUCCESS', message: toastMessageByAction[actionType]}
    };

  case 'ORDER_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};

