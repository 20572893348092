import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { formatCurrency } from 'utils/formatter';
import globalStyle from 'styles/GlobalStyle';

const useStyles = globalStyle;

const getBGColor = (status) => {
  switch (status) {
  case 'RECEIVED':
  case 'RECEIVED_PARTIAL':
    return 'green';
  case 'UNPAID':
    return 'red';
  case 'INVOICED':
    return '#2196F3';
  }
};

const InvoiceModal = ({ invoice }) => {
  const classes = useStyles();
  invoice = invoice || {};
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={6} sm={4}>
          <Box>
            <Typography
              gutterBottom
              variant='h5'
            >
              {invoice.displayOrderId}
            </Typography>
            <Typography
              variant='subtitle2'
            >
              {'Order Number'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box>
            <Typography
              gutterBottom
              variant='h5'
            >
              {invoice.invoiceDate}
            </Typography>
            <Typography
              variant='subtitle2'
            >
              {'Invoice Date'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box>
            <Typography>
              <Chip size='small' label={invoice.paymentStatus} style={{ color: 'white', backgroundColor: `${getBGColor(invoice.paymentStatus)}` }} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={6} sm={4}>
          <Box>
            <Typography
              gutterBottom
              variant='h5'
            >
              {invoice.brokerName}
            </Typography>
            <Typography
              variant='subtitle2'
            >
              {'Broker'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box>
            <Typography
              gutterBottom
              variant='h5'
            >
              {invoice.brokerReferenceNumber}
            </Typography>
            <Typography
              variant='subtitle2'
            >
              {'Broker Ref. Num.'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box>
            <Typography
              gutterBottom
              variant='h5'
            >
              {invoice.containerNumber}
            </Typography>
            <Typography
              variant='subtitle2'
            >
              {'Container'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Box>
            <Typography
              gutterBottom
              variant='h5'
            >
              {invoice.origins}
            </Typography>
            <Typography
              variant='subtitle2'
            >
              {'Origin'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <Typography
              gutterBottom
              variant='h5'
            >
              {invoice.destinations}
            </Typography>
            <Typography
              variant='subtitle2'
            >
              {'Destination'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={6} sm={3}>
          <Box>
            <Typography
              gutterBottom
              variant='h5'
            >
              {formatCurrency(invoice.invoiceTotal)}
            </Typography>
            <Typography
              variant='subtitle2'
            >
              {'Total Amount'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Box>
            <Typography
              gutterBottom
              variant='h5'
            >
              {formatCurrency(invoice.paymentReceived)}
            </Typography>
            <Typography
              variant='subtitle2'
            >
              {'Payment Received'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Box>
            <Typography
              gutterBottom
              variant='h5'
            >
              {invoice.checkNumber || '-'}
            </Typography>
            <Typography
              variant='subtitle2'
            >
              {'Check Number'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Box>
            <Typography
              gutterBottom
              variant='h5'
            >
              {invoice.paymentReceivedDate || '-'}
            </Typography>
            <Typography
              variant='subtitle2'
            >
              {'Payment Date'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      {invoice.expenses ?
        <PerfectScrollbar>
          <Box>
            <Table variant='small'>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  {['Expense', 'Amount', 'Date', 'Type', 'Is PerDay', 'Total Amount'].map(header => (
                    <TableCell className={classes.tableHeaderCell} key={header}>
                      {header}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice.expenses && invoice.expenses.map(data => {
                  return (
                    <TableRow
                      hover
                      key={data.id}>
                      <TableCell className={classes.tableCell}>{data.name}</TableCell>
                      <TableCell className={classes.tableCell}>{data.billableAmount}</TableCell>
                      <TableCell className={classes.tableCell}>{data.expenseDate}</TableCell>
                      <TableCell className={classes.tableCell}>{data.expenseType}</TableCell>
                      <TableCell className={classes.tableCell}>{data.isPerDay}</TableCell>
                      <TableCell className={classes.tableCell}>{data.totalAmount}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar> : <Typography variant='subtitle2'>{'No Expenses'}</Typography>
      }
    </Box>
  )
};

export default InvoiceModal;