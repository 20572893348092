import React from 'react';
import Header from 'components/Header';

const Topbar = ({ title, subTitle }) => {
  return (
    <Header
      title={title}
      subtitle={subTitle}
    />
  );
};

export default Topbar;