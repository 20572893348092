import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { formatCurrency } from 'utils/formatter.js';
import StatBox from 'components/StatBox';

const ReportSummary = ({ orderSummaryStats, invoiceSummaryStats, invoiceCountStats, billSummaryStats, billCountStats, netStats }) => {
  let totalOrderCount = 0;
  let totalInvoiceAmount = 0;
  let totalBillPaymentAmount = 0;

  let invoiceData = [];
  let billPaymentData = [];
  let orderCountData = [];
  if (orderSummaryStats) {
    totalOrderCount = Object.values(orderSummaryStats).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    orderCountData = Object.entries(orderSummaryStats).map(([k, v]) => ({ 'status': k, 'value': v }));
  }
  if (invoiceSummaryStats) {
    totalInvoiceAmount = Object.values(invoiceSummaryStats).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    invoiceData = Object.entries(invoiceSummaryStats).map(([k, v]) => ({ 'status': k, 'value': v, 'count': invoiceCountStats[k] }));
  }
  if (billSummaryStats) {
    totalBillPaymentAmount = Object.values(billSummaryStats).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    billPaymentData = Object.entries(billSummaryStats).map(([k, v]) => ({ 'status': k, 'value': v, 'count': billCountStats[k] }));
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={3}>
        <StatBox
          title='Orders'
          statHighlight={totalOrderCount}
          statBreakdown={orderCountData}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StatBox
          title='Invoices'
          statHighlight={formatCurrency(totalInvoiceAmount)}
          statBreakdown={invoiceData}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StatBox
          title='Driver Settlement'
          statHighlight={formatCurrency(totalBillPaymentAmount)}
          statBreakdown={billPaymentData}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StatBox
          title='Net'
          statHighlight={formatCurrency(netStats.Net)}
          statBreakdown={[{ status: 'invoice', value: netStats.InvoiceTotal }, { status: 'bills', value: netStats.PaymentTotal }]}
        />
      </Grid>
    </Grid>
  );
};

ReportSummary.propTypes = {
  className: PropTypes.string
};

export default ReportSummary;