import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Backdrop,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@material-ui/core';

import Page from 'components/Page';
import CTMap from 'components/CTMap';

import * as OrderAction from 'actions/order-action';
import { getOrderTimingStats, getInvoiceByOrderId, getFilesByOrderId, } from 'services/order-service.js';
import { bindActionCreators } from 'redux';
import { filterOrderDataByTab } from 'utils/filter';
import { formatCurrency } from 'utils/formatter.js';
import { Validate } from 'utils/Validator';

import 'rsuite/dist/rsuite.min.css';
import '../../../styles/rsuite-component.css';
import '../order-page.css';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import theme from 'theme';
import Topbar from 'containers/global/Topbar';
import Notificationbar from 'containers/global/Notificationbar';
import { ArrowDropDown, CloseOutlined, ImportExportOutlined } from '@material-ui/icons';
import Searchbar from 'containers/global/Searchbar';
import DataDialog from 'components/dialogs/DataDialog';
import OrderDetail from '../order-detail';
import IntermodalOrderForm from './intermodal-order-form';
import { getOrderNotifications } from 'services/notification-service';
import NotificationDialog from 'components/dialogs/NotificationDialog';
import IntermodalGridView from './intermodal-grid-view';
import IntermodalTableView from './intermodel-table-view';
import OrderStats from '../order-stats';
import InvoiceModal from '../invoice-modal';
import FilesModal from 'components/files-modal';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { Calendar } from 'rsuite';
import globalStyle from 'styles/GlobalStyle';

const PAGESIZE = 50;

const INTERMODAL_STATUS = ['OPEN', 'PROGRESS', 'YARD', 'DELIVERED', 'COMPLETE'];
const OTR_STATUS = ['OPEN', 'PROGRESS', 'DELIVERED', 'COMPLETE'];

const DISPATCH_MODE = ['TODAY', 'ALL ORDERS', 'NO APPOINTMENT ORDERS'];

const SEARCH_FIELDS = [
  { 'name': 'OrderId', 'label': 'Order Id', 'type': 'text' },
  { 'name': 'Terminal/Shipper', 'label': 'Terminal/Shipper', 'type': 'text' },
  { 'name': 'Broker', 'label': 'Broker', 'fieldType': 'text' },
  { 'name': 'Broker W/O Number', 'label': 'Broker Ref. No.', 'type': 'text' },
  { 'name': 'Container Number', 'label': 'Container Num.', 'type': 'text' },
  { 'name': 'LDF', 'label': 'LastFreeDate', 'fieldType': 'type' },
];

const IntermodalDispatch = (props) => {
  const { location } = props; //Navigation Location

  const classes = globalStyle();
  const dispatch = useDispatch();
  const initState = {
    summary: {
      orderType: 'DELIVERY',
      appointment: true,
      brokerId: '',
      broker: null,
      brokerOrderNumber: '',
      orderRate: null,
      fuelCharges: null,
      fuelChargesType: '$',
      extraCharges: null,
      expenses: [],
      orderTotal: null,
      notes: null,
    },
    expense: {
      id: '',
      name: '',
      billableAmount: '',
      companyShare: '',
      driverShare: '',
      isPerDay: 'NO',
      expenseType: 'BILLABLE',
    },
    origin: {
      id: [],
      name: [null],
      appointment: [new Date()],
      openAppointment: [null], // We don't need another boolean. If user enters the data in this field it means we need open appointment.
      puNumber: [''],
      puLocation: [null],
      notes: [null],
      street: [null],
      city: [null],
      state: [null],
      zipcode: [null],
      address: [null],
      country: [null],
    },
    destination: {
      id: [],
      name: [null],
      appointment: [new Date()],
      openAppointment: [null],
      referenceNumber: [null],
      notes: [null],
      street: [null],
      city: [null],
      state: [null],
      zipcode: [null],
      address: [null],
      country: [null],
    },
    container: {
      line: null,
      deliveryContainerNumber: null,
      pickupContainerNumber: null,
      bookingNumber: null,
      containerId: null,
      lastFreeDate: null,
      notes: null,
      isCustomDropLocation: false,
      dropLocation: '',
    },
    chassis: {
      company: null,
      chassisId: null,
      chassisNumber: null,
      isChassisSplit: false,
      pickupChassisCompany: null,
      pickupChassisId: null,
      pickupChassisNumber: null,
      isPickupChassisSplit: false,
    },
    equipment: {
      type: null,
      trailer: null,
      length: '',
      weight: '',    
    }
  };
  const [transportationMode, setTransportationMode] = useState(location && location.pathname.includes('intermodal') ? 'INTERMODAL' : 'OTR');
  const [order, setOrder] = useState(initState);
  const [action, setAction] = useState('Save');
  const [dispatchMode, setDispatchMode] = useState('TODAY');
  const [viewMode, setViewMode] = useState('GRID'); //other view is TABLE
  

  /* Dispatch Mode Logic */
  const [isDispatchModeMenuOpen, setDispatchModeMenuOpen] = useState(false);
  const [anchorRef, setAnchorRef] = useState(null);
  const [dispatchDate, setDispatchDate] = useState(new Date());

  /* Search State */
  const [isSearchEnabled, setSearchEnable] = useState(false);
  const [searchParameters, setSearchParameters] = useState({});

  const [pageNumber, setPageNumber] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [orderStats, setOrderStats] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [originMarkers, setOriginMarker] = useState([]);
  const [destinationMarkers, setDestinationMarker] = useState([]);

  /* DIALOGS */
  const [isOrderFormDialogOpen, setOrderFormDialogOpen] = useState(false);
  const [isOrderDetailDialogOpen, setOrderDetailDialog] = useState(false);
  const [isOrderStatsDialogOpen, setOrderStatsDialog] = useState(false);
  const [isOrderHistoryDialogOpen, setOrderHistoryDialog] = useState(false);
  const [isDeleteOrderDialogOpen, setDeleteOrderDialog] = useState(false);
  const [isOrderMapDialogOpen, setMapDialog] = useState(false);
  const [isFileUploadDialogOpen, setFileUploadDialog] = useState(false);
  const [isInvoiceDialogOpen, setInvoiceDialog] = useState(false);
  const [isAdvanceSearchDialogOpen, setAdvanceSearchDialog] = useState(false);
  const [isNotificationDialogOpen, setNotificationDialog] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const fileModalChildRef = useRef(null);

  useEffect(() => {
    if(dispatchMode == 'ALL ORDERS') {
      dispatch(OrderAction.listOrders(null, null, null, pageNumber, PAGESIZE)); // Get All Orders
    } else if(dispatchMode === 'NO. APPOINTMENT ORDERS') {
      dispatch(OrderAction.listOrders('NO', null, null, pageNumber, PAGESIZE)); // Get No Appointment Orders
    } else {
      const startDate = new Date(dispatchDate.setHours(0, 0, 0, 0)); // dispatchDate by default is Today's date
      const endDate = new Date(dispatchDate.setHours(23, 59, 59, 999));
      const startDateISO = startDate.toISOString();
      const endDateISO = endDate.toISOString();
      dispatch(OrderAction.listOrders('YES', startDateISO, endDateISO, pageNumber, PAGESIZE)); // Get Appointment orders By Date
    }
  }, [dispatchMode, dispatchDate]);

  /* When Dispatch Mode changes or date changes we need to 
  make API call to pull orders 
  */

  /* Dispatch mode menu */
  const handleDispatchModeChange = (evt) => {
    const mode = evt.currentTarget.textContent;
    if (mode === 'TODAY') {
      setDispatchDate(new Date()); //reset to today's date.
    }
    setDispatchMode(mode);
    setDispatchModeMenuOpen(false);
  };

  const handleDateChange = (evt) => {
    const dd = new Date(evt);
    const customDate = new Date(dd.setMinutes(dd.getMinutes() + dd.getTimezoneOffset())); //Need this for firefox. otherwise for chrome we can use datetime-local.
    setDispatchDate(customDate);
    setDispatchMode(customDate.toDateString()); // Show user which date they have selected.
    setDispatchModeMenuOpen(false);
  };

  /* Table or Grid View */
  const toggleViewMode = () => {
    const newViewMode = viewMode === 'GRID' ? 'TABLE' : 'GRID';
    setViewMode(newViewMode);
  };

  const addOrder = () => {    
    setAction('Save');
    setOrderFormDialogOpen(true);
  };

  const editOrder = (row) => {
    const dispatchOrder = Validate.parseDispatchOrder(row);
    setOrder(dispatchOrder);
    setAction('Update');
    setOrderFormDialogOpen(true);
  };

  const processOrderPersist = (order) => {
    const { summary, origin, destination, container, chassis } = order;
    
    // Add the Order Creation Date
    summary.orderCreateDate = new Date();
    summary.broker = summary.broker.value;
    origin.name = origin.name.map(o=>o.value);
    destination.name = destination.name.map(d=>d.value);
    container.line = container.line && container.line.value;
    chassis.company = chassis.company && chassis.company.value;
      
    const dispatchOrder = Object.create(null);

    dispatchOrder.summary = summary;
    dispatchOrder.origin = origin;
    dispatchOrder.destination = destination;
    dispatchOrder.container = container;
    dispatchOrder.chassis = chassis;

    if(action === 'Save') {
      dispatch(OrderAction.save(dispatchOrder));
    } else {
      dispatch(OrderAction.update(order));
    }
  };

  const toggleDeleteOrder = (order) => {
    setSelectedOrder(order);
    setDeleteOrderDialog(!isDeleteOrderDialogOpen);
  };
  const processDeleteOrder = () => {
    const orderId = selectedOrder.id;
    dispatch(OrderAction.deleteOrder(orderId));
  };

  const closeDeleteOrderDialog = () => {
    dispatch(OrderAction.reset('RESET_TOAST_STATE'));
    setDeleteOrderDialog(false);
  };

  const toggleOrderDetail = (order) => {
    props.setActiveOrder(order); // similar to props.getOrderById()
    setSelectedOrder(order);
    setOrderDetailDialog(!isOrderDetailDialogOpen);
  };

  const closeFormDialog = () => {
    dispatch(OrderAction.reset('RESET_TOAST_STATE'));
    setOrder(initState);
    setOrderFormDialogOpen(false);
  };

  /* Order Properties */
  const processOrderStats = (order) => {
    if (isOrderStatsDialogOpen) {
      setSelectedOrder(null);
      setOrderStats([]);
      setOrderStatsDialog(false);
    } else {
      getOrderTimingStats(order.id).then((res) => {
        setSelectedOrder(order);
        setOrderStats(res.data);
        setOrderStatsDialog(true);
      });
    }
  };

  const processOrderHistory = (order) => {
    if (isOrderHistoryDialogOpen) {
      setSelectedOrder(null);
      setOrderStats([]);
      setOrderHistoryDialog(false);
    } else {
      getOrderNotifications(order.id).then((res) => {
        setSelectedOrder(order);
        setOrderHistory(res.data);
        setOrderHistoryDialog(true);
      });
    }
  };

  const processOrderMap = (order) => {
    const orders = order ? [{ ...order }] : orders;
    const origins = [];
    const destinations = [];

    if (!isOrderMapDialogOpen) {
      orders.forEach((order) => {
        const legs = order.legs;
        if (legs && legs.length > 0) {
          legs[0].origins.forEach((loc) => {
            const origin = {
              orderId: order.displayOrderId,
              name: loc.name,
              address: loc.address,
              geo:
                loc.address && loc.address.geo
                  ? [loc.address.geo.lat, loc.address.geo.lng]
                  : [],
            };
            origins.push(origin);
          });
          legs[0].destinations.forEach((loc) => {
            const destination = {
              orderId: order.displayOrderId,
              name: loc.name,
              address: loc.address,
              geo:
                loc.address && loc.address.geo
                  ? [loc.address.geo.lat, loc.address.geo.lng]
                  : [],
            };
            destinations.push(destination);
          });
        }
      });
      setOriginMarker(originMarkers);
      setDestinationMarker(destinationMarkers);
      setMapDialog(true);
    } else {
      setOriginMarker([]);
      setDestinationMarker([]);
      setMapDialog(false);
    }
  };

  const processOrderInvoice = (order) => {
    if (isInvoiceDialogOpen) {
      setSelectedOrder(null);
      setInvoice(null);
      setInvoiceDialog(false);
    } else {
      getInvoiceByOrderId(order.id).then((res) => {
        setSelectedOrder(order);
        setInvoice(res.data);
        setInvoiceDialog(true);
      });
    }
  };

  const processFileUpload = (order) => {
    if (isFileUploadDialogOpen) {
      setFileUploadDialog(false);
      setSelectedOrder(null);
    } else {
      setSelectedOrder(order);
      getFilesByOrderId(order.id)
        .then((res) => {
          if (res.status === 200 && res.data) {
            // res.data
            fileModalChildRef.current.onInit(res.data);
          }
        })
        .catch((e) => {
          // pass error
          fileModalChildRef.current.onInit(null, e);
        });
      setFileUploadDialog(true);
    }
  };

  const downloadOrderCSV = () => {
    const filename = 'orders.csv';
    const { orders } = props;

    const formattedOrders = [];
    const header = ['OrderId', 'OrderType', 'Broker', 'Broker Order Number', 'Rate', 'FSC', 'Status', 'Container Num', 'Line', 'Chassis Number', 'Company', 'CompleteDate', 'Payment Status', 'Notes'];
    const subHeader = ['', 'LegId', 'Origin', 'Destination', 'Leg Status', 'Driver', 'Driver Rate'];

    formattedOrders.push(header);

    orders.forEach(function (order) {
      const { legs, broker, container, chassis } = order;
      let containerNumber = container.deliveryContainerNumber;
      if (order.orderType === 'PICKUP') {
        containerNumber = container.pickupContainerNumber;
      }

      let arr = [order.displayOrderId, order.orderType, broker.name, order.brokerOrderNumber, formatCurrency(order.orderRate), formatCurrency(order.fuelCharges) || 0.0, order.status, containerNumber, container.line, chassis.chassisNumber, chassis.company, order.orderCompleteDate, order.paymentStatus, order.notes];
      formattedOrders.push(arr);
      formattedOrders.push(['']);
      formattedOrders.push(subHeader);
      legs.forEach(function (leg) {
        const origins = leg.origins.map(lo => lo.name);
        const destinations = [];
        leg.destinations.forEach(ld => {
          if (ld.active)
            destinations.push(ld.name);
        });
        const driverName = leg.driver ? leg.driver.fullName : 'N.A';
        const driverRate = leg.driver ? leg.driver.rate : 'N.A';
        arr = ['', leg.id, origins, destinations, leg.status, driverName, driverRate];
        formattedOrders.push(arr);
      });
      formattedOrders.push(['']);
      formattedOrders.push(['']);
    });
    let csvContent = 'data:text/csv;charset=utf-8,';
    formattedOrders.forEach(function (rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });
    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  };

  const countOrdersByStatus = (data) => {
    const result = {};
    ['OPEN', 'PROGRESS', 'YARD', 'DELIVERED', 'COMPLETE'].forEach(status => {
      if (status === 'YARD') {
        const YARD = ['YARD_IN', 'YARD_PULL', 'DRYRUN'];
        result[status] = data.filter(d => YARD.includes(d.status)).length;
      } else if (status === 'PROGRESS') {
        const PROGRESS = ['PROGRESS', 'COVERED'];
        result[status] = data.filter(d => PROGRESS.includes(d.status)).length;
      } else {
        result[status] = data.filter(d => d.status === status).length;
      }
    });
    return result;
  };

  const getTabColor = (tab) => {
    switch (tab) {
    case 'YARD':
      return theme.status.order.yard;
    case 'PROGRESS':
      return theme.status.order.progress;
    case 'DELIVERED':
      return theme.status.order.delivered;
    case 'COMPLETE':
      return theme.status.order.complete;
    default:
      return theme.status.order.open;
    }
  };
  /* Search */
  /* 
    1. User click on search icon to call executeSearch function.
    2. If status is 200, we will display the search results.
    3. A Chip with onDelete property will be created based on {key:value} in the search Parameters
  */
  const toggleAdvanceSearchDialog = () => {
    setAdvanceSearchDialog(!isAdvanceSearchDialogOpen);
  };

  const handleSearchFieldChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    const param = {};
    param[`${name}`] = value.trim();
    setSearchParameters(Object.assign(searchParameters, param));
  };

  /* Execute Search and also save state of the search variables */
  const executeSearch = (searchParameters) => {
    if (searchParameters && Object.entries(searchParameters).length > 0) {
      const params = {
        searchParam: Object.entries(searchParameters).map(([k, v]) => ({ 'label': k, 'value': v }))
      };
      setSearchEnable(true);
      setSearchParameters(searchParameters);
      setAdvanceSearchDialog(false);
      props.search(params);
    } else {
      setSearchEnable(false);
      setSearchParameters({});
      setAdvanceSearchDialog(false);

    }
  };

  /* Create Search Chip */
  const createSearchChip = () => {
    const kvpair = Object.entries(searchParameters).map(([k, v]) => ({ 'key': k, 'value': v }));
    const chips = kvpair.map(item => {
      return <Chip key={item.key}
        label={item.value}
        onDelete={(evt) => deleteSearchChip(evt, item.key)}
        color='secondary'
        size='small'
        style={{marginRight: 5}}
      />;
    });
    return chips;
  };
  /* Delete Search Chip and execute Search */
  const deleteSearchChip = (evt, key) => {
    const sp = searchParameters;
    delete sp[key];
    // Execute search on remaining search items if any.
    executeSearch(sp);
  };

  /* 
  Clear Search Fields - No need to perform search, because user has been given Search Button.
  */
  const clearSearchField = (evt, fieldName) => {
    const updatedSP = delete searchParameters[fieldName];
    // We need to clear this filtered searchOption from the searchParameters and input box.
    Array.from(document.querySelectorAll('input')).forEach(input => {
      if (input.name === fieldName) //true
        input.value = null;
    });
    setSearchParameters(updatedSP);
  };

  // Check the path to render the component
  const { orders, searched, activeOrder, isProcessing, toast } = props;

  const orderData = isSearchEnabled ? searched : orders;
  const orderCountByStatus = countOrdersByStatus(orderData);
  return (
    <Page
      title='Orders'
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <Topbar
          title='ORDERS'
          subTitle={`Manage Your Intermodal Dispatch`}
        />
        <Searchbar
          searchType='ADVANCED'
          isAdvanceSearchDialogOpen={isAdvanceSearchDialogOpen}
          searchParameters={searchParameters}
          fnSearchMenuHandler={toggleAdvanceSearchDialog}
        >
          <Grid container spacing={2}>
            {SEARCH_FIELDS.map(field => {
              return (
                <Grid item xs={6} md={4} key={field.name}>
                  <TextField
                    key={field.name}
                    label={field.label}
                    variant='outlined'
                    name={field.name}
                    type={field.type}
                    margin='dense'
                    fullWidth={true}
                    onChange={evt => handleSearchFieldChange(evt)}
                    InputProps={{
                      endAdornment: (<IconButton name={field.name} onClick={(evt) => clearSearchField(evt, field.name)}>
                        <CloseOutlined fontSize='small' />
                      </IconButton>
                      )
                    }}
                  />
                </Grid>
              );
            })
            }
          </Grid>
          <Box
            display='flex'
            justifyContent='flex-end'
            p={2}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={() => executeSearch(searchParameters)}
            >
              Search
            </Button>
          </Box>
        </Searchbar>
        <Notificationbar />
      </Box>
      <Box
        m='20px 0 0 0'
        height='75vh'
      >
        <Box style={{ margin: '10px 0 10px' }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Button
              variant='contained'
              color='primary'
              onClick={addOrder}
            >
              {'New Order'}
            </Button>
            {isSearchEnabled &&
              <Box>
                <Typography variant='h5'
                  style={{
                    letterSpacing: 1,
                    color: theme.colors.grey,
                    textTransform: 'uppercase',
                    marginBottom: '2px',
                    textAlign: 'center',
                  }}
                >
                  {`${searched.length} records found`}
                </Typography>
                {createSearchChip()}
              </Box>
            }
            <ButtonGroup size='small' variant='outlined' color='secondary' aria-label='split button'>
              <Button disabled>{dispatchMode}</Button>
              <Button
                onClick={(evt) => {
                  setDispatchModeMenuOpen(!isDispatchModeMenuOpen);
                  setAnchorRef(evt.currentTarget);
                }}
              >
                <ArrowDropDown />
              </Button>
              <Button onClick={downloadOrderCSV}><ImportExportOutlined /> </Button>
              <Button onClick={toggleViewMode}>
                {'CHANGE VIEW'}
              </Button>                    
            </ButtonGroup>
            <Popper
              open={isDispatchModeMenuOpen}
              anchorEl={anchorRef}              
              modifiers={{
                arrow: {
                  enabled: true,
                  element: anchorRef
                }
              }}
              transition disablePortal style={{ zIndex: 1500 }}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper elevation={2}>
                    <ClickAwayListener onClickAway={() => setDispatchModeMenuOpen(false)}>
                      <MenuList id='split-button-menu'>
                        {DISPATCH_MODE.map((option) => (
                          <>
                            <MenuItem
                              key={option}
                              onClick={(event) => handleDispatchModeChange(event)}
                            >
                              <Typography variant='h6' style={{letterSpacing: 1}}>{option}</Typography>
                            </MenuItem>
                            <Divider />
                          </>                    
                        ))}
                        <Divider />
                        <Calendar
                          compact
                          onSelect={handleDateChange}
                          style={{ width: 240 }}
                          value={dispatchDate}
                          monthDropdownProps={<div></div>}
                        />  
                      </MenuList>          
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
          {isProcessing && <Backdrop className='backDrop' style={{zIndex: 10000000}}
            sx={{ color: '#fff' }}
            open={true}>
              <CircularProgress style={{margin: '0px 50%', display: 'flex'}} />
          </Backdrop>}
          {toast ? <ToastHandler toast={toast} type={toast.type} /> : null}
        </Box>
        {viewMode === 'GRID' ?
          <Grid container spacing={1}>            
            {INTERMODAL_STATUS.map(status =>
              <Grid style={{ minWidth: '20%' }} item key={status} xs={12} sm={6} md={4} lg={2}>
                <Typography
                  className={classes.swimlaneHeader}
                  variant='h5'
                  style={{ color: getTabColor(status), textAlign: 'center', padding: '5px' }}
                >
                  {status} ({orderCountByStatus[status]})
                </Typography>
                <IntermodalGridView
                  orders={filterOrderDataByTab(orderData, status)}
                  fnViewOrderHandler={toggleOrderDetail}
                  fnEditOrderHandler={editOrder}
                  fnDeleteOrderHandler={toggleDeleteOrder}
                  fnOrderStatsHandler={processOrderStats}
                  fnOrderHistoryHandler={processOrderHistory}
                  fnInvoiceHandler={processOrderInvoice}
                  fnFileUploadHandler={processFileUpload}
                  fnMapHandler={processOrderMap}
                />
              </Grid>
            )}
          </Grid> :
          <IntermodalTableView
            orders={orderData}
            fnViewOrderHandler={toggleOrderDetail}
            fnEditOrderHandler={editOrder}
            fnDeleteOrderHandler={toggleDeleteOrder}
            fnOrderStatsHandler={processOrderStats}
            fnOrderHistoryHandler={processOrderHistory}
            fnInvoiceHandler={processOrderInvoice}
            fnFileUploadHandler={processFileUpload}
            fnMapHandler={(evt, selectedOrder) => processOrderMap(evt, selectedOrder)}
          />
        }
      </Box>

      {/* MAP DIALOG */}
      <DataDialog
        open={isOrderMapDialogOpen}
        width={'lg'}
        onCloseHandler={() => setMapDialog(false)}
      >
        <CTMap origins={originMarkers} destinations={destinationMarkers} />
      </DataDialog>

      {/* ORDER DETAIL DIALOG */}
      <DataDialog
        open={isOrderDetailDialogOpen}
        title='Order Detail'
        width={'lg'}
        isProcessing={isProcessing}
        toast={toast}
        onCloseHandler={toggleOrderDetail}
      >
        <OrderDetail
          order={selectedOrder || activeOrder}
        />
      </DataDialog>

      {/* ORDER FORM DIALOG */}
      <DataDialog
        open={isOrderFormDialogOpen}
        title={'Order Form'}
        width={'lg'}
        toast={toast}
        isProcessing={isProcessing}
        onCloseHandler={closeFormDialog}
      >
        <IntermodalOrderForm
          isEdit={action === 'Update'}
          orderForm={order}
          formAction={action}
          tabIndex={0}
          saveFormHandler={(order) => processOrderPersist(order)}
          updateFormHandler={(order) => processOrderPersist(order)}
          cancelDialogHandler={closeFormDialog}
        />
      </DataDialog>

      {/* ORDER STATS DIALOG */}
      <DataDialog
        open={isOrderStatsDialogOpen}
        title={'Order Stats'}
        width={'md'}
        onCloseHandler={() => setOrderStatsDialog(false)}
      >
        <OrderStats
          title={'Order Stats'}
          orderStats={orderStats}
        />
      </DataDialog>

      {/* ORDER HISTORY DIALOG */}
      <DataDialog
        open={isOrderHistoryDialogOpen}
        title={'Order History'}
        width={'md'}
        onCloseHandler={() => setOrderHistoryDialog(false)}
      >
        <OrderStats
          title={'Order History'}
          orderHistory={orderHistory}
        />
      </DataDialog>

      {/* INVOICE DIALOG */}
      <DataDialog
        open={isInvoiceDialogOpen}
        title={'Order Invoice'}
        width={'md'}
        onCloseHandler={() => setInvoiceDialog(false)}
      >
        <InvoiceModal
          invoice={invoice || null}
        />
      </DataDialog>

      {/* NOTIFICATION DIALOG */}
      <NotificationDialog
        open={isNotificationDialogOpen}
        title={'Information'}
        width={'md'}
        onCloseHandler={() => setNotificationDialog(false)}
      >
        <Typography variant='h6'>{notificationMessage}</Typography>
      </NotificationDialog>
      <DataDialog
        open={isFileUploadDialogOpen}
        title={'Upload Documents'}
        width={'md'}
        onCloseHandler={() => setFileUploadDialog(false)}
      >
        <FilesModal
          ref={fileModalChildRef}
          errorData={''}
          messageData={''}
          filesData={[]}
          order={selectedOrder}
        />
      </DataDialog>
      <ConfirmationDialog
        open={isDeleteOrderDialogOpen}
        title={'Delete Order'}
        isProcessing={isProcessing}
        toast={toast}
        handleConfirm={processDeleteOrder}
        handleCancel={()=>closeDeleteOrderDialog(false)}
        handleCloseFunc={()=>closeDeleteOrderDialog(false)}
      >
        <Typography variant='subtitle2'>{`Delete Order: ${selectedOrder && selectedOrder.displayOrderId} `}</Typography>
        <Typography variant='body2' style={{color: 'rgb(255, 56, 56)'}}>Order delete will delete associated legs, expenses, driver charges, invoices and bills.</Typography>
        <Typography variant='subtitle2' style={{marginTop: '15px', textAlign: 'center'}}>{'Do you want to continue ?'}</Typography>
      </ConfirmationDialog>
    </Page>
  );
}

function mapStatesToProps(state) {
  // // Whatever is returned will show up as props inside of Orders
  const { orders, searched, order, isProcessing, toast } = state.orderReducer;
  return {
    orders,
    searched,
    activeOrder: order,
    isProcessing,
    toast
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(OrderAction, dispatch);
}

export default connect(mapStatesToProps, mapDispatchToProps)(IntermodalDispatch);