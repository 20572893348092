import React from 'react';
import Moment from 'moment';

export function getMomentTimeStamp() {
  return Moment();
}

export function getMomentDate() {
  return Moment().format('YYYY-MM-DD');
}

export function formatAppointmentByObject(appointment) {
  const appt = Moment().utc(appointment);
  return appt.format('MM-DD-YY h:mm A');
}

export function formatAppointment(epoch) {
  return Moment(epoch * 1000).format('MM-DD-YY h:mm A');
  //return Moment(epoch).format('MMM Do YYYY hh:mm A');
}

export function formatAppointmentDateOnly(epoch) {
  const ldf = new Date(epoch).toISOString();
  return ldf.split('T')[0];
}

export function getDateFromMoment(momentTS) {
  if (momentTS) {
    return Moment(momentTS).format('L');
  } else {
    return Moment().format('L');
  }
}

export function getRemainingTrialDays(nextPaymentDate) {
  const nextPaymentMoment = Moment(new Date(nextPaymentDate));
  const daysLeft = nextPaymentMoment.diff(Moment(new Date()), 'days') + 1;
  return daysLeft;
}

export function getRemainingPaymentDays(nextPaymentDate) {
  const nextPaymentMoment = Moment(new Date(nextPaymentDate));
  const daysLeft = nextPaymentMoment.diff(Moment(new Date()), 'days') + 1;
  return daysLeft;
}


export function formatAppointmentOrderDetail(msec) {
  return Moment(msec).format('MMM DD, YYYY').toUpperCase();
}

// export function addTimeZone(date) {
//   let tz = Moment(date, 'YYYY-MM-DDTHH:mm:ss');
//   return tz;
// }

export function formatFuelSurcharges(value, type) {
  if (value) {
    if (type && type === '%')
      return `${value.toFixed(2)} %`;
    else 
      return `$${value.toFixed(2)}`;
  }
  return '$0.00';
}

export function formatAddress(address) {
  if (address) {
    return `${address.street}, ${address.city}, ${address.state}, ${address.country}.`;
  } else {
    return null;
  }
}

export function formatCurrency(value, locale) {
  // format number to US dollar
  let currentFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
  });
  if (locale === 'GBP') {
    // format number to British pounds
    currentFormatter = Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      notation: 'compact',
    });
  } else if (locale === 'INR') {
    // format number to Indian rupee
    currentFormatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      notation: 'compact',
    });
  } else if (locale === 'EUR') {
    // format number to Euro
    currentFormatter = Intl.NumberFormat('en-DE', {
      style: 'currency',
      currency: 'EUR',
      notation: 'compact',
    }); 
  }
  return currentFormatter.format(value);  
}

export function formatEpochToDateTime(date) {
  const dt = new Date(date*1000);
  return dt;
}

export function getValidNotifications(company) {
  if (company) {
    let notificationElement;
    if (company.accountType === 'TRIAL') {
      notificationElement = (<span className='error'><i className="fa fa-info-circle"></i>&nbsp;
        {getRemainingTrialDays(company.nextPaymentDate)} Days left in your free trial. Please contact ChuckTruck Accounts to make the payment before trial period expires.
      </span>);
    } else {
      if (getRemainingPaymentDays(company.nextPaymentDate) < 3) {
        notificationElement = (<span className="error"><i className="fa fa-exclamation-circle"></i>&nbsp; 
          {getRemainingPaymentDays(company.nextPaymentDate)} Days left for next payment. Please contact ChuckTruck Accounts to make the payment to continue.
        </span>);
      }
      else if (getRemainingPaymentDays(company.nextPaymentDate) < 5) {
        notificationElement = (<span className="warning"><i className="fa fa-info-circle"></i>&nbsp;
          {getRemainingPaymentDays(company.nextPaymentDate)} Days left for next payment. Please contact ChuckTruck Accounts to make the payment to continue.
        </span>);
      }
    }
    return notificationElement;
  }
}