import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';

import AssignDriver from './assign-driver';
import OrderExpense from '../expenses/order-expense';
import LegCharge from '../driver-charges/driver-charge';
import InputField from 'components/Input-Field/input-field';
import NextStatusModal from './next-status';
import { formatAppointment, formatCurrency, formatAddress } from 'utils/formatter.js';
import { AggregateLegCharges, AggregateCompanyShareExpenses, AggregateDriverBasePayForOrder } from 'utils/calculations.js';
import { LEG_TYPE_MAPPING } from 'utils/constant.js';
import { changeDriverAvailability, } from 'services/driver-service.js';
import * as OrderAction from 'actions/order-action';

import OrderLocation from './order-location';
import OrderEquipment from './order-equipment';
import OrderSummary from './order-summary';
import OrderToolbar from './order-toolbar';

import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import CTCard from 'components/CTCard';
import CTTabs from 'components/CTTabs';
import './order-page.css';
import { CloseOutlined, LocalShippingOutlined, MoneyOutlined, PeopleOutline } from '@material-ui/icons';
import ReactCardFlip from 'react-card-flip';
import { Validate } from 'utils/Validator';
import DataDialog from 'components/dialogs/DataDialog';
import theme from 'theme';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
});

const { colors, status } = theme;
class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: { ...props.order },
      expense: {
        id: '',
        name: '',
        billableAmount: '',
        companyShare: '',
        driverShare: '',
        isPerDay: 'NO',
        expenseType: 'BILLABLE',
      },
      legCharge: {
        id: '',
        name: '',
        amount: '',
        status: 'NEW',
      },
      yard_address: {
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      },
      drivers: [],
      legDriverRate: 0,
      selectedLegTabIndex: 0,
      isEdit: false,
      orderExpenses: null,
      expenseFormAction: 'Save',
      legChargeFormAction: 'Save',
      destinationStatus: null,
      nextStatusActions: null,
      isNextStatusModalOpen: false,
      isAssignDriverModalOpen: false,
      isLegChargeDialogOpen: false,
      isEditDriverRate: false,
      isDriverSMSModelOpen: false,
      isEditOriginDialog: false,
      isEditDestinationDialog: false,
      isEditChassisDialog: false,
      isEditContainerDialog: false,
      isEditOrderSummary: false,
      isMapComponentDialogOpen: false,
      gotNextStatus: false,
      isContainerLoading: false,
      isChassisLoading: false,
      isOriginLoading: false,
      isDestinationLoading: false,
      isAssignDriverLoading: false,
      anchorElement: null,
      anchorMenuElement: null,
      selectedDestinationIdx: 0,
      selectedOriginIdx: 0,

      /* Flip */
      isSummaryFlipped: false,
      isOriginFlipped: false,
      isDestinationFlipped: false,
      isContainerFlipped: false,
      isChassisFlipped: false,
    };
  }

  componentDidMount() {
    if (this.state.order) {
      const legs = this.state.order.legs;
      const currentLeg = legs && legs.length !== 0 ? legs[0] : null;
      const currentLegId = currentLeg && currentLeg.id;
      // const legDriverRate = currentLeg && currentLeg.driver ? currentLeg.driver.rate : 0;
      this.setState({
        selectedLegTabIndex: 0,
        currentLeg,
        currentLegId,
        // legDriverRate,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.order && state.order && props.order.id !== state.order.id) {
      //Change in props
      const order = props.order;
      const legs = props.order.legs;
      const currentLeg = legs && legs.length !== 0 ? legs[0] : null;
      const currentLegId = currentLeg && currentLeg.id;
      return {
        order,
        currentLeg,
        currentLegId,
      };
    }
    return null; // No change to state
  }

  handleClick = event => {
    this.setState({ anchorMenuElement: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorMenuElement: null });
  };

  handlePopoverToggle = (evt) => {
    let anchorElement = this.state.anchorElement;
    if (anchorElement) {
      anchorElement = null;
    } else {
      anchorElement = evt.currentTarget;
    }
    this.setState({ anchorElement });
  };

  changeLegTab = (evt, tabIndex) => {
    const tab = evt.currentTarget.textContent;
    if (tab) {
      const tabName = tab.split(' ').length > 0 ? tab.split(' ')[0] : 'None';
      const legId = tab.split(' ').length > 0 ? parseInt(tab.split(' ')[1]) : 0;
      const currentLeg = this.state.order.legs.filter(leg => leg.id === legId)[0];
      // const legDriverRate = currentLeg && currentLeg.driver ? currentLeg.driver.rate : 0;
      this.setState({
        selectedLegTabIndex: tabIndex,
        tabName: tabName,
        currentLegId: parseInt(legId),
        currentLeg,
        // legDriverRate,
      });
    }
  };

  toggleAssignDriverDialog = () => {
    this.props.resetState('RESET_TOAST_STATE');
    this.setState({ isAssignDriverModalOpen: !this.state.isAssignDriverModalOpen });
  };

  toggleExpensePopover = (order) => {
    this.props.resetState('RESET_TOAST_STATE');
    this.setState({ orderExpenses: order.expenses });
  };

  toggleCTDialog = (toggleType) => {
    this.props.resetState('RESET_TOAST_STATE');
    if (toggleType === 'legCharges') {
      this.setState({
        legChargeFormAction: 'Save',
        isLegChargeDialogOpen: !this.state.isLegChargeDialogOpen,
        legCharge: {
          id: '',
          name: '',
          amount: 0,
          status: 'NEW',
        }
      });
    } else if (toggleType === 'expense') {
      this.setState({
        expenseFormAction: 'Save',
        isExpenseDialogOpen: !this.state.isExpenseDialogOpen,
        expense: {
          id: '',
          name: '',
          billableAmount: '',
          companyShare: '',
          driverShare: '',
          isPerDay: 'NO',
          expenseType: 'BILLABLE',
        },
      });
    } else if (toggleType === 'orderMap') {
      this.setState({ isMapComponentDialogOpen: !this.state.isMapComponentDialogOpen });
    }
  };

  getOrderExpensesForPopover = (orderExpenses) => {
    const expenseElement = orderExpenses.map(exp => {
      return (<tr key={`key:${exp.name}`}>
        <td>{exp.name}</td>
        <td>{exp.amount}</td>
      </tr>);
    });
    return (<table className='table'>
      <tbody>
        {expenseElement}
      </tbody>
    </table>);
  };

  showOrderNetCalculations = (order) => {
    const billableExpense = AggregateCompanyShareExpenses(order.expenses);
    let totalDriverPayForOrder = 0;
    let totalLegCharges = 0;
    order.legs.forEach(leg => {
      totalLegCharges += AggregateLegCharges(leg.legCharges);
      totalDriverPayForOrder += AggregateDriverBasePayForOrder(leg);
    });
    const result = <div style={{ padding: '4px' }}>
      <p>Order Rate <span >{formatCurrency(order.orderRate)}</span></p>
      <p>FSC <span>{formatCurrency(order.fuelCharges)}</span></p>
      <p>Expenses(Profit) <span>{formatCurrency(billableExpense)}</span></p>
      <p>Total DriverPay <span>{formatCurrency(totalDriverPayForOrder)}</span></p>
      <p>Driver Chagres <span>{formatCurrency(totalLegCharges)}</span></p>
    </div>;
    return result;
  };

  deleteOrderLeg = (leg) => {
    const legId = Number(leg.title.split(':')[1].trim());
    if (window.confirm('This will delete current leg. Are you sure you want to delete LegId ? ', legId)) {
      if (window.confirm('Deleting leg is permanent action. Press OK to proceed.')) {
        this.props.deleteOrderLeg(legId);
      }
    }
  };

  assignDriverToLeg = (driver) => {
    this.props.assignDriver(this.state.currentLegId, driver);
  };

  unassignDriver = (legId, driverId) => {
    if (window.confirm('Do you want to un-assign the driver ?')) {
      this.props.unassignDriver(legId, driverId);
    }
  };

  changeDriver = (driver) => {
    this.props.changeDriver(this.state.currentLegId, driver);
  };

  changeDriverAvailability = (driver) => {
    changeDriverAvailability(driver.id).then(() => {
      const drivers = this.state.drivers.map(drvr => {
        if (drvr.id === driver.id) {
          drvr = { ...driver, available: true };
        }
        return drvr;
      });
      this.setState({ drivers });
    });
  };

  /** Order Updates -- call OrderAction methods */
  updateOrderLocation = (orderId, legId, locationId, orderLocation, type) => {
    let location = JSON.parse(JSON.stringify(orderLocation));
    location.appointment = location.appointment;
    location.address = null;
    location = Object.fromEntries(Object.entries(location).map(([k, v]) => [k, [v]]));

    this.props.updateOrderLocation(orderId, legId, locationId, location, type);
  };

  updateOrderEquipment = (orderEquipment, equipmentId, type) => {
    const orderId = this.state.order.id;
    this.props.updateOrderEquipment(orderId, equipmentId, orderEquipment, type);
  };

  updateOrderSummary = (summary) => {
    const orderId = this.state.order.id;
    this.props.updateOrderSummary(orderId, summary);
  };

  handleOriginFlip = (evt) => {
    evt.preventDefault();
    this.setState({ isOriginFlipped: !this.state.isOriginFlipped });
    this.props.resetState('RESET_TOAST_STATE');
  };

  handleDestinationFlip = (evt) => {
    evt.preventDefault();
    this.setState({ isDestinationFlipped: !this.state.isDestinationFlipped });
    this.props.resetState('RESET_TOAST_STATE');
  };

  handleContainerFlipped = (evt) => {
    evt.preventDefault();
    this.setState({ isContainerFlipped: !this.state.isContainerFlipped });
    this.props.resetState('RESET_TOAST_STATE');
  };

  handleChassisFlipped = (evt) => {
    evt.preventDefault();
    this.setState({ isChassisFlipped: !this.state.isChassisFlipped });
    this.props.resetState('RESET_TOAST_STATE');
  };

  handleSummaryFlipped = () => {
    this.setState({ isSummaryFlipped: !this.state.isSummaryFlipped });
    this.props.resetState('RESET_TOAST_STATE');
  };

  toggleNextStatusDialog = (leg) => {
    this.setState({
      isNextStatusModalOpen: !this.state.isNextStatusModalOpen,
      nextStatusActions: leg.nextStatusActions
    });
  };

  updateNextStatus = (status, leg, address) => {
    const orderId = this.state.order.id;
    const timestamp = new Date().toISOString();
    this.props.setNextStatusForOrder(orderId, leg, timestamp, status, address);
    this.setState({ isNextStatusModalOpen: false, gotNextStatus: true });
  };

  getLegStatusColor = (value) => {
    return status.leg[value.toLowerCase()];
  };

  getDestinationStatusColor = (value) => {
    return status.destination[value.toLowerCase()];
  };

  /** Driver Rate */
  handleDriverRateChange = (evt) => {
    this.setState({ legDriverRate: evt.target.value });
  };

  toggleEditDriverRate = (driverRate) => {
    this.setState({
      legDriverRate: driverRate,
      isEditDriverRate: !this.state.isEditDriverRate
    });
  };

  updateDriverBaseRate = (legId, rate) => {
    const driverRate = { 'driverRate': rate };
    this.props.updateDriverRate(legId, driverRate);
    this.setState({ isEditDriverRate: !this.state.isEditDriverRate });
  };

  /** Driver Charges */
  editLegCharge = (legCharge) => {
    this.setState({ legCharge: legCharge, legChargeFormAction: 'Update' });
  };

  saveLegCharge = (charges) => {
    // const orderId = this.state.order.id;
    const legId = this.state.currentLegId;
    this.props.saveCharge(legId, charges);
  };

  updateLegCharge = (charges) => {
    const legId = this.state.currentLegId;
    this.props.updateCharge(legId, charges);
  };

  deleteLegCharge = (legCharge) => {
    const legId = this.state.currentLegId;
    this.props.deleteCharge(legId, legCharge.id);
  };

  //TODO: Change to Action instead
  /** Expenses */
  saveOrderExpense = (orderExpense) => {
    const orderId = this.state.order.id;
    this.props.saveExpense(orderId, orderExpense);
  };

  editOrderExpense = (orderExpense) => {
    this.setState({ expense: orderExpense, expenseFormAction: 'Update' });
  };

  updateOrderExpense = (orderExpense) => {
    const orderId = this.state.order.id;
    this.props.updateExpense(orderId, orderExpense);
  };

  deleteOrderExpense = (orderExpense) => {
    const orderId = this.state.order.id;
    this.props.deleteExpense(orderId, orderExpense.id);
  };

  renderLegStatusCard = (order, leg) => {
    const color = leg.status ? this.getLegStatusColor(leg.status) : status.leg.default;
    const card = {};
    card.title = 'Leg Status';
    card.icon = <LocalShippingOutlined />;
    card.content = <Chip
      variant='outlined'
      size='small'
      label={leg.status}
      style={{ color: color, borderColor: color }}
    />
    card.action = {};
    card.action.name = 'Change Status';
    card.action.icon = (<IconButton size='small' color='primary' disabled={!leg.driver || leg.status === 'COMPLETE'}>
      <SettingsIcon fontSize='small' onClick={() => this.toggleNextStatusDialog(leg)}/>
      <DataDialog
        open={this.state.isNextStatusModalOpen}
        title={'Select Next Action'}
        width={'sm'}
        onCloseHandler={() => this.toggleNextStatusDialog(leg)}
      >
        <NextStatusModal
          orderId={order.id}
          leg={leg}
          yard_address={this.state.yard_address}
          actions={this.state.nextStatusActions}
          fnOnNextStatusClick={(status, address) => this.updateNextStatus(status, leg, address)}
        />
      </DataDialog>

    </IconButton>);
    return card;
  };

  renderDriverCard = (leg, isProcessing, toast) => {
    const driver = leg.driver;

    let actionName = 'Assign Driver';
    let icon = <AddCircleOutlineOutlinedIcon fontSize='small' onClick={() => this.toggleAssignDriverDialog()} />;

    const card = {};
    card.title = 'Driver';
    card.icon = <PeopleOutline />;
    card.content = driver ? <React.Fragment>
      <div>{driver.fullName}</div>
      <div>{driver.phone}</div>
    </React.Fragment> : 'No Driver Assigned.';
    card.action = {};

    if (driver) {
      icon = <AddCircleOutlineOutlinedIcon fontSize='small' onClick={() => this.toggleAssignDriverDialog()} />;
      actionName = 'Change';
      card.action.name = actionName;
      card.action.icon = <React.Fragment>{icon}
        <DataDialog
          open={this.state.isAssignDriverModalOpen}
          title={'Change Driver'}
          width={'md'}
          isProcessing={isProcessing}
          toast={toast}
          onCloseHandler={() => this.toggleAssignDriverDialog()}
        >
          <AssignDriver
            action={'Change'}
            drivers={this.props.drivers}
            rate={driver ? driver.rate : 0}
            fnOnAssignDriverHandler={this.changeDriver}
            fnOnChangeDriverAvailabilityHandler={this.changeDriverAvailability}
          />
        </DataDialog>
      </React.Fragment>;

      card.second_action = {};
      card.second_action.icon = <RemoveCircleOutlineOutlinedIcon fontSize='small' onClick={() => this.unassignDriver(leg.id, driver.id)} />;
      card.second_action.name = 'Remove';
    } else {
      card.action.name = actionName;
      card.action.icon = <React.Fragment>{icon}
        <DataDialog
          open={this.state.isAssignDriverModalOpen}
          title={'Assign Driver'}
          width={'md'}
          isProcessing={isProcessing}
          toast={toast}
          onCloseHandler={() => this.toggleAssignDriverDialog()}
        >
          <AssignDriver
            action={'Assign'}
            drivers={this.props.drivers}
            rate={driver ? driver.rate : 0}
            fnOnAssignDriverHandler={this.assignDriverToLeg}
            fnOnChangeDriverAvailabilityHandler={this.changeDriverAvailability}
          />
        </DataDialog>
      </React.Fragment>;
    }
    return card;
  };

  renderDriverRateCard = (leg) => {
    const driverRate = leg.driver ? leg.driver.rate : 0;
    const card = {};
    card.title = 'Driver Rate';
    card.icon = <MoneyOutlined />;
    card.content = this.state.isEditDriverRate ? <div style={{ display: 'flex' }}>
      <InputField
        type='number'
        label='Enter Driver Rate'
        name='driverRate'
        margin='dense'
        onChange={(evt) => this.handleDriverRateChange(evt)}
        value={this.state.legDriverRate}
        isFullWidth={true}
        valid />
      <IconButton aria-label='edit' style={{ color: 'green' }} onClick={() => this.updateDriverBaseRate(leg.id, this.state.legDriverRate)}>
        <DoneIcon fontSize='small' />
      </IconButton>
      <IconButton aria-label='cancel' style={{ color: 'red' }} onClick={() => this.toggleEditDriverRate()}>
        <CancelIcon fontSize='small' />
      </IconButton>
    </div> : formatCurrency(driverRate);
    card.action = {};
    card.action.name = 'Edit Rate';
    card.action.icon = (<IconButton size='small' color='primary' disabled={!leg.driver}  onClick={() => this.toggleEditDriverRate(driverRate)} >
      <CreateOutlinedIcon fontSize='small'/>
    </IconButton>);
    return card;
  };

  renderLegChargeCard = (leg, isProcessing, toast) => {
    const card = {};
    card.title = 'Driver Charge';
    card.content = formatCurrency(AggregateLegCharges(leg.legCharges));
    card.icon = <MoneyOutlined />;
    card.action = {};
    card.action.name = 'Add Charges';
    card.action.icon = (<IconButton size='small' color='primary' disabled={!leg.driver}>
      <AddCircleOutlineOutlinedIcon fontSize='small' onClick={() => this.toggleCTDialog('legCharges')} />
      <DataDialog
        open={this.state.isLegChargeDialogOpen}
        title={'Driver Charges'}
        width={'md'}
        isProcessing={isProcessing}
        toast={toast}
        onCloseHandler={() => this.toggleCTDialog('legCharges')}
      >
        <Box
          m='10px 0 0 0'
        >
          <LegCharge
            action={this.state.legChargeFormAction}
            legCharge={this.state.legCharge}
            legChargeList={leg.legCharges}
            fnOnSaveHandler={this.saveLegCharge}
            fnOnEditHandler={this.editLegCharge}
            fnOnUpdateHandler={this.updateLegCharge}
            fnOnDeleteHandler={this.deleteLegCharge}
          />
        </Box>
      </DataDialog>
    </IconButton>);
    return card;
  };
  /**
     * This function provides a concise view of a leg by means of widget.
     * At this point we show following leg information (may be modified)
     * 1. Status 2. Driver  3. Equipment
     * @param {*} leg
     */
  renderSummary = (order, leg, isProcessing, toast) => {
    const statusSummary = this.renderLegStatusCard(order, leg, isProcessing, toast);
    const driverSummary = this.renderDriverCard(leg, isProcessing, toast);
    const driverRateSummary = this.renderDriverRateCard(leg, isProcessing, toast);
    const legChargeSummary = this.renderLegChargeCard(leg, isProcessing, toast);
    const summaries = [statusSummary, driverSummary, driverRateSummary, legChargeSummary];
    const { classes } = this.props;
    return (
      <Box mt={2}>
        <Grid
          container
          spacing={2}
        >
          {summaries.map(summary => (
            <Grid
              item
              key={summary.id}
              lg={3}
              md={6}
              xs={12}
            >
              <CTCard
                className={classes.productCard}
                summary={summary}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  /**
   * Render the Origin / Yard information for a given leg
   * @param {*} origins
  */
  renderOriginAndDestination = (order, leg) => {
    const orderId = order.id;
    const origins = leg.origins.map((origin, idx) => {
      const selectedOrigin = leg.origins[this.state.selectedOriginIdx];
      return <Box flex={1} mr={1} key={`bkey:${idx}`}>
        <ReactCardFlip
          isFlipped={this.state.isOriginFlipped}
          flipDirection='horizontal'
        >
          {/* FRONT */}
          <Card key={`key:${idx}`}>
            <CardHeader style={{ padding: '4px' }}
              title={
                <Typography variant='h6'>Origin</Typography>
              }
              action={
                <IconButton aria-label='edit' color='primary' onClick={this.handleOriginFlip} >
                  <CreateOutlinedIcon fontSize='small'/>
                </IconButton>
              }
            />
            <Divider />
            <CardContent style={{ padding: '4px' }}>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={8}>
                  <Typography variant='h6'>{origin.name}</Typography>
                  <Typography variant='body1'>{formatAddress(origin.address)}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography variant='h6'>{formatAppointment(origin.appointment)}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography variant='body1'>PU Number</Typography>
                  <Typography variant='h6'>{origin.puNumber || '-'}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography variant='body1'>PU Location</Typography>
                  <Typography variant='h6'>{origin.puLocation || '-'}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant='body1'>Shipper/Origin Notes</Typography>
                  <Typography variant='h6'>{origin.notes || '-'}</Typography>
                </Grid>                
                <Grid item xs={4} sm={4}>
                  <Typography variant='body1'>Appointment</Typography>
                  <Typography variant='h6'>{origin.openTime ? 'Open' : 'Scheduled'}</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography variant='body1'>Open Appointment Time</Typography>
                  <Typography variant='h6'>{origin.openAppointment || '-'}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* BACK */}
          <Card key={`key:${idx}`}>
            <CardHeader style={{ padding: '4px' }}
              title={
                <Typography variant='h6'>Origin</Typography>
              }
              action={
                <IconButton aria-label='edit' color='primary' onClick={this.handleOriginFlip} >
                  <CloseOutlined fontSize='small'/>
                </IconButton>
              }
            />
            <Divider />
            <CardContent style={{ padding: '4px' }}>
              {this.state.isOriginFlipped && <OrderLocation
                title='Origin'
                legId={leg.id}
                orderId={orderId}
                locationType='Origin'
                tabIndex={idx}
                origin={Validate.parseOrderOrigin(origin)}
                updateFormHandler={(orderLocation) => this.updateOrderLocation(orderId, leg.id, selectedOrigin.id, orderLocation, 'Origin')}
              />}
            </CardContent>
          </Card>
        </ReactCardFlip>
      </Box>
    });
    const destinations = leg.destinations.map((destination, idx) => {
      const orderId = this.state.order.id;
      const selectedDestination = leg.destinations[this.state.selectedDestinationIdx];
      const color = destination.status ? this.getDestinationStatusColor(destination.status) : status.destination.default;
      return <Box flex={1} ml={1} key={`d-key:${idx}`}>
        <ReactCardFlip
          isFlipped={this.state.isDestinationFlipped}
          flipDirection='horizontal'
        >
          {/* FRONT */}
          <Card key={`key:${idx}`}>
            <CardHeader style={{ padding: '4px' }}
              title={
                <Typography variant='h6'>Destination</Typography>
              }
              action={
                <IconButton aria-label='edit' color='primary' onClick={this.handleDestinationFlip} >
                  <CreateOutlinedIcon fontSize='small' />
                </IconButton>
              }
            />
            <Divider />
            <CardContent style={{ padding: '4px' }}>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={8}>
                  <Typography variant='h6'>{destination.name}</Typography>
                  <Typography variant='body1'>{formatAddress(destination.address)}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Chip
                    variant='outlined'
                    size='small'
                    label={destination.status}
                    style={{ color: color, borderColor: color }}
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography variant='body1'>Ref. Number</Typography>
                  <Typography variant='h6'>{destination.referenceNumber || '-'}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography variant='body1'>Destination Notes</Typography>
                  <Typography variant='h6'>{destination.notes || '-'}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography variant='h6'>{formatAppointment(destination.appointment)}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography variant='body1'>Appointment</Typography>
                  <Typography variant='h6'>{destination.openTime ? 'Open' : 'Scheduled'}</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography variant='body1'>Open Appointment Time</Typography>
                  <Typography variant='h6'>{destination.openAppointment || '-'}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* BACK */}
          <Card key={`key:${idx}`}>
            <CardHeader style={{ padding: '4px' }}
              title={
                <Typography variant='h6'>Destination</Typography>
              }
              action={
                <IconButton aria-label='edit' color='primary' onClick={this.handleDestinationFlip}>
                  <CloseOutlined fontSize='small' />
                </IconButton>
              }
            />
            <Divider />
            <CardContent style={{ padding: '10px' }}>
              {this.state.isDestinationFlipped && <OrderLocation
                title='Destination'
                locationType='Destination'
                destination={Validate.parseOrderDestination(destination)}
                updateFormHandler={(orderLocation) => this.updateOrderLocation(orderId, leg.id, selectedDestination.id, orderLocation, 'Destination')}
              />}
            </CardContent>
          </Card>
        </ReactCardFlip>
      </Box>
    });
    return <Box display={'flex'} justifyContent={'space-between'} m='10px 0px'>
      {origins}
      {destinations}
    </Box>
  };

  /**
     * Render Container and Chassis information for a given leg.
     * @param {*} leg
     */

  renderEquipmentInformation = (order, leg) => {
    const { id, orderType, container, chassis } = order;

    let containerNumber = container.deliveryContainerNumber;
    if (order.orderType === 'PICKUP') {
      containerNumber = container.pickupContainerNumber;
    }
    return (
      <Box display={'flex'} justifyContent={'space-between'} m='10px 0px'>
        {/* CONTAINER */}
        <Box flex={1} mr={1}>
          <ReactCardFlip
            isFlipped={this.state.isContainerFlipped}
            flipDirection='horizontal'
          >
            {/* FRONT */}
            <Card>
              <CardHeader style={{ padding: '4px' }}
                title={
                  <Typography variant='h6'>Container</Typography>
                }
                action={
                  <IconButton aria-label='edit' color='primary' onClick={this.handleContainerFlipped}>
                    <CreateOutlinedIcon fontSize='small' />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent style={{ padding: '4px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4}>
                    <Typography variant='body1'>Container Number</Typography>
                    <Typography variant='h6'>{containerNumber}</Typography>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typography variant='body1'>Last Free Date</Typography>
                    <Typography variant='h6'>{container.lastFreeDate}</Typography>
                  </Grid>
                  {leg.containerStatus ? <Grid item xs={4} sm={4}>
                    <header className={`content-row__text content-row__text--primary Destination-status equipment-${leg.containerStatus ? leg.containerStatus.toLowerCase() : ''}`}>
                      {leg.containerStatus}
                    </header>
                  </Grid> : null}
                  <Grid item xs={4} sm={4}>
                    <Typography variant='body1'>Line</Typography>
                    <Typography variant='h6'>{container.line || '-'}</Typography>
                  </Grid>
                  {order.orderType === 'PICKUP' || order.orderType === 'DROP_PICK' ?
                    <Grid item xs={4} sm={4}>
                      <Typography variant='body1'>Booking Number</Typography>
                      <Typography variant='h6'>{container.bookingNumber}</Typography>
                    </Grid> : null}
                  {order.orderType === 'DROP_PICK' ?
                    <Grid item xs={4} sm={4}>
                      <Typography variant='body1'>Pickup Number</Typography>
                      <Typography variant='h6'>{container.pickupContainerNumber}</Typography>
                    </Grid> : null}
                  <Grid item xs={8} sm={8}>
                    <Typography variant='body1'>Drop Location</Typography>
                    <Typography variant='h6'>{container.dropLocation || '-'}</Typography>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typography variant='body1'>Notes</Typography>
                    <Typography variant='h6'>{container.notes}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* BACK */}
            <Card>
              <CardHeader style={{ padding: '4px' }}
                title={
                  <Typography variant='h6'>Container</Typography>
                }
                action={
                  <IconButton aria-label='edit' color='primary' onClick={this.handleContainerFlipped}>
                    <CloseOutlined fontSize='small' />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent style={{ padding: '4px' }}>
                {this.state.isContainerFlipped &&<OrderEquipment
                  title='Order Container'
                  equipmentType='Container'
                  orderType={orderType}
                  container={JSON.parse(JSON.stringify(container))}
                  chassis={null}
                  updateFormHandler={data => this.updateOrderEquipment(data, container.id, 'CONTAINER')}
                />}
              </CardContent>
            </Card>
          </ReactCardFlip>
        </Box>
        {/* CHASSSIS */}
        <Box flex={1} ml={1}>
          <ReactCardFlip
            isFlipped={this.state.isChassisFlipped}
            flipDirection='horizontal'
          >
            {/* FRONT */}
            <Card>
              <CardHeader style={{ padding: '4px' }}
                title={
                  <Typography variant='h6'>Chassis</Typography>
                }
                action={
                  <IconButton aria-label='edit' color='primary' onClick={this.handleChassisFlipped}>
                    <CreateOutlinedIcon fontSize='small' />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent style={{ padding: '4px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4}>
                    <Typography variant='body1'>Chassis Number</Typography>
                    <Typography variant='h6'>{chassis.chassisNumber || 'Pending'}</Typography>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typography variant='body1'>Chassis Split</Typography>
                    <Typography variant='h6'>{chassis.isChassisSplit === true ? 'Yes' : 'No'}</Typography>
                  </Grid>
                  {leg.chassisStatus ? <Grid item xs={4} sm={4}>
                    <header className={`content-row__text content-row__text--primary Destination-status equipment-${leg.chassisStatus ? leg.chassisStatus.toLowerCase() : ''}`}>
                      {leg.chassisStatus}
                    </header>
                  </Grid> : null}
                  <Grid item xs={3} sm={3}>
                    <Typography variant='body1'>Company</Typography>
                    <Typography variant='h6'>{chassis.company || '-'}</Typography>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <Typography variant='body1'>Rent/Day</Typography>
                    <Typography variant='h6'>{formatCurrency(chassis.rentPerDay)}</Typography>
                  </Grid>
                  <Grid item xs={7} sm={7}>
                    <Typography variant='body1'>Drop Location</Typography>
                    <Typography variant='h6'>{container.dropLocation || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='body1'>Notes</Typography>
                    <Typography variant='h6'>{chassis.notes}</Typography>
                  </Grid>
                  {chassis && order.orderType === 'DROP_PICK' ?
                    <>
                      <Grid item xs={4} sm={4}>
                        <Typography variant='body1'>Pickup Number</Typography>
                        <Typography variant='h6'>{chassis.pickupChassisNumber || '-'}</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Typography variant='body1'>Pickup Split</Typography>
                        <Typography variant='h6'>{chassis.isChassisSplit === true ? 'Yes' : 'No'}</Typography>
                      </Grid>
                      {leg.pickupChassisStatus ? <Grid item xs={4} sm={4}>
                        <header className={`content-row__text content-row__text--primary Destination-status equipment-${leg.chassisStatus ? leg.chassisStatus.toLowerCase() : ''}`}>
                          {leg.chassisStatus}
                        </header>
                      </Grid> : null}
                      <Grid item xs={3} sm={3}>
                        <Typography variant='body1'>Company</Typography>
                        <Typography variant='h6'>{chassis.pickupChassisCompany || '-'}</Typography>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <Typography variant='body1'>Rent/Day</Typography>
                        <Typography variant='h6'>{formatCurrency(chassis.pickupRentPerDay)}</Typography>
                      </Grid>
                      <Grid item xs={7} sm={7}>
                        <Typography variant='body1'>Drop Location</Typography>
                        <Typography variant='h6'>{container.dropLocation || '-'}</Typography>
                      </Grid>
                    </> : null}
                </Grid>
              </CardContent>
            </Card>

            {/* BACK */}
            <Card>
              <CardHeader style={{ padding: '4px' }}
                title={
                  <Typography variant='h6'>Chassis</Typography>
                }
                action={
                  <IconButton aria-label='edit' color='primary' onClick={this.handleChassisFlipped}>
                    <CloseOutlined fontSize='small'/>
                  </IconButton>
                }
              />
              <Divider />
              <CardContent style={{ padding: '4px' }}>
                {this.state.isChassisFlipped &&<OrderEquipment
                  title='Order Chassis'
                  equipmentType='Chassis'
                  orderType={orderType}
                  chassis={JSON.parse(JSON.stringify(chassis))}
                  updateFormHandler={data => this.updateOrderEquipment(data, chassis.id, 'CHASSIS')}
                />}
              </CardContent>
            </Card>
          </ReactCardFlip>
        </Box>
      </Box>
    )
  };

  /**
     * Order is a complex entity and detail view needs to display all the
     * required and related informtion for an order.
     *
     * Following is the render order
     * 1. Render Status and Net $amount (profit and loss)
     * 2. Render Summary mostly read only data
     * 3. A order can have 1 or more legs. Each leg is rendered as a tab
     * 4. A Leg tab shows Status, Driver and Equipment informtion (same across order)
     * 5. A leg tab will also render Origin (Shipper) and Destination
     * 6. Each leg also render equipment status.
     */
  render() {
    const { order, isProcessing, toast } = this.props;
    if (order && order.legs) {
      const legs = order.legs;
      const legTabs = legs.map(leg => {
        return `${LEG_TYPE_MAPPING[leg.legType]} ${leg.id}`;
      });
      return (
        <div>
          <ReactCardFlip
            isFlipped={this.state.isSummaryFlipped}
            flipDirection='horizontal'
          >
            {/* FRONT */}
            <OrderToolbar
              order={order}
              fnHandleEditOrderDialog={this.handleSummaryFlipped}
              fnHandleExpenseDialog={() => this.toggleCTDialog('expense')}
            />
            {/* BACK */}
            <Card>
              <CardHeader style={{ padding: '4px' }}
                title={
                  <Typography variant='h6'>Summary</Typography>
                }
                action={
                  <IconButton aria-label='edit' color='primary' onClick={this.handleSummaryFlipped} >
                    <CloseOutlined fontSize='small'/>
                  </IconButton>
                }
              />
              <Divider />
              <CardContent style={{ padding: '4px' }}>
                {this.state.isSummaryFlipped &&<OrderSummary
                  title='Summary'
                  summary={Validate.parseOrderSummary(order)}
                  updateFormHandler={summary => this.updateOrderSummary(summary)}
                />}
              </CardContent>
            </Card>
          </ReactCardFlip>
          <CTTabs
            labels={legTabs}
            tabIndex={this.state.selectedLegTabIndex}
            addNewButtonEnabled={false}
            removeButtonEnabled={false}
            fnOnRemoveTabHandler={tab => this.deleteOrderLeg(tab)}
            fnOnTabChangeHandler={(tab, evt) => this.changeLegTab(evt, tab)}
          />
          {legs.map(leg => {
            return leg.id === this.state.currentLegId ?
              <div key={`key:leg:${leg.id}`}>
                {this.renderSummary(order, leg, isProcessing, toast)}
                {' '}
                {this.renderOriginAndDestination(order, leg)}
                {' '}
                {this.renderEquipmentInformation(order, leg)}
              </div> : null;
          })
          }
          <DataDialog
            open={this.state.isExpenseDialogOpen}
            title={'Expense'}
            width={'md'}
            isProcessing={isProcessing}
            toast={toast}
            onCloseHandler={() => this.toggleCTDialog('expense')}
          >
            <OrderExpense
              action={this.state.expenseFormAction}
              expense={this.state.expense}
              expenseList={order.expenses || []}
              fnOnSaveHandler={this.saveOrderExpense}
              fnOnUpdateHandler={this.updateOrderExpense}
              fnOnEditHandler={this.editOrderExpense}
              fnOnDeleteHandler={this.deleteOrderExpense}
            />
          </DataDialog>

        </div>
      );
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(OrderAction, dispatch);
}

function mapStateToProps(state) {
  const { order, toast, isProcessing } = state.orderReducer;
  const { drivers } = state.driverReducer;
  return {
    order: order,
    drivers,
    toast,
    isProcessing,
  };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OrderDetail));