import { serviceHandler } from './service-executor';

const BILL_API = '/api/bills';

export const findBills = (driver, params) => {
  let URL = `${BILL_API}/drivers/${driver}`;
  return serviceHandler(URL, 'POST', params);
};

export const processBillPayment = (payment) => {
  const URL = `${BILL_API}/pay`;
  return serviceHandler(URL, 'POST', payment);
};

export const unpayDriverLegs = (legIds) => {
  let legIdArray = `${legIds[0]},`;
  legIds.forEach((id, idx) => {
    if (idx !== 0)
      legIdArray += `${id},`;
  });
  const URL = `${BILL_API}/${legIdArray}/unpay`;
  return serviceHandler(URL, 'GET');
};

export const getBillReport = (legIds, start, end) => {
  let legIdArray = `${legIds[0]}`;
  legIds.forEach((id, idx) => {
    if (idx !== 0)
      legIdArray += `,${id}`;
  });
  const URL = `${BILL_API}/${legIdArray}/report?startDate=${start}&endDate=${end}`;
  return serviceHandler(URL, 'GET_BLOB');
};

export const saveCheck = (billId, check) => {
  const URL = `${BILL_API}/${billId}/check/save`;
  return serviceHandler(URL, 'POST', check);
};

export const updateLegRate = (legId, rate) => {
  const URL = `/api/legs/${legId}/driverRate/update`;
  return serviceHandler(URL, 'POST', rate);
};

// export const updateOrderRate = (order) => {
//   const URL = `/api/orders/${order.orderId}/rate/update`;
//   return serviceHandler(URL, 'POST', order);
// };

export const save = (bill) => {
  console.log(bill);
};

export const update = (bill) => {
  console.log(bill);
};
