import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import { CloseOutlined, NotificationImportantOutlined } from '@material-ui/icons';
import { getOrderStatusCount } from 'services/notification-service';

const Notificationbar = () => {
  const [orderCounter, setOrderCounter] = useState([]);

  useEffect(() => {
    getOrderStatusCount('YARD').then((res) => {
      if (res && res.data) {
        res.data.forEach(item => {
          orderCounter.push({ 'title': item.orderStatus, 'count': item.count });
        });
      }
    });
  }, []);

  const notifications = [
    {
      title: '',
      content: 'A security question has been added or changed to your account'
    },
    {
      title: '',
      content: 'A recent sign-in to your FreightSmart account (ecef860a) from an unknown device or browser.'
    },
    {
      title: '',
      content: 'A security question has been added or changed to your account'
    },
  ];

  /* Notification Menu */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOrderCounter([]);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        {/* ICONS */}
        <Tooltip title='Notifications' arrow>
          <IconButton onClick={handleClick}>
            <Badge badgeContent={18} color='secondary'>
              <NotificationImportantOutlined />
            </Badge>
          </IconButton>
        </Tooltip>
        <Avatar style={{ width: 35, height: 35, marginLeft: 20, background: '#e33371' }}>
          <Typography>{'RD'}</Typography>
        </Avatar>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='notifications'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          style: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h5'>{'Notifications'}</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
        </MenuItem>
        <Divider />
        <Paper component='ul' style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          listStyle: 'none',
          padding: '5px',
          margin: '5px auto',
          width: '90%',
        }}>
          {orderCounter.map(item => {
            const label = item.title + ' ' + item.count;
            return (
              <Chip
                key={item.title}
                size='small'
                label={label}
                variant='outlined'
                color='secondary'
                style={{
                  marginLeft: '5px',
                  textAlign: 'center'
                }}
              />
            );
          })}
        </Paper>
        {
          notifications.map(({ content }, index) => (
            <Box key={index}>
              <MenuItem onClick={handleClose} style={{ minHeight: '48px' }}>
                <Typography variant='body1'>{content}</Typography>
              </MenuItem>
              <Divider />
            </Box>
          ))
        }
      </Menu>
    </>
  );
};

export default Notificationbar;