import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { formatCurrency } from 'utils/formatter.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  cardContent: {
    padding: 8,
    '&:last-child': {
      paddingBottom: 10
    }
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  expenseIcon: {
    padding: 0,
    color: 'green'
  }
}));

const TenantToolbar = ({ className,
  tenant,
  fnHandleEditTenantDialog,
  fnOnPaymentHandler,
  ...rest 
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box className={classes.root} mt={3}>
        <Box
          display="flex"
          justifyContent="space-evenly"
        >
          <Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {tenant.company}
            </Typography>
            <Typography
              align="center"
              variant="body1"
              color="textSecondary"
            >
              {'COMPANY'}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {tenant.tenantId}
            </Typography>
            <Typography
              align="center"
              variant="body1"
              color="textSecondary"
            >
              {'ACCOUNT NUMBER'}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {tenant.accountPlan}
            </Typography>
            <Typography
              align="center"
              variant="body1"
              color="textSecondary"
            >
              {'PLAN'}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {tenant.registrationDate}
            </Typography>
            <Typography
              align="center"
              variant="body1"
              color="textSecondary"
            >
              {'REGISTRATION DATE'}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {tenant.nextPaymentDate}
            </Typography>
            <Typography
              align="center"
              variant="body1"
              color="textSecondary"
            >
              {'NEXT PAYMENT'}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {tenant.lastPaidDate}
            </Typography>
            <Typography
              align="center"
              variant="body1"
              color="textSecondary"
            >
              {'LAST PAYMENT DATE'}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {formatCurrency(tenant.paymentAmount)}
            </Typography>
            <Typography
              align="center"
              variant="body1"
              color="textSecondary"
            >
              {'PAYMENT DUE'}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {tenant.status}
            </Typography>
            <Typography
              align="center"
              variant="body1"
              color="textSecondary"
            >
              {'STATUS'}
            </Typography>
          </Box>                
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Box>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              <IconButton color="primary" aria-label="add an alarm">
                <EditRoundedIcon onClick={fnHandleEditTenantDialog} />
              </IconButton>
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Box>
            <Button
              variant='contained'
              color='primary'
              onClick={fnOnPaymentHandler}
            >
              {'Make Payment'}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

TenantToolbar.propTypes = {
  className: PropTypes.string
};

export default TenantToolbar;