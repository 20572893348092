import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

export const CTDialog = (props) => {
  return (
    <Dialog maxWidth='md' open={props.open}>
      <DialogTitle >
        <Typography variant="h6">{props.title}</Typography>
      </DialogTitle>
      <DialogContent>
        {props.content}
      </DialogContent>
      <DialogActions>
        {props.action}
      </DialogActions>
    </Dialog>
  );
};

export default CTDialog;