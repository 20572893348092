const initState = {
  drivers: [],
  searched: [],
  driver: {},
  toast: null,
  isProcessing: false,
  isLoaded: false,
};

export const driverReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_DRIVER_STATE':
    return { 
      ...initState
    };
  case 'RESET_TOAST_STATE':
    return {
      ...state,
      toast: null, 
      isProcessing: false,
    };
  case 'PROCESSING_DRIVER':
    return {
      ...state,
      toast: { type: 'LOADING', message: 'Please wait ...'},
      isProcessing: true,
    };

  case 'FETCH_DRIVERS':    
    return {
      ...state,
      drivers: action.payload,      
      toast: {type: 'SUCCESS'},
      isProcessing: false,
      isLoaded: true, // This is to make sure we avoid additional fetch calls.
    };
  case 'ADD_DRIVER':
    return {
      ...state,      
      drivers: [...state.drivers, action.payload],
      toast: { type: 'SUCCESS', message: 'Driver saved successfully.'},
      isProcessing: false,
    };     
  case 'UPDATE_DRIVER':
    return {
      ...state,      
      drivers: state.drivers.map(driver => driver.id === action.payload.id ? {...action.payload } : { ...driver }),
      searched: state.searched.map(driver => driver.id === action.payload.id ? {...action.payload } : { ...driver }),
      toast: { type: 'SUCCESS', message: 'Driver updated successfully.' },
      isProcessing: false,
    };
  case 'SEARCH_DRIVERS':
    return {
      ...state,
      searched: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,      
    };    
  case 'DRIVER_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};
