import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import { formatCurrency, formatFuelSurcharges } from 'utils/formatter.js';
import { AggregateOrderExpenses, calculateOrderTotal } from 'utils/calculations.js';
import { AddCircleOutlineOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    marginTop: 8,
  },
  cardContent: {
    padding: 8,
    '&:last-child': {
      paddingBottom: 10
    }
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  expenseIcon: {
    padding: 0,
    color: 'green'
  }
}));

const OrderToolbar = ({ className, order, fnHandleEditOrderDialog, fnHandleExpenseDialog, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="space-evenly"
      >
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {order.displayOrderId}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'Id'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {order.orderType}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'TYPE'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {order.broker.name}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'BROKER'}
          </Typography>
        </Box>        
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {order.brokerOrderNumber}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'BROKER REF. #'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {formatCurrency(order.orderRate)}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'RATE'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {formatFuelSurcharges(order.fuelCharges, order.fuelChargesType)}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'FSC'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />            
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {formatCurrency(AggregateOrderExpenses(order.expenses))}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'EXPENSE'}
            <IconButton aria-label="money" onClick={() => fnHandleExpenseDialog('expense')} classes={{root: classes.expenseIcon }}>
              <AddCircleOutlineOutlined fontSize="small" />
            </IconButton>
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />        
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {formatCurrency(calculateOrderTotal(order.orderRate, order.fuelCharges, order.fuelChargesType, order.expenses))}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'TOTAL'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box>
          <IconButton aria-label="edit" color="primary" onClick={() => fnHandleEditOrderDialog('editContainerDialog')} >
            <CreateOutlinedIcon fontSize="small"/>
          </IconButton>
        </Box>                
      </Box>
    </div>
  );
};

OrderToolbar.propTypes = {
  className: PropTypes.string
};

export default OrderToolbar;
