import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

const SelectInput = props => {

  const { label, name, value, variant, margin, fullWidth, error, onBlur, onChange, options, multiple } = props;
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id='select-label' style={{ margin: '-5px 15px', position: 'absolute' }} >{label}</InputLabel>
      <Select
        labelId='select-label'
        label={label}
        name={name}
        value={value}
        variant={variant ? variant : 'outlined'}
        fullWidth={fullWidth}
        error={error}
        onBlur={onBlur}
        onChange={onChange}
        margin={margin ? margin : 'none'}
        multiple={multiple}
      >
        {options.map((option, idx) => {
          const value = option.value ? options.value : option;
          const key = option.key ? `key-${option.key}-${idx}` : `key-${option}-${idx}`;
          const display = option.display ? option.display : option;
          return <MenuItem key={key} value={value}>
            {display}
          </MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  controlFunc: PropTypes.func,
  placeholder: PropTypes.string
};

export default SelectInput;
