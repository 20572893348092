import React, { useEffect, useState } from 'react';
import Page from 'components/Page';
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@material-ui/core';
import Topbar from 'containers/global/Topbar';
import Notificationbar from 'containers/global/Notificationbar';
import { Calendar, DateRangePicker } from 'rsuite';
import CTTabs from 'components/CTTabs';
import BarChart from 'components/Charts/barchart';
import { formatAppointmentDateOnly, formatCurrency } from 'utils/formatter';
import PieChart from 'components/Charts/piechart';
import ActivityGrid from 'components/Charts/activity-grid';
import MapChart from 'components/Charts/mapchart';
import { getReportByType } from 'services/report-service';
import ReportSummary from './report-summary';
import { DataGrid } from '@mui/x-data-grid';

const TABS = ['Dispatch Report'];
const QUARTERS = ['FIRST QUARTER', 'SECOND QUARTER', 'THIRD QUARTER', 'FORTH QUARTER'];

const ReportPage = (() => {
  const startOfMonthDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0,10);
  const today = new Date(new Date().setHours(0,0,0,0)).toISOString().slice(0,10);

  const [tabIndex, setTabIndex] = useState(0);
  const [dateRange, setDateRange] = useState(null);
  const [startDate, setStartDate] = useState(startOfMonthDate)
  const [endDate, setEndDate] = useState(today);
  const [isQuarterMenuOpen, setQuarterMenuOpen] = useState(false);
  const [anchorRef, setAnchorRef] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [toast, setToast] = useState({});

  /* Reports */
  /* Summary Stats */
  const [orderSummaryStats, setOrderSummaryStats] = useState(null);
  const [invoiceSummaryStats, setInvoiceSummaryStats] = useState(null);
  const [billSummaryStats, setBillSummaryStats] = useState(null);
  const [netStats, setNetStats] = useState(null);
  const [invoiceCountStats, setInvoiceCountStats] = useState(null);
  const [billCountStats, setBillCountStats] = useState(null);

  /* Time Series */
  const [consolidatedDataByTS, setConsolidatedDataByTS] = useState(null);
  const [billAmountByDateTS, setBillAmountByDateTS] = useState(null);
  const [invoiceAmountByDateTS, setInvoiceAmountByDateTS] = useState(null);
  const [orderNetByCompleteDateTS, setOrderNetByCompleteDateTS] = useState(null);

  /* PieChart */
  const [expenseAndChargesData, setExpenseAndChargesData] = useState(null);//Expenses & Charges  
  const [orderTypeCountsData, setOrderTypeCountsData] = useState(null); //Order Type

  /* Tabular Data */
  const [driverPaymentStats, setDriverPaymentStats] = useState(null);
  const [brokerAccountingStats, setBrokerAccountingStats] = useState(null);

  /* Activity Chart */
  const [orderCountByDate, setOrderCountByDate] = useState(null);

  /* Top N */
  const [topNDestinations, setTopNDestinations] = useState(null);
  const [topNOrigins, setTopNOrigins] = useState(null);

  /* Map */
  const [originGeoData, setOriginGeoData] = useState(null);
  const [destinationGeoData, setDestinationGeoData] = useState(null);

  const locationColumns = [
    { field: 'id', headerName: 'Id' },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'count', headerName: 'Count', flex: 1 }
  ];
  const driverSettlementColumns = [
    { field: 'id', headerName: 'Id', flex: .5 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'orderCount', headerName: 'Order Count', flex: 1 },
    { field: 'totalAmount', headerName: 'Amount', flex: 1, renderCell: (params) => {
      return formatCurrency(params.row.totalAmount)
    }},
    { field: 'paid', headerName: 'Payment Made', flex: 1, renderCell: (params) => {
      return formatCurrency(params.row.paid)
    }}
  ];
  const brokerColumns = [
    { field: 'id', headerName: 'Id', flex: .5 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'orderCount', headerName: 'Order Count', flex: 1 },
    { field: 'totalAmount', headerName: 'Amount', flex: 1, renderCell: (params) => {
      return formatCurrency(params.row.totalAmount) }
    },
    { field: 'paid', headerName: 'Payment Received', flex: 1, renderCell: (params) => {
      return formatCurrency(params.row.paid)
    }
    },
  ];

  useEffect(() => {
    prepareReport(startDate, endDate);
  }, [startDate, endDate]);

  const handleDateRangeChange = (evt) => {
    if (evt !== null && evt.length === 2) {
      const start = evt[0].toISOString().substring(0,10);
      const end = evt[1].toISOString().substring(0,10);
      setDateRange(evt);
      setStartDate(start);
      setEndDate(end)
    }
  };

  const handleQuarterChange = (option) => {
    var quarter = 0;
    if(option === QUARTERS[1]) { //Second 
      quarter = 1;
    } else if (option === QUARTERS[2]) { // Third
      quarter = 2;
    } else if (option === QUARTERS[3]) {
      quarter = 3;
    }
    const quarterStartDate = new Date(new Date().getFullYear(), quarter*3, 1).toISOString().slice(0,10);
    const quarterEndDate = new Date(new Date().getFullYear(), quarter*3 + 3, 1).toISOString().slice(0,10);
    setStartDate(quarterStartDate);
    setEndDate(quarterEndDate);
  };

  const prepareReport = async (startDate, endDate) => {    
    setIsProcessing(true);
    await Promise.all([
      getReportByType('STATS', startDate, endDate).then(handleSummaryStats),
      getReportByType('ORDERNET', startDate, endDate).then(handleOrderNetTimeSeries),
      getReportByType('EXPENSESANDCHARGES', startDate, endDate).then(handleExpenseChargeData),
      getReportByType('ORDERTYPE', startDate, endDate).then(handleOrderTypeData),
      getReportByType('PAYMENTS', startDate, endDate).then(handleDriverPaymentStats),
      getReportByType('INVOICES', startDate, endDate).then(handleBrokerAccountingStats),
      getReportByType('ACTIVITY', startDate, endDate).then(handleOrderCountActivity),
      getReportByType('TOP', startDate, endDate).then(handleTopLocations),
    ]);
  };

  /* Response Handlers */
  const handleSummaryStats = (response) => {
    const { data, status } = response;
    if (status === 200) {
      setOrderSummaryStats(data.orderCountByStatus);
      setInvoiceSummaryStats(data.invoiceAmountByStatus);
      setInvoiceCountStats(data.invoiceCountByStatus)
      setBillSummaryStats(data.driverPaymentByStatus);
      setBillCountStats(data.billCountByStatus);
      setNetStats(data.netStats);
    }
  };
  const handleOrderNetTimeSeries = (response) => {
    const { data, status } = response;
    const result = [];
    if (status === 200) {
      const { orderNetByCompleteDateTS, billAmountByDateTS, invoiceAmountByDateTS } = data;
      Object.entries(billAmountByDateTS).forEach((pair, idx) => {
        const key = pair[0];
        const billAmount = pair[1];
        const invoiceAmount = invoiceAmountByDateTS[key];
        const net = orderNetByCompleteDateTS[key];
        if (billAmount && invoiceAmount) {
          result.push({ 'id': idx+1, 'appointment': formatAppointmentDateOnly(Number(key)), 'invoice': Number(billAmount), 'payment': Number(invoiceAmount), 'orderNet': Number(net) });
        }
      });
    }
    setConsolidatedDataByTS(result);
  };

  const handleExpenseChargeData = (response) => {
    const { data, status } = response;
    if (status === 200) {
      const charges = data.expenseAndCharges.charges;
      const expenses = data.expenseAndCharges.expenses;

      const result = [
        { 'id': 'charges', 'label': 'charges', 'value': Number(charges.amount) },
        { 'id': 'expense', 'label': 'expenses', 'value': Number(expenses.amount) }
      ];
      setExpenseAndChargesData(result);
    }
  };
  const handleOrderTypeData = (response) => {
    const { data, status } = response;
    if (status === 200) {
      const result = [];
      Object.entries(data.orderTypeCounts).forEach(pair => {
        const label = pair[0];
        const value = pair[1];

        result.push({ 'id': label, 'label': label, 'value': Number(value) });
      });
      setOrderTypeCountsData(result);
    }
  };

  const handleDriverPaymentStats = (response) => {
    const { data, status } = response;
    if (status === 200) {
      data.driverAccountingStats.forEach((item, idx) => item.id = idx);
      setDriverPaymentStats(data.driverAccountingStats);
    }
  };

  const handleBrokerAccountingStats = (response) => {
    const { data, status } = response;
    if (status === 200) {
      data.brokerAccountingStats.forEach((item, idx) => item.id = idx);
      setBrokerAccountingStats(data.brokerAccountingStats);
    }
  };

  const handleOrderCountActivity = (response) => {
    const { data, status } = response;
    if (status === 200) {
      const result = [];
      data.orderCountByDay.forEach(item => {
        const key = item.dateTS;
        const value = item.count;
        if (key) {
          result.push({ 'day': key, 'value': Number(value) });
        }
      });
      setOrderCountByDate(result);
    }
  };

  const handleTopLocations = (response) => {
    const { data, status } = response;
    if (status === 200) {
      const origins = []
      const destinations = [];
      const { topNOrigins, topNDestinations } = data;
      Object.entries(topNOrigins).forEach((pair, idx) => {
        origins.push({ 'id': pair[0], 'label': pair[0], 'value': pair[1] });
      });
      Object.entries(topNDestinations).forEach((pair, idx) => {
        destinations.push({ 'id': pair[0], 'label': pair[0], 'value': pair[1] });
      });
      setTopNOrigins(origins);
      setTopNDestinations(destinations);
    }
  };

  return (
    <Page title='reports'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Topbar
          title='REPORT'
          subTitle='View Your Reports'
        />
        <Notificationbar />
      </Box>
      <Box
        m='20px 0 0 0'
        height='75vh'
      >
        <Box style={{ margin: '10px 0' }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <DateRangePicker
              format='MMM dd, yyyy'
              value={dateRange}
              onChange={handleDateRangeChange}
            />
            <Typography variant='h5' 
              style={{ 
                letterSpacing: 1,
                color: '#a3a3a3',
                textTransform: 'uppercase',
                marginBottom: '2px'
              }}
            >
              {`View Report from ${startDate} to ${endDate}`}
            </Typography>
            <ButtonGroup
                variant='outlined'
                color='secondary'
              >
              <Button onClick={(evt)=>{
                setQuarterMenuOpen(true);
                setAnchorRef(evt.currentTarget);
              }}>Select Quarter</Button>
              <Button>Preview Report</Button>
            </ButtonGroup>
            <Popper
              open={isQuarterMenuOpen}
              anchorEl={anchorRef}              
              modifiers={{
                arrow: {
                  enabled: true,
                  element: anchorRef
                }
              }}
              transition disablePortal style={{ zIndex: 1500 }}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper elevation={2}>
                    <ClickAwayListener onClickAway={() => setQuarterMenuOpen(false)}>
                      <MenuList id='split-button-menu'>
                        {QUARTERS.map((option) => (
                          <>
                            <MenuItem
                              key={option}
                              onClick={() => handleQuarterChange(option)}
                            >
                              <Typography variant='h6' style={{letterSpacing: 1}}>{option}</Typography>
                            </MenuItem>
                            <Divider />
                          </>                    
                        ))}
                      </MenuList>          
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>            
          </Box>
        </Box>
        <CTTabs
          labels={TABS}
          tabIndex={tabIndex}
        // fnOnTabChangeHandler={this.changeTab}
        />

        <ReportSummary
          orderSummaryStats={orderSummaryStats}
          invoiceSummaryStats={invoiceSummaryStats}
          invoiceCountStats={invoiceCountStats}
          billSummaryStats={billSummaryStats}
          billCountStats={billCountStats}
          netStats={netStats||{}}
        />
        <Box
          mt={2}
        >
          <Card>
            <CardHeader
              title={<Typography variant='subtitle2'>{'Accounting Stats'}</Typography>}
            />
            <Divider />
            <CardContent style={{ height: '300px' }}>
              <BarChart
                data={consolidatedDataByTS || []}
              />
            </CardContent>
          </Card>
        </Box>
        <Box
          mt={2}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Card style={{flex: 1, marginRight: '5px'}}>
            <CardHeader
              title={<Typography variant='subtitle2'>{'Driver Settlement Stats'}</Typography>}
            />
            <Divider />
            <CardContent>
              <DataGrid
                rows={driverPaymentStats && driverPaymentStats.slice(0,5) || []}
                columns={driverSettlementColumns}
                autoHeight
                density='compact'
              />
            </CardContent>
            <CardActions style={{justifyContent: 'flex-end'}}>
              <Button size='small' color='secondary'>VIEW MORE</Button>
            </CardActions>            
          </Card>
          <Card style={{ flex: 1, marginLeft: '5px'}}>
            <CardHeader
              title={<Typography variant='subtitle2'>{'Broker Invoice Stats'}</Typography>}
            />
            <Divider />
            <CardContent>
              <DataGrid
                rows={brokerAccountingStats && brokerAccountingStats.slice(0,5) || []}
                columns={brokerColumns}
                autoHeight
                density='compact'
              />
            </CardContent>         
            <CardActions style={{justifyContent: 'flex-end'}}>
              <Button size='small' color='secondary'>VIEW MORE</Button>
            </CardActions>
          </Card>
        </Box>
        <Box
          mt={2}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Card style={{flex: 1, marginRight: '5px'}}>
            <CardHeader
              title={<Typography variant='subtitle2'>{'Top 5 Deliveries'}</Typography>}
            />
            <Divider />
            <CardContent style={{height: '350px'}}>
              <PieChart
                data={topNDestinations && topNDestinations.slice(0, 5) || []}
              />
            </CardContent>
            <CardActions style={{justifyContent: 'flex-end'}}>
              <Button size='small' color='secondary'>VIEW MORE</Button>
            </CardActions>             
          </Card>
          <Card style={{flex: 1, marginRight: '5px'}}>
            <CardHeader
              title={<Typography variant='subtitle2'>{'Top 5 Pickups'}</Typography>}
            />
            <Divider />
            <CardContent style={{height: '350px'}}>
              <PieChart
                data={topNOrigins && topNOrigins.slice(0, 5) || []}
              />
            </CardContent>                    
            <CardActions style={{justifyContent: 'flex-end'}}>
              <Button size='small' color='secondary'>VIEW MORE</Button>
            </CardActions>            
          </Card>
          <Card style={{flex: 1, marginRight: '5px'}}>
            <CardHeader
              title={<Typography variant='subtitle2'>{'Order Type'}</Typography>}
            />
            <Divider />
            <CardContent style={{height: '350px'}}>
              <PieChart
                data={orderTypeCountsData || []}
              />
            </CardContent>                    
            <CardActions style={{justifyContent: 'flex-end'}}>
              <Button size='small' color='secondary'>VIEW MORE</Button>
            </CardActions>            
          </Card>          
        </Box>
        <Box
          mt={2}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Card style={{flex: 1, marginRight: '5px'}}>
            <CardHeader
              title={<Typography variant='subtitle2'>{'Expense & Charges'}</Typography>}
            />
            <Divider />
            <CardContent style={{height: '350px'}}>
              <PieChart
                data={expenseAndChargesData || []}
              />
            </CardContent>                    
            <CardActions style={{justifyContent: 'flex-end'}}>
              <Button size='small' color='secondary'>VIEW MORE</Button>
            </CardActions>            
          </Card>
          <Card style={{flex: 1, marginRight: '5px'}}>
            <CardHeader
              title={<Typography variant='subtitle2'>{'Orders By Date'}</Typography>}
            />
            <CardContent style={{height: '350px'}}>
              <ActivityGrid
                data={orderCountByDate || []}
                from={'2024-09-01'}
                to={'2024-09-30'}
              />
            </CardContent>
          </Card>          
        </Box>
        {/* <Box
          mt={2}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Card style={{flex: 1, marginRight: '5px'}}>
            <CardHeader
              title={<Typography variant='subtitle2'>{'Top 5 Deliveries'}</Typography>}
            />
            <Divider />
            <CardContent>
              <DataGrid
                rows={topNDestinations || []}
                columns={locationColumns}
                autoHeight
                density='compact'
              />
            </CardContent>
            <CardActions style={{justifyContent: 'flex-end'}}>
              <Button size='small' color='secondary'>VIEW MORE</Button>
            </CardActions>            
          </Card>
          <Card style={{ flex: 1, marginLeft: '5px'}}>
            <CardHeader
              title={<Typography variant='subtitle2'>{'Top 5 Pickups'}</Typography>}
            />
            <Divider />        
            <CardContent>
              <DataGrid
                rows={topNOrigins || []}
                columns={locationColumns}
                autoHeight
                density='compact'
              />
            </CardContent>         
            <CardActions style={{justifyContent: 'flex-end'}}>
              <Button size='small' color='secondary'>VIEW MORE</Button>
            </CardActions>
          </Card>
        </Box> */}
      </Box>
    </Page>
  );
});

export default ReportPage;