import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Divider,
  Box,
  Link,
  Zoom,
  Chip,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { formatAppointment, formatCurrency } from 'utils/formatter.js';
import { showOrderNetCalculations } from 'utils/calculations';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import globalStyle from 'styles/GlobalStyle';
import theme from 'theme';

const useStyles = globalStyle;

const getBGColor = (status) => {
  switch (status) {
  case 'RECEIVED':
  case 'RECEIVED_PARTIAL':
    return theme.colors.green;
  case 'UNPAID':
    return theme.colors.red;
  case 'INVOICED':
    return '#2196F3';
  }
};

const getCurrencyColor = (amount) => {
  return amount > 0 ? theme.colors.green : theme.colors.red;
};

const findKey = function(t) {
  if(!t) return '';
  if(!t.dataset.key) {
    return findKey(t.parentElement);
  }
  return t.dataset.key;
};

const IntermodalGridView = ({orders,
  fnViewOrderHandler,
  fnEditOrderHandler,
  fnDeleteOrderHandler,
  fnOrderStatsHandler,
  fnOrderHistoryHandler,
  fnInvoiceHandler,
  fnFileUploadHandler,  
  fnMapHandler,
}) => {
  const classes = useStyles();

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [elevation, setElevation] = React.useState(4);

  const handleClick = (event, order) => {
    setSelectedOrder(order);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedOrder(null);
    setAnchorEl(null);
  };

  return (
    <>
      {orders.map(order => {
        const legCount = order.legs && order.legs.length;
        const drivers = [];
        const containerNumbers = [];

        if (legCount > 1) {
          // Find all drivers for these legs
          order.legs.forEach(leg => {
            if (leg.driver) {
              drivers.push(<Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{leg.id}: {leg.driver.fullName.toUpperCase()} - {formatCurrency(leg.driver.rate)}</Typography>);
            } else {
              drivers.push('');
            }
          });
        }

        let origins = null;
        let destinations = null;
        if (order.legs) {
          origins = order.legs[legCount - 1].origins;
          destinations = order.legs[legCount - 1].destinations;
        }
        let containerNumber = order.container.deliveryContainerNumber;
        if (order.orderType === 'PICKUP') {
          containerNumber = order.container.pickupContainerNumber;
        } else if (order.orderType === 'DROP_PICK' || order.orderType === 'DROP') {
          containerNumbers.push(`Delivery Container #: ${order.container.deliveryContainerNumber}`);
          containerNumbers.push(<br />);
          containerNumbers.push(`Pickup Container #: ${order.container.pickupContainerNumber}`);
        }
        const legs = order.legs;
        const currentLeg = legs[0];
        const driver = currentLeg && currentLeg.driver ? currentLeg.driver.fullName : 'None';
        const orderNet = showOrderNetCalculations(order);
        const orderNetTooltipInformation = [];
        if (order.status === 'COMPLETE') {
          orderNetTooltipInformation.push(<Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>Order Rate: ${orderNet.orderRate}</Typography>);
          orderNetTooltipInformation.push(<Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>FSC: ${orderNet.fsc}</Typography>);
          orderNetTooltipInformation.push(<Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>Expenses (Billable): ${orderNet.billableExpense}</Typography>);
          orderNetTooltipInformation.push(<Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>Expenses (Company): ${orderNet.companyExpense}</Typography>);
          orderNetTooltipInformation.push(<Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>Driver Settlement: ${orderNet.driverPay}</Typography>);
          orderNetTooltipInformation.push(<Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>Charges: ${orderNet.legCharges}</Typography>);
        }
        return (
          <Card data-key={order.id} className={classes.swimlaneBox} variant='elevation' elevation={elevation == order.id ? 12 : 4} key={`key:order:${order.id}`}
            onMouseEnter={(evt)=> {
              evt.preventDefault();
              let id = findKey(evt.target);
              setElevation(id); 
            }}
            onMouseLeave={(evt)=>{ 
              evt.preventDefault();
              setElevation(-1); 
            }}
          >
            <CardHeader
              style={{padding: 8}}
              title={
                <Box display='flex' justifyContent='space-between'>
                  <Box>
                    <Link variant='h6' style={{ fontSize: '13px', fontWeight: 600, fontSmooth: 'auto'}} component='button' underline='hover' onClick={() => fnViewOrderHandler(order)}>
                      {order.displayOrderId}
                    </Link>
                    {order.status === 'COMPLETE' || order.status === 'DELIVERED' &&
                    <Tooltip
                      TransitionComponent={Zoom}
                      placement='bottom'
                      title={order.status === 'COMPLETE' ? orderNetTooltipInformation : ''} arrow>
                      <Typography variant='h6' style={{fontSmooth: 'auto', color: getCurrencyColor(order.orderNet)}} className={classes.formattedData}>
                        {formatCurrency(order.orderNet)}
                      </Typography>
                    </Tooltip>}
                  </Box>                  

                  <Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{order.orderType}</Typography>
                  <Chip style={{ color: getBGColor(order.paymentStatus), borderColor: getBGColor(order.paymentStatus) }} variant='outlined' size='small' label={order.paymentStatus} />          
                </Box>
              }
              action={
                <Box display='flex'>
                  <IconButton aria-label='settings' onClick={(evt) => handleClick(evt, order)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id='long-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem key={`view:${order.id}`} onClick={() => fnViewOrderHandler(selectedOrder)}><VisibilityOutlinedIcon fontSize='small' />&nbsp;&nbsp;View Order</MenuItem>
                    <Divider />
                    <MenuItem key={`edit:${order.id}`} onClick={() => fnEditOrderHandler(selectedOrder)}><EditOutlinedIcon fontSize='small' />&nbsp;&nbsp;Edit Order</MenuItem>
                    <Divider />
                    <MenuItem key={`delete:${order.id}`} onClick={() => fnDeleteOrderHandler(selectedOrder)}><DeleteOutlinedIcon fontSize='small' />&nbsp;&nbsp;Delete Order</MenuItem>                    
                    <Divider />                    
                    <MenuItem key={`files:${order.id}`} onClick={() => fnFileUploadHandler(selectedOrder)}><AttachFileIcon fontSize='small' />&nbsp;&nbsp;Upload Documents</MenuItem>
                    <Divider />
                    <MenuItem key={`stats:${order.id}`} onClick={() => fnOrderStatsHandler(selectedOrder)}><EqualizerOutlinedIcon fontSize='small' />&nbsp;&nbsp;Order Stats</MenuItem>
                    <Divider />
                    <MenuItem key={`notification:${order.id}`} onClick={() => fnOrderHistoryHandler(selectedOrder)}><NotificationsActiveOutlinedIcon fontSize='small' />&nbsp;&nbsp;Order History</MenuItem>
                    <Divider />
                    <MenuItem key={`history:${order.id}`}
                      disabled={!Boolean(['DELIVERED', 'TERMINAL', 'COMPLETE'].filter(st => st === order.status).length)}
                      onClick={() => fnInvoiceHandler(selectedOrder)}><ReceiptOutlinedIcon fontSize='small' />&nbsp;&nbsp;View Invoice</MenuItem>
                    <Divider />
                    <MenuItem key={`map:${order.id}`} onClick={(evt) => fnMapHandler(evt, selectedOrder)}><ExploreOutlinedIcon fontSize='small' />&nbsp;&nbsp;View Map</MenuItem>
                    <Divider />
                  </Menu>
                </Box>
              }
            />
            <CardContent style={{padding: 0, maxHeight: 140,}}>
              <Divider className={classes.swimlaneBoxDivider}/>
              {/* ROW 2 - BROKER INFO */}
              <Grid container style={{ padding: 4 }}>
                <Grid item md={7} sm={7} xs={6}>
                  <Typography variant='body1'>Broker</Typography>
                  <Tooltip title={order.broker.name} arrow>
                    <Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{order.broker.name}</Typography>
                  </Tooltip>
                </Grid>
                <Grid item md={5} sm={5} xs={6}>
                  <Typography>Broker Ref.#</Typography>
                  <Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{order.brokerOrderNumber}</Typography>
                </Grid>
              </Grid>
              {/* ROW 3 - ORIGIN INFO */}
              {origins.map(origin =>
                origin.originalOrigin ?
                  <Grid container key={origin.name} style={{ padding: 4}}>
                    <Grid item xs={7} key={`key:origin:${origin.id}`}>
                      <Typography variant='body1'>Origin</Typography>
                      <Tooltip
                        title={<Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{origin.address.street} {origin.address.city}, {origin.address.state}</Typography>} arrow>
                        <Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{origin.name}</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={5} key={`key:origin2:${origin.id}`}>
                      <Typography variant='body1'>Appointment</Typography>
                      <Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{formatAppointment(origin.appointment)}</Typography>
                    </Grid>
                  </Grid> : null
              )}
              {/* ROW 3 - DESTINATION INFO */}
              {destinations.map(destination =>
                destination.originalDestination ?
                  <Grid container key={destination.name} style={{ padding: 4 }}>
                    <Grid item xs={7} key={`key:destination:${destination.id}`}>
                      <Typography variant='body1'>Destination</Typography>
                      <Tooltip
                        title={<Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{destination.address.street}, {destination.address.city}, {destination.address.state}</Typography>} arrow>
                        <Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{destination.name}</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={5} key={`key:destination2:${destination.id}`}>
                      <Typography variant='body1'>Appointment</Typography>
                      <Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{formatAppointment(destination.appointment)}</Typography>
                    </Grid>
                  </Grid> : null
              )}
              {/* ROW 4 - Container & Driver */}
              <Grid container style={{padding: 4}}>
                <Grid item md={7} sm={8} >
                  <Typography>Driver</Typography>
                  <Tooltip
                    title={driver} arrow>
                    <Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}} className={classes.formattedData}>{driver}</Typography>
                  </Tooltip>
                </Grid>
                {order.orderType === 'DROP_PICK' || order.orderType === 'DROP' ?
                  <Grid item xs={5}>
                    <Typography>Container</Typography>
                    <Tooltip
                      title={containerNumbers} arrow>
                      <Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}} className={classes.net}>{containerNumber}</Typography>
                    </Tooltip>
                  </Grid> :
                  <Grid item xs={5}>
                    <Typography>Container</Typography>
                    <Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{containerNumber}</Typography>
                  </Grid>
                }
              </Grid>
              <Grid container style={{padding: 4}}>
                <Typography style={{textAlign: 'center'}}>Notes: </Typography>
                <Tooltip title={order.notes} aria-label="notes" arrow>
                  <Typography variant='h6' style={{fontWeight: 600, fontSmooth: 'auto'}}>{order.notes}</Typography>
                </Tooltip>
              </Grid>
            </CardContent>         
          </Card>
        );
      }
      )}
    </>
  );
};

IntermodalGridView.propTypes = {
  className: PropTypes.string
};

export default IntermodalGridView;