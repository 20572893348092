import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {   
  Button,
  Box,
  Grid
} from '@material-ui/core';

import InputField from 'components/Input-Field/input-field';
import SelectField from 'components/Select-Field/select-field';
import { USStates, CAProvinces, COUNTRIES } from 'utils/constant.js';

const EquipmentForm = ({ action, equipment, saveFormHandler, updateFormHandler }) => {

  const formik = useFormik({
    initialValues: { ...equipment },

    validationSchema: Yup.object().shape({
      type: Yup.string().required('Equipment Type is required.'),
      name: Yup.string().max(100).required('Equipment Line or Company is required.'),
      street: Yup.string().max(100),
      city: Yup.string().min(2).max(50),
      state: Yup.string().min(2).max(25),
      zipcode: Yup.string().max(10),
      country: Yup.string().max(25),
    }),

    onSubmit: values => {
      const trimmed_data = {};
      Object.keys(values).forEach(key => trimmed_data[key] = typeof(values[key]) === 'string' ? values[key].trim() : values[key]);
      action === 'Save' ? saveFormHandler(trimmed_data) : updateFormHandler(trimmed_data);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <SelectField
              label="Select Type"
              name="type"
              select
              value={formik.values.type}
              variant="outlined"
              fullWidth          
              error={Boolean(formik.touched.type && formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={['CONTAINER', 'CHASSIS']}
            />
          </Grid>          
          <Grid item xs={12} md={4}>
            {formik.values.type === 'CONTAINER' ?            
              <InputField
                label="Container Line"
                name="name"
                value={formik.values.name}
                variant="outlined"
                fullWidth
                error={Boolean(formik.touched.name && formik.errors.name)}              
                helperText={formik.touched.name && formik.errors.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />:
              <InputField
                label="Chassis Company"
                name="name"
                value={formik.values.name}              
                variant="outlined"
                fullWidth
                error={Boolean(formik.touched.name && formik.errors.name)}              
                helperText={formik.touched.name && formik.errors.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />}            
          </Grid>
          <Grid item xs={12} md={4}>
            {formik.values.type === 'CONTAINER' ?
              <InputField
                label="Container Free Days"
                name="freeDays"
                value={formik.values.freeDays}
                type="number"            
                variant="outlined"
                fullWidth
                error={Boolean(formik.touched.freeDays && formik.errors.freeDays)}              
                helperText={formik.touched.freeDays && formik.errors.freeDays}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />:
              <InputField
                label="Chassis Rent"
                name="rent"
                value={formik.values.rent}
                type="number"            
                variant="outlined"
                fullWidth
                error={Boolean(formik.touched.rent && formik.errors.rent)}              
                helperText={formik.touched.rent && formik.errors.rent}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            }
          </Grid>        
          <Grid item xs={12} md={4}>
            <InputField
              label="Street"            
              name="street"
              value={formik.values.street}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.street && formik.errors.street)}            
              helperText={formik.touched.street && formik.errors.street}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />          
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              label="City"            
              name="city"
              value={formik.values.city}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.city && formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label="Select Country"
              name="country"
              select
              value={formik.values.country}
              variant="outlined"
              fullWidth         
              error={Boolean(formik.touched.country && formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={COUNTRIES}
            />
          </Grid>        
          <Grid item xs={12} md={4}>
            <SelectField
              label="Select State/Province"
              name="state"
              select
              value={formik.values.state}
              variant="outlined"
              fullWidth          
              error={Boolean(formik.touched.state && formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={formik.values['country'] === 'USA' ? USStates : CAProvinces}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              label="Zip code or Postal code"   
              name="zipcode"
              value={formik.values.zipcode}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.zipcode && formik.errors.zipcode)}
              helperText={formik.touched.zipcode && formik.errors.zipcode}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />          
          </Grid>
        </Grid>
        <Grid />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}>
          {action === 'Save' ?
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
            >
            Save Equipment
            </Button> :
            <Button 
              type="submit"
              variant="contained"
              color="primary"
            >
            Update Equipment
            </Button>
          }
        </Box>        
      </form>
    </div>
  );
};
export default EquipmentForm;