import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { CloseOutlined } from '@material-ui/icons';
import ToastHandler from 'components/Toast-Handler/toast-handler';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(.5),
    color: theme.palette.secondary,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, isProcessing, toast, classes, onClose } = props;
  return (
    <MuiDialogTitle>
      <Typography variant="h4">{children}</Typography>
      {isProcessing && <CircularProgress style={{margin: '0px 50%'}} />}
      {toast && toast.type ? <ToastHandler toast={toast} type={toast.type} /> : ''}  { /*This needs to be included with all forms */}              
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseOutlined />
      </IconButton>
    </MuiDialogTitle>
  );
});

const ConfirmationDialog = ({
  open,
  title,
  children,
  width,
  isProcessing,
  toast, 
  handleConfirm,
  handleCancel,
  handleCloseFunc,
}) => {
  return (
    <Dialog
      maxWidth={width ? width : 'sm'}
      fullWidth
      open={open}
      onClose={handleCloseFunc}
    >
      <DialogTitle
        toast={toast}
        isProcessing={isProcessing}
        onClose={handleCloseFunc}
      >
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color='primary' variant='contained'>Yes</Button>
        <Button onClick={handleCancel}>No</Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmationDialog;