const initState = {
  companies: [],
  searched: [],
  company: {},
  toast: null,
  isProcessing: false,
  isLoaded: false,
};

export const companyReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_COMPANY_STATE':
    return { 
      ...initState
    };
  case 'RESET_TOAST_STATE':
    return {
      ...state,
      toast: null, 
      isProcessing: false,
    };

  case 'PROCESSING_COMPANY':
    return {
      ...state,
      toast: { type: 'LOADING', message: 'Please wait ...'},
      isProcessing: true,
    };

  case 'FETCH_COMPANIES':
    return {
      ...state,
      companies: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,
      isLoaded: true, // This is to make sure we avoid additional fetch calls.
    };
  case 'ADD_COMPANY':
    return {
      ...state,      
      companies: [...state.companies, action.payload],
      toast: { type: 'SUCCESS', message: 'Company saved successfully.'},
      isProcessing: false,
    };    
  case 'UPDATE_COMPANY':
    return {
      ...state,      
      companies: state.companies.map(company => company.id === action.payload.id ? {...action.payload } : { ...company }), //this works for update, but new item does not show up.
      // companies: [...state.companies.filter(item => item.id !== action.payload.id), Object.assign({}, action.payload)], #filter send the item to end of list when updated.
      searched: state.searched.map(company => company.id === action.payload.id ? {...action.payload } : { ...company }),
      toast: { type: 'SUCCESS', message: 'Company updated successfully.' },
      isProcessing: false,
    };
  case 'SEARCH_COMPANIES':
    return {
      ...state,
      searched: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,      
    };      
  case 'COMPANY_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};
