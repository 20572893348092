import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import Page from 'components/Page';
import InvoiceTable from './invoice-table';
import CTTabs from 'components/CTTabs';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import * as OrderAction from 'actions/order-action';
import * as InvoiceAction from 'actions/invoice-action';

import { AggregateOrderExpenses } from 'utils/calculations';
import { convertArrayOfObjectsToCSV } from 'utils/csvdownload.js';
import { formatCurrency } from 'utils/formatter.js';
import { filterAccountingDataByTab } from 'utils/filter';
import { saveOrderExpense, deleteOrderExpense, updateOrderExpense, getExpensesByOrder } from 'services/expense-service.js';
import { search, viewInvoiceReport } from 'services/invoice-service.js';
import { getCheckInformation } from 'services/check-service.js';
import CheckPayment from './check-payment';

import OrderExpense from '../expenses/order-expense';
import InfoIcon from '@material-ui/icons/Info';
import CTDialog from 'components/CTDialog';
import CheckTable from './check-table';
import Topbar from 'containers/global/Topbar';
import Searchbar from 'containers/global/Searchbar';
import Notificationbar from 'containers/global/Notificationbar';
import DataDialog from 'components/dialogs/DataDialog';
import OrderDetail from 'containers/Dispatch-Order/order-detail';
import { CloseOutlined } from '@material-ui/icons';
import theme from 'theme';
import CustomSelectField from 'components/Select-Field/custom-select';

const TABS = ['UNPAID', 'INVOICED', 'RECEIVED_PARTIAL', 'RECEIVED'];

const SEARCH_FIELDS = [
  { 'name': 'broker', 'label': 'broker', 'type': 'datalist' },
  { 'name': 'orderId', 'label': 'Order Id', 'type': 'text' },
  { 'name': 'brokerReferenceNumber', 'label': 'Reference Number', 'type': 'text' },
  { 'name': 'containerNumber', 'label': 'Container Num.', 'type': 'text' },
  { 'name': 'checkNumber', 'label': 'Check Num.', 'type': 'text' },
];
const TABLE_HEADER = [
  { id: 'chkbox', sortable: true, label: '', show: true },
  { id: 'orderId', sortable: true, label: 'OrderID', show: true },
  { id: 'broker', sortable: true, label: 'Broker', show: true },
  { id: 'broker_reference_number', sortable: true, label: 'Broker Ref', show: true },
  { id: 'container', sortable: true, label: 'Container', show: true },
  { id: 'driver', sortable: true, label: 'Driver', show: true },
  { id: 'origin', sortable: true, label: 'Origin', show: true },
  { id: 'destination', sortable: true, label: 'Destination', show: true },
  { id: 'total', sortable: true, label: 'Total', show: true },
  { id: 'custom_payment', sortable: true, label: 'Received', show: true },
  { id: 'complete_date', sortable: true, label: 'Order Completed Date', show: true },
  { id: 'generate_date', sortable: true, label: 'Generate Date', show: true },
  { id: 'payment_received_date', sortable: true, label: 'Payment Received Date', show: true },
  { id: 'action', sortable: true, label: '', show: false },
];

class InvoiceManager extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      brokers: [],
      invoices: [],
      activeOrder: null,
      checks: [],
      checkModel: {
        amount: 0,
        checkNumber: 0,
        checkDate: '',
        transactionType: 'INVOICE'
      },
      expenses: [],
      expenseFormAction: 'Save',
      expense: {
        id: '',
        name: '',
        billableAmount: '',
        companyShare: '',
        driverShare: '',
        isPerDay: 'NO',
        expenseType: 'BILLABLE',
      },
      tabIndex: 0,
      paymentStatus: 'UNPAID',
      selectedBroker: '',
      selectedBrokerId: '',
      customPaymentAmount: [],

      isExpenseDialogOpen: false,
      isOrderDetailDialogOpen: false,
      isCheckPaymentDialogOpen: false,
      isViewCheckDialogOpen: false,
      isAlertDialogOpen: false,
      isAdvanceSearchDialogOpen: false,

      selectedPaymentFilter: [true, true, true, true, true],
      selectedPaymentFilterStatus: ['UNPAID', 'INVOICED', 'RECEIVED_PARTIAL', 'RECEIVED'],
      selectedInvoices: [],
      /* Search */
      isSearchEnabled: false,
      searchParameters: {},
      searchResults: []
    };
  }

  componentDidMount() {
    this.props.resetState('RESET_INVOICE_STATE');
    const { paymentStatus } = this.state;
    this.loadInvoicesByStatus(paymentStatus);
  }

  changeTab = (tabIndex) => {
    const paymentStatus = TABS[tabIndex];
    // If search enabled then load searched data, otherwise load invoices for selected Payment Status
    this.setState({ tabIndex, paymentStatus },
      !this.state.isSearchEnabled ? this.loadInvoicesByStatus(paymentStatus) : null
    );
  };

  closeInfoDialog = () => {
    this.props.resetState('RESET_TOAST_STATE');
    this.setState({
      isAlertDialogOpen: false,
      alertTitle: '',
      alertMessage: ''
    });
  };

  /* Order Expenses */
  toggleExpenseDialog = orderId => {
    if (this.state.isExpenseDialogOpen) {
      this.loadInvoicesByStatus(this.state.paymentStatus);
      this.setState({ isExpenseDialogOpen: false });
    } else {
      getExpensesByOrder(orderId).then(res => {
        this.setState({
          expenses: res.data,
          isExpenseDialogOpen: !this.state.isExpenseDialogOpen,
          orderId
        });
      });
    }
  };

  saveOrderExpense = (expense) => {
    saveOrderExpense(this.state.orderId, expense).then(() => {
      this.loadInvoicesByStatus(this.state.paymentStatus);
      this.setState({ isExpenseDialogOpen: !this.state.isExpenseDialogOpen });
    });
  };

  editOrderExpense = (expense) => {
    this.setState({ expense: expense, expenseFormAction: 'Update' });
  };

  updateOrderExpense = (expense) => {
    updateOrderExpense(this.state.orderId, expense).then(() => {
      this.loadInvoicesByStatus(this.state.paymentStatus);
      this.setState({
        isExpenseDialogOpen: !this.state.isExpenseDialogOpen,
        expenseFormAction: 'Save'
      });
    });
  };

  deleteOrderExpense = (expense) => {
    deleteOrderExpense(expense.id, this.state.orderId).then(() => {
      this.loadInvoicesByStatus(this.state.paymentStatus);
      this.setState({ isExpenseDialogOpen: !this.state.isExpenseDialogOpen });
    });
  };

  loadInvoicesByStatus = (status) => {
    const data = [];
    const params = {
      searchParam: data
    };
    this.props.findInvoices(status, params);
  };

  toggleRowSelection = (invoices) => {
    const customPaymentAmount = {};
    invoices.forEach(invoice => {
      const invoiceId = invoice.id;
      const balance = invoice.invoiceTotal - invoice.paymentReceived;
      customPaymentAmount[invoiceId] = balance;
    });
    this.setState({
      selectedInvoices: invoices,
      customPaymentAmount
    });
  };

  handlePaymentChange = (evt, invoiceId) => {
    const tempOP = this.state.customPaymentAmount;
    tempOP[invoiceId] = evt.target.value;
    this.setState({ customPaymentAmount: tempOP });
  };

  searchInvoices(searchParameters) {
    this.searchParameters = searchParameters;
    const params = {
      searchParam: Object.entries(searchParameters).map(([k, v]) => ({ 'label': k, 'value': v }))
    };
    this.props.search(params);
  }

  /* Search Invoices */
  /* 
    1. User click on search icon to call executeSearch function.
    2. If status is 200, we will display the search results.
    3. A Chip with onDelete property will be created based on {key:value} in the search Parameters
  */
  toggleAdvanceSearchDialog = () => {
    this.setState({
      isAdvanceSearchDialogOpen: !this.state.isAdvanceSearchDialogOpen
    });
  };

  handleSearchFieldChange = (evt, fieldName) => {    
    const name = fieldName;
    const value = fieldName === 'broker' ? evt.value : evt.target.value;
    
    const param = {};
    param[`${name}`] = value.trim();    
    this.setState({searchParameters: Object.assign(this.state.searchParameters, param)});
  };

  /* Execute Search and also save state of the search variables */
  executeSearch = (searchParameters) => {
    if (searchParameters && Object.entries(searchParameters).length > 0) {
      const params = {
        searchParam: Object.entries(searchParameters).map(([k, v]) => ({ 'label': k, 'value': v }))
      };
      search(params).then(res => { // This search query is manual in the backend. make sure to update the db there when testing in local or staging.
        if (res && res.status === 200) {
          this.setState({
            searchResults: res.data,            
            searchParameters: searchParameters,
            isSearchEnabled: true,
            isAdvanceSearchDialogOpen: false,
          });
        }
      });
    } else {
      this.setState({
        searchResults: [],
        isSearchEnabled: false,
        searchParameters: searchParameters,
        isAdvanceSearchDialogOpen: false,
      });
    }
  };

  /* Create Search Chip */
  createSearchChip = () => {
    const kvpair = Object.entries(this.state.searchParameters).map(([k, v]) => ({ 'key': k, 'value': v }));
    const chips = kvpair.map(item => {
      return <Chip key={item.key}
        label={item.value}
        onDelete={(evt) => this.deleteSearchChip(evt, item.key)}
        color='secondary'
        size='small'
        style={{marginRight: 5}}
      />;
    });
    return chips;
  };
  /* Delete Search Chip and execute Search */
  deleteSearchChip = (evt, key) => {
    const sp = this.state.searchParameters;
    delete sp[key];
    // Execute search on remaining search items if any.
    this.executeSearch(sp);
  };

  /* 
  Clear Search Fields - No need to perform search, because user has been given Search Button.
  */
  clearSearchField = (evt, fieldName) => {
    const updatedSP = delete this.state.searchParameters[fieldName]; 
    // We need to clear this filtered searchOption from the searchParameters and input box.
    Array.from(document.querySelectorAll('input')).forEach(input => {      
      if(input.name === fieldName) //true
        input.value = null;
    });
    this.setState({ searchParameters: updatedSP });
  };

  toggleCheckPaymentDialog = () => {
    if (this.state.isCheckPaymentDialogOpen) {
      this.setState({
        isCheckPaymentDialogOpen: false,
        selectedInvoices: [],
        customPaymentAmount: []
      });
    } else {
      const { selectedInvoices, customPaymentAmount } = this.state;
      let isPaymentAmountInvalid = false;
      let isPaymentAmountNegative = false;
      if (selectedInvoices.length === 0) {
        this.setState({
          isAlertDialogOpen: true,
          alertTitle: <InfoIcon color='primary' value='View Report'>Process Payment</InfoIcon>,
          alertMessage: <Typography variant='h6'>No invoice selected. Please select at least one invoice to download the report.</Typography>
        });
      } else {
        selectedInvoices.forEach(inv => {
          if (customPaymentAmount[inv.id] > inv.balance) {
            isPaymentAmountInvalid = true;
          } else if (customPaymentAmount[inv.id] < 0) {
            isPaymentAmountNegative = true;
          }
          if (isPaymentAmountInvalid || isPaymentAmountNegative) {
            const message = isPaymentAmountInvalid ? <Typography variant='h6'>Payment amount for invoice  ${inv.id} more than balance amount. Cannot process the payment.</Typography> : <Typography variant='h6'>Payment amount for invoice  ${inv.id} is negative. Cannot process the payment.</Typography>;
            this.setState({
              isAlertDialogOpen: true,
              alertTitle: <InfoIcon color='primary'>Process Payment</InfoIcon>,
              alertMessage: message
            });
          }
        });
        if (!isPaymentAmountInvalid && !isPaymentAmountNegative) {
          //Process Payment
          const totalAmount = Object.values(customPaymentAmount).reduce(function (total, payment) {
            return Number(total) + Number(payment);
          }, 0);

          const checkModel = this.state.checkModel;
          checkModel.amount = totalAmount;
          checkModel.checkDate = new Date().toISOString().split('T')[0];
          this.setState({
            isCheckPaymentDialogOpen: !this.state.isCheckPaymentDialogOpen,
            checkModel,
          });
        }
      }
    }
  };

  processPayment = (checkModel) => {
    const invoices = []; //invoices to process
    this.state.selectedInvoices.forEach(inv => {
      const invoiceItem = {
        'invoiceId': inv.id,
        'orderId': inv.orderId,
        'invoiceAmount': this.state.customPaymentAmount[inv.id]
      };
      invoices.push(invoiceItem);
    });

    const paymentData = {
      checkModel,
      invoices
    };
    this.props.processPayment(paymentData);
  };

  viewReport = () => {
    const selectedInvoices = this.state.selectedInvoices;
    if (selectedInvoices.length === 0) {
      this.setState({
        isAlertDialogOpen: true,
        alertTitle: <InfoIcon color='primary' value='View Report'>View Report</InfoIcon>,
        alertMessage: <Typography variant='h6'>No invoice selected. Please select at least one invoice to download the report.</Typography>
      });
    } else {
      const invoiceIdList = selectedInvoices.map(inv => inv.id);
      const date = new Date(new Date().toDateString()).toISOString();
      viewInvoiceReport(invoiceIdList, date);
    }
  };

  generateInvoice = () => {
    const selectedInvoices = this.state.selectedInvoices;
    if (selectedInvoices.length === 0) {
      this.setState({
        isAlertDialogOpen: true,
        alertTitle: <InfoIcon color='primary'>Generate Invoice</InfoIcon>,
        alertMessage: <Typography variant='h6'>No invoice selected. Please select at least one invoice to view the report.</Typography>
      });
    } else {
      const invoiceIdList = selectedInvoices.map(inv => inv.id);
      const date = new Date(new Date().toDateString()).toISOString();
      this.props.generateInvoice(invoiceIdList, date);
      viewInvoiceReport(invoiceIdList, date);
      this.setState({ selectedInvoices: [], customPaymentAmount: [] });
    }
  };

  processUnInvoice = () => {
    const selectedInvoices = this.state.selectedInvoices;
    if (selectedInvoices.length === 0) {
      this.setState({
        isAlertDialogOpen: true,
        alertTitle: <InfoIcon color='primary'>Process unInvoice </InfoIcon>,
        alertMessage: <Typography variant='h6'>No invoice selected. Please select at least one invoice to revert the invoice.</Typography>
      });
    } else {
      const invoiceIdList = selectedInvoices.map(inv => inv.id);
      this.props.unInvoice(invoiceIdList);
      this.setState({ selectedInvoices: [], customPaymentAmount: [] });
    }
  };

  downloadCSVData = (args) => {
    var data, filename, link;
    const { invoices } = this.props;
    if (invoices && invoices.data) {
      const formattedData = invoices.data.map(o => {
        const leg = o.legs && o.legs.length > 0 ? o.legs[0] : null;
        const csv = {};
        csv.invoiceId = o.id;
        csv.orderId = o.displayOrderId;
        csv.invoiceType = o.invoiceType;
        csv.broker = o.broker.name;
        csv.brokerinvoiceNumber = o.brokerinvoiceNumber;
        if (leg) {
          csv.terminal = leg.terminals && leg.terminals.length > 0 ? leg.terminals[0].name : '';
          csv.consignee = leg.consignees && leg.consignees.length > 0 ? leg.consignees[0].name : '';
        }
        csv.chassisComppany = o.chassis.company || 'N.A.';
        csv.chassisNumber = o.chassis.chassisNumber;
        csv.containerLine = o.container.line || 'N.A.';
        csv.containerNumber = o.container.deliveryContainerNumber;
        csv.invoiceRate = o.invoiceRate;
        csv.fuelSurcharges = `${o.fuelCharges} ${o.fuelChargesType}`;
        if (o.expenses && o.expenses.length > 0) {
          o.expenses.forEach((exp, idx) => {
            csv[`Expense-${idx + 1}`] = exp.name;
            csv[`Expense-${idx + 1} Billable`] = exp.billableAmount;
            csv[`Expense-${idx + 1} Company`] = exp.companyShare;
          });
        }
        csv.status = o.status;
        csv.completeDate = o.invoiceCompleteDate;
        csv.paymentStatus = o.paymentStatus;
        csv.InvoiceDate = o.invoiceGenerationDate;
        return csv;
      });
      let csvFile = convertArrayOfObjectsToCSV({
        data: formattedData
      });
      if (csvFile === null) return;

      filename = args.filename || 'export.csv';

      if (!csvFile.match(/^data:text\/csv/i)) {
        csvFile = 'data:text/csv;charset=utf-8,' + csvFile;
      }
      data = encodeURI(csvFile);

      link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', filename);
      link.click();
    }
  };

  toggleOrderDetailDialog = orderId => {
    if (this.state.isOrderDetailDialogOpen) {
      this.setState({ isOrderDetailDialogOpen: false });
    } else {
      this.props.getOrderById(orderId);
      this.setState({ isOrderDetailDialogOpen: true });
    }
  };

  toggleViewCheckInformation = invoiceId => {
    if (this.state.isViewCheckDialogOpen) {
      this.setState({ isViewCheckDialogOpen: false });
    } else {
      getCheckInformation('invoice', invoiceId).then(res => {
        this.setState({
          checks: res.data,
          isViewCheckDialogOpen: true,
        });
      });
    }
  };

  getBrokerLabel = (broker) => {
    const primaryText = broker.name;
    const secondaryText = broker.address && `${broker.address.street},${broker.address.city},${broker.address.state},${broker.address.country}`;
    const reactSelectObj = {
      label:<Box>
        <Typography variant='h6'>{primaryText}</Typography>
        <Typography variant='subtitle2' style={{fontSize: '11px'}}>{secondaryText}</Typography>
      </Box>,
      value: primaryText
    };
    return reactSelectObj;
  };

  render() {
    const { brokers, invoices, searched, activeOrder, toast, isProcessing } = this.props;

    const { checkModel } = this.state;
    const totalAmount = checkModel.amount;
    const statusCounts = [];
    const results = this.state.searchResults;
    const mergedData = Object.keys(results).reduce(function (res, key) {
      return res.concat(results[key]);
    }, []);

    // Invoices or Searched invoices to display
    const tableData = this.state.isSearchEnabled ? mergedData : invoices;

    statusCounts.push(
      {
        'status': 'UNPAID',
        'count': tableData.filter(d => d.paymentStatus === 'UNPAID').length,
      },
      {
        'status': 'RECEIVED_PARTIAL',
        'count': tableData.filter(d => d.paymentStatus === 'RECEIVED_PARTIAL').length,
      },
      {
        'status': 'INVOICED',
        'count': tableData.filter(d => d.paymentStatus === 'INVOICED').length,
      },
      {
        'status': 'RECEIVED',
        'count': tableData.filter(d => d.paymentStatus === 'RECEIVED').length,
      }
    );
    const invoicesByStatus = filterAccountingDataByTab(tableData, TABS[this.state.tabIndex]);
    let content = null;
    if (toast && toast.action && toast.message) {
      content = toast.message.map((msg, idx) =>
        <React.Fragment key={idx}>
          <Typography variant='h6'>{msg.includes('SUCCESS') ?
            <CheckCircleOutlineOutlinedIcon style={{ color: 'green', marginBottom: '-8px' }} /> :
            <CancelOutlinedIcon style={{ color: 'red', marginBottom: '-8px' }} />}&nbsp;&nbsp;{msg}
          </Typography>
          <br />
        </React.Fragment>
      );
    }
    return (
      <Page title='Invoice'>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Topbar
            title='INVOICE'
            subTitle='Manage Your Accounting'
          />
          <Searchbar
            searchType='ADVANCED'
            isAdvanceSearchDialogOpen={this.state.isAdvanceSearchDialogOpen}
            searchParameters={this.state.searchParameters}
            fnSearchMenuHandler={this.toggleAdvanceSearchDialog}
          >
            <Grid container spacing={1}>
              {SEARCH_FIELDS.map(field => {
                return (
                  field.type === 'datalist' ?
                    <Grid item xs={12} md={4} key={field.name}>
                      <CustomSelectField
                        type='BROKER'
                        id='brokers'
                        label='Broker Name'
                        name={field.name}
                        data={brokers.map(b => this.getBrokerLabel(b) )}
                        onChange={selected => this.handleSearchFieldChange(selected, field.name)}
                      />
                    </Grid> :
                    <Grid item xs={12} md={4} key={field.name}>
                      <TextField
                        key={field.name}
                        label={field.label}
                        margin='dense'
                        variant='outlined'
                        name={field.name}
                        type={field.type}
                        fullWidth={true}
                        onChange={evt => this.handleSearchFieldChange(evt, field.name)}
                        InputProps={{
                          endAdornment: (<IconButton name={field.name} onClick={(evt) => this.clearSearchField(evt, field.name)}>
                            <CloseOutlined fontSize='small' />
                          </IconButton>                          
                          )
                        }}
                      />
                    </Grid>
                );
              })
              }
            </Grid>
            <Box
              display='flex'
              justifyContent='flex-end'
              p={2}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={() => this.executeSearch(this.state.searchParameters)}
              >
                Search
              </Button>
            </Box>
          </Searchbar>
          <Notificationbar />
        </Box>
        <Box
          m='20px 0 0 0'
          height='75vh'
        >
          <Box style={{ margin: '10px 0' }}>            
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box flex={1}>
                {/* <Button
                  variant='contained'
                  color='primary'
                  onClick={this.toggleFormDialog}
                >
                  {'New Invoice'}
                </Button> */}
              </Box>
              {this.state.isSearchEnabled &&
                <Box flex={1}>
                  <Typography variant='h5' 
                    style={{ 
                      letterSpacing: 1,
                      color: theme.colors.grey,
                      textTransform: 'uppercase',
                      marginBottom: '2px',
                    }}
                  >
                    {`${tableData.length} records found`}
                  </Typography>
                  {this.createSearchChip()}
                </Box>
              }             
            </Box>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CTTabs
              labels={TABS}
              counts={statusCounts}
              tabIndex={this.state.tabIndex}
              fnOnTabChangeHandler={this.changeTab}
            />
            <ButtonGroup
              variant='outlined' 
              color='secondary' 
              size='small' 
              aria-label='outlined primary button group'
              style={{marginBottom: '5px'}}
            >
              <Button onClick={() => this.viewReport()}>Preview</Button>
              <Button onClick={() => this.generateInvoice()}>Generate</Button>
              <Button onClick={() => this.toggleCheckPaymentDialog()}>Receive</Button>
              <Button onClick={() => this.processUnInvoice()}>Revert</Button>
            </ButtonGroup>
          </Box>
          {toast ? <ToastHandler toast={toast} type={toast.type} /> : null}
          {isProcessing ? <CircularProgress style={{ position: 'absolute', top: '50%', marginLeft: '50%' }} /> :
            <InvoiceTable
              tableHeader={TABLE_HEADER}
              tableData={invoicesByStatus}
              checkBoxSelection={true}
              actionButtonSelection={true}
              customPaymentAmount={this.state.customPaymentAmount}
              fnOnHandlePaymentChange={this.handlePaymentChange}
              fnOnDeleteRowHandler={this.deleteInvoice}
              fnOnViewOrderDialogHandler={orderId => this.toggleOrderDetailDialog(orderId)}
              fnOnExpenseDialogHandler={invoice => this.toggleExpenseDialog(invoice.orderId)}
              fnOnViewCheckDialogHandler={invoice => this.toggleViewCheckInformation(invoice.id)}
              fnOnToggleRowSelectionHandler={data => this.toggleRowSelection(data)}
            />
          }
        </Box>
        <React.Fragment>
          <DataDialog
            open={this.state.isExpenseDialogOpen}
            title={'Expense'}
            width={'md'}
            isProcessing={isProcessing}
            toast={toast}            
            onCloseHandler={this.toggleExpenseDialog}
          >
            <OrderExpense
              action={this.state.expenseFormAction}
              expense={this.state.expense}
              expenseList={this.state.expenses || []}
              fnOnSaveHandler={this.saveOrderExpense}
              fnOnEditHandler={this.editOrderExpense}
              fnOnUpdateHandler={this.updateOrderExpense}
              fnOnDeleteHandler={this.deleteOrderExpense}
            />
          </DataDialog>
          {/* ORDER DETAIL DIALOG */}
          {activeOrder && activeOrder.legs ?
            <DataDialog
              open={this.state.isOrderDetailDialogOpen}
              title='Order Detail'
              width={'lg'}
              onCloseHandler={this.toggleOrderDetailDialog}
            >
              <OrderDetail
                order={activeOrder}
              />
            </DataDialog>
            : null
          }
          <CheckPayment
            open={this.state.isCheckPaymentDialogOpen}
            title='RECEIVE PAYMENT'
            description={`Receive Payment for ${formatCurrency(totalAmount)}`}
            isProcessing={isProcessing}
            toast={toast}
            checkModel={checkModel}
            fnOnProcesPaymentHandler={this.processPayment}
            fnOnCloseDialogHandler={this.toggleCheckPaymentDialog}
          />
          <CheckTable
            open={this.state.isViewCheckDialogOpen}
            tableData={this.state.checks}
            fnOnCloseDialogHandler={this.toggleViewCheckInformation}
          />
          <CTDialog
            open={content !== null || this.state.isAlertDialogOpen}
            title={this.state.alertTitle}
            content={content || this.state.alertMessage}
            action={<Button color='primary' onClick={this.closeInfoDialog}>OK</Button>}
          />
        </React.Fragment>;
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, OrderAction, InvoiceAction), dispatch);
}

function mapStateToProps(state) {
  const { brokers } = state.brokerReducer;
  const { order } = state.orderReducer;
  let { invoices, invoice, searched, toast, isProcessing, document } = state.invoiceReducer;
  if (order && order.id) {
    const fsc = order.fuelChargesType === '$' ? order.fuelCharges : .01 * order.fuelCharges * order.orderRate;
    const orderTotal = order.orderRate + fsc + AggregateOrderExpenses(order.expenses);
    invoices = invoices.map(inv => inv.orderId === order.id ? { ...inv, expenses: order.expenses, invoiceTotal: orderTotal } : { ...inv });
  }
  return {
    activeOrder: order,
    brokers,
    invoice,
    invoices,
    searched,
    toast,
    isProcessing,
    document,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceManager);