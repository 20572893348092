import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';

/* Google Tag Manager */
const tagManagerArgs = {
  gtmId: 'G-Q17YCJ64DF'
};
TagManager.initialize(tagManagerArgs);

const appContainer = document.getElementById('root');
const root = createRoot(appContainer);
root.render(<BrowserRouter><App /></BrowserRouter>);

registerServiceWorker();
