import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  status: {
    order: {
      open: '#1565c0',
      progress: '#7b1fa2',
      yard: '#E3D026',
      yard_pull: '#FF3838',
      yard_in: '#FF3838',
      delivered: '#4caf50',
      complete: '#1b5e20',
    },
    leg: {
      new: '#2196f3',
      covered: '#2DCCFF',
      yard_pull: '#FF3838',
      yard_in: '#FF3838',
      transit: '#3f50b5',
      delivered: '#00E200',
      terminal: '#00E200',
      complete: '#00E200',
      container_in: '#56F000',
      default: '#f0f0f0',
    },
    destination: {
      new: '#2196f3',
      transit: '#64D9FF',
      check_in: '#3f50b5',
      detention_start: '#FFB302',
      delivered: '#00E200',
      done: '#00E200',
      default: '#f0f0f0',
    }
  },
  colors: {
    title: '#141414',
    subtitle: '#e33371',
    searchbox: '#f0f0f0',
    grey: '#a3a3a3', // Grey[700]
    bar: '#e33371',
    red: '#FF3838',
    green: '#00E200',
  },
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#0D46A1' // Blue[500]
    },
    secondary: {
      light: '#e33371',
      main: '#e33371',
      dark: '#9a0036',
    },
    error: {
      main: '#e57373'
    },
    warning: {
      main: '#ffb74d'
    },
    info: {
      main: '#64b5f6'
    },
    success: {
      main: '#81c784'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  overrides: {
    MuiDataGrid: {
      root: {        
        '&cell': {
          borderBottom: 'none',
        },
        columnHeader: {
          fontWeight: '900',
        }, 
        columnHeaderWrapper: {
          backgroundColor: '#f0f0f0',
        },          
        columnHeaderTitle: {
          fontWeight: '900 !important',
          borderBottom: 'none',
        },
        columnsContainer: {
          background: '#f0f0f0'
        }
      },
      columnsContainer: {
        background: '#f0f0f0'
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          color: 'primary',
          backgroundColor: '#e33371'
        }
      }
    },
    MuiMenu: {
      paper: {
        minWidth: '30ch',
        marginTop: '30px',
      }
    }
  },
  shadows,
  typography
});

export default theme;