import React from 'react';
import PropTypes from 'prop-types';
import { 
  TextField
} from '@material-ui/core';

const InputField = props => {
  const { label, name, type, value, fullWidth, error, helperText, onBlur, onChange, disabled, hidden, margin, className } = props;
  return (
    <TextField classes
      label={label}
      name={name}
      type={type}
      value={value}
      variant={'outlined'}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
      hidden={hidden}
      autoComplete='off'
      margin= {margin ? margin : 'none'}
      className={className}
      style={{
        marginTop: '0px'
      }}
    />
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'password', 'date']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),  
  onChange: PropTypes.func,
};

export default InputField;