const initState = {
  equipments: [],
  searched: [],
  equipment: {},
  toast: null,
  isProcessing: false,
  isLoaded: false,
};

export const equipmentReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_EQUIPMENT_STATE':
    return { 
      ...initState
    };
  case 'RESET_TOAST_STATE':
    return {
      ...state,
      toast: null, 
      isProcessing: false,
    };
  case 'PROCESSING_EQUIPMENT':
    return {
      ...state,
      toast: { type: 'LOADING', message: 'Please wait ...'},
      isProcessing: true,
    };

  case 'FETCH_EQUIPMENTS':    
    return {
      ...state,
      equipments: action.payload,      
      toast: {type: 'SUCCESS'},
      isProcessing: false,
      isLoaded: true, // This is to make sure we avoid additional fetch calls.
    };
  case 'ADD_EQUIPMENT':
    return {
      ...state,      
      equipments: [...state.equipments, action.payload], 
      toast: { type: 'SUCCESS', message: 'Equipment saved successfully.'},
      isProcessing: false,
    };    
  case 'UPDATE_EQUIPMENT':
    return {
      ...state,      
      equipments: state.equipments.map(equipment => equipment.id === action.payload.id ? {...action.payload } : { ...equipment }),
      searched: state.searched.map(equipment => equipment.id === action.payload.id ? {...action.payload } : { ...equipment }),
      toast: { type: 'SUCCESS', message: 'Equipment updated successfully.' },
      isProcessing: false,
    };
  case 'SEARCH_EQUIPMENTS':
    return {
      ...state,
      searched: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,      
    };    
  case 'EQUIPMENT_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};
