const initState = {
  account: {},
  toast:null,
  isProcessing: false,
};

export const demoRequestReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_DEMO_REQUEST_STATE':
    return {
      ...state,
      toast: {},
      isProcessing: false,
    };
  case 'PROCESSING_DEMO_REQUEST':
    return {
      ...state,
      isProcessing: true,
      toast: { type: 'LOADING' }
    };
  case 'DEMO_REQUEST_SUCCESS':
    return {
      isProcessing: false,
      account: action.payload,
      toast: { type: 'SUCCESS', message: 'Thank you for contacting us. We have received your request for a demo and someone from our team will reach out.' }
    };
  case 'DEMO_REQUEST_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload }
    };
  default:
    return state;
  }
};
