import { useState, React } from 'react';
import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Link,
  Chip,
  ListItem,
  List,
  styled
} from '@material-ui/core';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { formatAppointment } from 'utils/formatter';
import theme from 'theme';
const { status } = theme;


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-toolbarContainer': {
    backgroundColor: '#f0f0f0'
  }
}));

const IntermodalTableView = (props) => {
  const {
    orders,
    fnViewOrderHandler,
    fnEditOrderHandler,
    fnDeleteOrderHandler,
    fnOrderStatsHandler,
    fnOrderHistoryHandler,
    fnInvoiceHandler,
    fnFileUploadHandler,
    fnMapHandler,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const toggleActionMenu = (evt, order) => {
    setSelectedOrder(order);
    const target = evt ? evt.currentTarget : null;
    setAnchorEl(target);
  };

  const getInvoiceStatusColor = (status) => {
    switch (status) {
    case 'RECEIVED':
    case 'RECEIVED_PARTIAL':
      return 'green';
    case 'UNPAID':
      return 'red';
    case 'INVOICED':
      return '#2196F3';
    }
  };

  const getOrderStatusColor = (value) => {
    return status.order[value.toLowerCase()];
  };

  const columns = [
    {
      flex: 0.5,
      field: 'displayOrderId', headerName: 'Id', renderCell: (params) => {
        return <Link variant='h6' component='button' color='primary' onClick={() => fnViewOrderHandler(params.row)}>
          {params.row.displayOrderId}
        </Link>
      }
    },
    { field: 'orderType', headerName: 'Type', flex: 1},
    {
      field: 'broker',
      headerName: 'Broker',
      flex: 1,
      valueGetter: (params) => params.row.broker.name,
    },
    { field: 'brokerOrderNumber', headerName: 'Broker W/O', flex: 1},
    {
      field: 'origin',
      headerName: 'Origin',
      flex: 1,
      renderCell: ({ row: { legs } }) => {
        const origins = legs && legs.length > 0 ? legs[0].origins : [];
        return <List>{origins.map((loc) => <ListItem key={loc.name}><Typography>{loc.name}</Typography></ListItem>)}</List> 
      },
    },
    {
      field: 'origin_appointment',
      headerName: 'Appointment',
      flex: 1,
      renderCell: ({ row: { legs } }) => {
        const origins = legs && legs.length > 0 ? legs[0].origins : [];
        return <List>{origins.map((loc) => <ListItem key={loc.name}><Typography>{formatAppointment(loc.appointment)}</Typography></ListItem>)}</List> 
      },
    },
    {
      field: 'destination',
      flex: 1,
      headerName: 'Destination',
      renderCell: ({ row: { legs } }) => {
        const destinations = legs && legs.length > 0 ? legs[0].destinations : [];
        return <List>{destinations.map((loc) => <ListItem key={loc.name}><Typography>{loc.name}</Typography></ListItem>)}</List> 
      },
    },
    {
      field: 'dest_appointment',
      headerName: 'Appointment',
      flex: 1,
      renderCell: ({ row: { legs } }) => {
        const destinations = legs && legs.length > 0 ? legs[0].destinations : [];
        return <List>{destinations.map((loc) => <ListItem key={loc.name}><Typography>{formatAppointment(loc.appointment)}</Typography></ListItem>)}</List> 
      },
    },
    {
      field: 'container',
      headerName: 'Container Num.',
      flex: 1,
      valueGetter: (params) =>
        params.row.container && params.row.container.deliveryContainerNumber,
    },
    // {
    //   field: 'paymentStatus',
    //   headerName: 'Payment Status',
    //   flex: 0.5,
    //   renderCell: (params) => {
    //     const color = getInvoiceStatusColor(params.value);
    //     return <Typography style={{ color: color }}>{params.value}</Typography>
    //   }
    // },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        const color = getOrderStatusColor(params.value);
        return <Chip style={{ color: color, borderColor: color }} variant='outlined' size='small' label={params.value} />
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'action',
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              aria-label='settings'
              onClick={(evt) => toggleActionMenu(evt, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id='long-menu'
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={() => toggleActionMenu(null, null)}
            >
              <MenuItem
                key={`view:${params.row.id}`}
                onClick={() => fnViewOrderHandler(selectedOrder)}
              >
                <VisibilityOutlinedIcon fontSize='small' />
                &nbsp;&nbsp;View Order
              </MenuItem>
              <Divider />
              <MenuItem
                key={`edit:${params.row.id}`}
                onClick={() => fnEditOrderHandler(selectedOrder)}
              >
                <EditOutlinedIcon fontSize='small' />
                &nbsp;&nbsp;Edit Order
              </MenuItem>
              <Divider />              
              <MenuItem
                key={`delete:${params.row.id}`}
                onClick={() => fnDeleteOrderHandler(selectedOrder)}
              >
                <DeleteOutlinedIcon fontSize='small' />
                &nbsp;&nbsp;Delete Order
              </MenuItem>              
              <Divider />              
              <MenuItem
                key={`files:${params.row.id}`}
                onClick={() => fnFileUploadHandler(selectedOrder)}
              >
                <AttachFileIcon fontSize='small' />
                &nbsp;&nbsp;Upload Documents
              </MenuItem>
              <Divider />
              <MenuItem
                key={`stats:${params.row.id}`}
                onClick={() => fnOrderStatsHandler(selectedOrder)}
              >
                <EqualizerOutlinedIcon fontSize='small' />
                &nbsp;&nbsp;Order Stats
              </MenuItem>
              <Divider />
              <MenuItem
                key={`notification:${params.row.id}`}
                onClick={() => fnOrderHistoryHandler(selectedOrder)}
              >
                <NotificationsActiveOutlinedIcon fontSize='small' />
                &nbsp;&nbsp;Order History
              </MenuItem>
              <Divider />
              <MenuItem
                disabled={!Boolean(['DELIVERED', 'TERMINAL', 'COMPLETE'].filter(st => st === (selectedOrder && selectedOrder.status)).length)}
                key={`history:${params.row.id}`}
                onClick={() => fnInvoiceHandler(selectedOrder)}
              >
                <ReceiptOutlinedIcon fontSize='small' />
                &nbsp;&nbsp;View Invoice
              </MenuItem>
              <Divider />
              <MenuItem
                key={`map:${params.row.id}`}
                onClick={() => fnMapHandler(selectedOrder)}
              >
                <ExploreOutlinedIcon fontSize='small' />
                &nbsp;&nbsp;View Map
              </MenuItem>
              <Divider />
            </Menu>
          </>
        );
      },
    },
  ];
  return (
    <Box
      m='20px 0 0 0'
      height='75vh'
    >
      <StyledDataGrid
        rows={orders}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
      />{' '}
      {/* Data Grid */}
    </Box>
  );
};

export default IntermodalTableView;