import React, { useState } from 'react';
import {
  Box,
  Container,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import { CloseOutlined, MenuOutlined, SearchOutlined } from '@material-ui/icons';
import theme from 'theme';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: 0,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: '10px',
    margin: '4px',
  },
}));

const Searchbar = ({
  searchType,
  isAdvanceSearchDialogOpen,
  children,
  searchKey,
  searchParameters,
  fnSearchMenuHandler,
  fnOnSearchHandler,
}) => {
  const colors = theme.colors;
  const classes = useStyles();
  const [isCloseButtonVisible, setCloseButtonVisibility] = useState(false);

  const handleDataChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    if (value.length !== 0) {
      const param = {};
      param[`${name}`] = value.trim();
      Object.assign(searchParameters, param);
      setCloseButtonVisibility(true);
    } else {
      setCloseButtonVisibility(false);
    }
  };

  const handleSearch = (evt) => {
    fnOnSearchHandler(searchParameters);
  };

  const clearSearchField = (evt) => {
    searchParameters = Object.entries(searchParameters).filter(item => item['key'] = evt.target.name);
    setCloseButtonVisibility(false);
    // We need to clear this filtered searchOption from the searchParameters and input box.
    Array.from(document.querySelectorAll('input')).forEach(input => {
      if (input.name === 'name') //true
        input.value = null;
    });
    fnOnSearchHandler(searchParameters);
  };

  return (
    <Box>
      <Paper className={classes.root}>
        {searchType === 'ADVANCED' ?
          <IconButton
            aria-label='menu'
            onClick={fnSearchMenuHandler}
          >
            <MenuOutlined />
          </IconButton> : null}
        <InputBase
          label={searchKey}
          className={classes.input}
          placeholder='Search'
          inputProps={{ 'aria-label': 'search orders' }}
          name={searchKey}
          onChange={evt => handleDataChange(evt)}
          endAdornment={isCloseButtonVisible ?
            <IconButton name={searchKey} onClick={(evt) => clearSearchField(evt)}>
              <CloseOutlined fontSize='small' />
            </IconButton> : null
          }
        />
        <IconButton color='primary' aria-label='search' onClick={handleSearch}>
          <SearchOutlined />
        </IconButton>
        {/* Search History - Recent Search --- Future */}
        {/* <IconButton className={classes.iconButton} aria-label='history'>
          <HistoryOutlined />
        </IconButton> */}
      </Paper>
      <Paper
        elevation={3}
        hidden={!isAdvanceSearchDialogOpen}
        style={{
          marginTop: 10,
          position: 'absolute',
          marginLeft: '-10%',
          zIndex: 1000,
        }}
      >
        <Container
          maxWidth='sm'
        >
          <Box m='10px 0 0 0'>
            <Typography variant='h4' style={{ color: colors.title, fontWeight: 'bold' }}>Advanced Search</Typography>
            <Typography variant='subtitle2'>Search for orders with advanced options below</Typography>
          </Box>
          <Box m='20px 0 0 0'>
            {children}
          </Box>
          {/* <Divider orientation='vertical' />
          <Box>
            <Typography variant='h3' style={{ color: colors.title, fontWeight: 'bold'}}>Quick Search</Typography>
            <Typography variant='subtitle2'>Get fast results with these options</Typography>
          </Box> */}
        </Container>
      </Paper>
    </Box>
  );
};

export default Searchbar;