const initState = {
  reports: [],
  report: {},
  toast: {},
  isProcessing: false,
};

export const reportReducer = (state = initState, action) => {
  switch (action.type) {

  case 'PROCESSING_REPORT':
    return {
      ...state,
      toast: { type: 'LOADING', message: 'Please wait ...'},
      isProcessing: true,
    };

  case 'FETCH_REPORT':
    return {
      ...state,
      report: action.payload,
      toast: {},
      isProcessing: false,
    };
  case 'REPORT_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};