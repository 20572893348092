import { Box, Typography } from "@material-ui/core"
import { formatCurrency } from "utils/formatter";

const StatBox = ({
  title,
  statHighlight,
  statBreakdown,
  icon,
  indicator,
}) => {
  return (
    <Box m='10px 0px' width='100%'
      sx={{
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: '#010d4e',
        color: '#fff'
      }}
    >
      <Typography variant='h5'>{title}</Typography>
      <Box textAlign={'center'}>
        <Typography variant='h3' style={{ fontWeight: 600 }}>{statHighlight}</Typography>
      </Box>
      <Box display='flex' justifyContent='space-between' mt={2} textAlign={'center'}>
        {statBreakdown && statBreakdown.map((stat, idx) =>
          <Box key={`${title}-${idx}`}>
            {title === 'Orders' ?
              <>
                <Typography variant='h5' style={{ fontWeight: 600 }}>{stat.value}</Typography>
                <Typography variant='body1' style={{ color: '#717273', textTransform: 'capitalize' }}>
                  {stat.status.toLowerCase()}
                </Typography>
              </> :
              <>
                <Typography variant='h5' style={{ fontWeight: 600 }}>{formatCurrency(stat.value)}</Typography>
                <Typography variant='body1' style={{ color: '#717273', textTransform: 'capitalize' }}>
                  {stat.status.toLowerCase()} {stat.count && `(${stat.count})`}
                </Typography>
              </>
            }
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default StatBox;