import React, { Component } from 'react';
import Page from 'components/Page';
import CTTabs from 'components/CTTabs';
import * as BrokerAction from 'actions/broker-action';
import FileUploader from 'components/FileUploader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataDialog from 'components/dialogs/DataDialog';
import BrokerForm from './broker-form';
import Topbar from 'containers/global/Topbar';
import { Box, Button, ButtonGroup, Chip, CircularProgress, Divider, IconButton, Menu, MenuItem, Switch, Typography } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { EditOutlined, MoreVert } from '@material-ui/icons';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import Searchbar from 'containers/global/Searchbar';
import Notificationbar from 'containers/global/Notificationbar';
import theme from 'theme';

const TABS = ['ACTIVE', 'INACTIVE'];

class BrokerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.formState,
      action: 'Save',
      tabIndex: 0,      
      isDataDialogOpen: false,
      isFileLoaderDialogOpen: false,
      anchorEl: null,
      selectedRow: null,      
      isSearchEnabled: false,  
      searchParameters: {},
    };
  }

  get formState() {
    return {
      broker: {
        name: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        isActive: true,
      },
    };
  }

  columns = [
    { field: 'id', headerName: 'Id', flex: 0.5},
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
    },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'street',
      headerName: 'Street',
      flex: 1,
      valueGetter: (params) => params.row.address.street,
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 1,
      valueGetter: (params) => params.row.address.city,
    },
    {
      field: 'state',
      headerName: 'State', 
      flex: 0.75,
      valueGetter: (params) => params.row.address.state,
    },
    {
      field: 'zip',
      headerName: 'Zip',
      flex: 0.75,
      valueGetter: (params) => params.row.address.zipcode,
    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 0.75,
      valueGetter: (params) => params.row.address.country,
    },
    {
      field: 'status',
      flex: 1,
      headerName: 'Active',
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.active}
            onChange={() => this.toggleActiveStatus(params.row.id)}
            color='secondary'
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              size='small'
              color={'primary'}
              aria-label="more"
              aria-controls="long-menu"
              onClick={(evt) => this.handleMenuClick(evt, params.row)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.closeDialog}
            >
              <MenuItem key="edit" onClick={() => this.editRow(this.state.selectedRow)}>
                <EditOutlined fontSize="small" color='primary' />&nbsp;&nbsp;Edit Broker</MenuItem>
              <Divider />              
            </Menu>
          </>
        );
      },
    },
  ];

  /* Search */
  /* 
    1. User click on search icon to call executeSearch function.
    2. If status is 200, we will display the search results.
    3. A Chip with onDelete property will be created based on {key:value} in the search Parameters
  */
  executeSearch = (searchParameters) => {
    if (searchParameters && Object.entries(searchParameters).length > 0) {
      const value = searchParameters['name'];
      this.setState({
        isSearchEnabled : true,
        searchParameters: searchParameters
      });
      this.props.search(value);
    } else {
      this.setState({
        isSearchEnabled : false,
        searchParameters: {}
      });      
    }
  };

  /* Create Search Chip */
  createSearchChip = () => {
    const kvpair = Object.entries(this.state.searchParameters).map(([k, v]) => ({ 'key': k, 'value': v }));
    const chips = kvpair.map(item => {
      return <Chip key={item.key}
        label={item.value}
        onDelete={(evt) => this.deleteSearchChip(evt, item.key)}
        color='secondary'
        size='small'
      />;
    });
    return chips;
  };
  /* Delete Search Chip and execute Search */
  deleteSearchChip = (evt, key) => {
    const sp = this.state.searchParameters;
    delete sp[key];

    // Execute search on remaining search items if any.
    this.executeSearch(sp);
  };

  saveForm = (broker) => {
    this.props.save(broker);
  };

  updateForm = (broker) => {
    this.props.update(broker);
  };

  toggleDataDialog = () => {
    const { isDataDialogOpen } = this.state;
    this.setState({ isDataDialogOpen: !isDataDialogOpen });
  };

  closeDialog = () => {    
    this.setState({
      ...this.formState,
      isDataDialogOpen: false,
      isFileLoaderDialogOpen: false,
      anchorEl: null,
      selectedRow: null,
      action: 'Save'
    });
    /* Reset TOAST State */
    this.props.resetState('RESET_TOAST_STATE');
  };

  editRow = (row) => {
    this.setState({
      isDataDialogOpen: true,
      action: 'Update',
      broker: { ...row, ...row.address }
    });
  };

  toggleActiveStatus = (id) => {
    this.props.changeStatus(id);
    /* Reset TOAST State */
    this.props.resetState('RESET_TOAST_STATE');
  };

  changeTab = (tabIndex) => {
    this.setState({ tabIndex });
  };

  handleMenuClick = (evt, data) => {
    this.setState({
      anchorEl: evt.currentTarget,
      selectedRow: data
    });
  };

  downloadTemplate = () => {
    const filename = 'broker_template.csv';
    const headers = ['name', 'email', 'phone', 'contact_name', 'street', 'city', 'state', 'country', 'zipcode', 'is_active'];
    let csvContent = 'data:text/csv;charset=utf-8,' + headers;
    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  };

  downloadBrokers = () => {
    const filename = 'brokers.csv';
    const { brokers } = this.props;

    const csvData = [];
    const headers = ['name', 'email', 'phone', 'contact_name', 'street', 'city', 'state', 'country', 'zipcode', 'is_active'];
    csvData.push(headers);

    brokers.forEach(item => {
      let arr = [item.name, item.email, item.phone, item.contactName, item.address.street, item.address.city, item.address.state, item.address.country, item.address.zipcode, item.status];
      csvData.push(arr);
    });
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvData.forEach(function (rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });
    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  };

  render() {
    const { isSearchEnabled } = this.state;
    const { brokers, searched, toast, isProcessing } = this.props;
    
    const selectedTab = TABS[this.state.tabIndex];
    const flag = selectedTab === 'ACTIVE' ? true : false;
    // searched brokers only or all brokers
    let filteredData = [];
    const statusCounts = [];
    const tableData = isSearchEnabled ? searched : brokers;
    if (tableData) {
      filteredData = tableData ? tableData.filter(d => d.active === flag) : [];    
      statusCounts.push(
        {
          'status': 'ACTIVE',
          'count': tableData.filter(d => d.active).length,
        },
        {
          'status': 'INACTIVE',
          'count': tableData.filter(d => !d.active).length,
        }
      );
    }

    return (
      <Page title="Brokers">
        <Box display={'flex'} justifyContent={'space-between'}>
          <Topbar
            title='BROKER'
            subTitle='Manage Your Brokers'
          />
          <Searchbar
            searchType='SIMPLE'
            searchKey='name'
            searchParameters={this.state.searchParameters}
            fnOnSearchHandler={searchParams => this.executeSearch(searchParams)}
          >
          </Searchbar>
          <Notificationbar />
        </Box>
        <Box
          m='20px 0 0 0'
          height='75vh'
        >
          <Box style={{ margin: '10px 0' }}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Button
                variant='contained'
                color='primary'
                onClick={this.toggleDataDialog}
              >
                {'New Broker'}
              </Button>
              {isProcessing && <CircularProgress style={{margin: 'auto'}}/>}
              {toast ? <ToastHandler toast={toast} type={toast.type} /> : null}
              <ButtonGroup
                variant='outlined'
                color='secondary'
              >
                <Button onClick={() => this.setState({ isFileLoaderDialogOpen: true })}>
                  Import
                </Button>
                <Button onClick={this.downloadTemplate}>
                  Template
                </Button>
                <Button onClick={this.downloadBrokers}>Export</Button>
              </ButtonGroup>
            </Box>
          </Box>         
          <Box
            display={'flex'}>
            <Box flex={1}>
              <CTTabs
                labels={TABS}
                counts={statusCounts}
                tabIndex={this.state.tabIndex}
                fnOnTabChangeHandler={this.changeTab}              
              />
            </Box>
            {isSearchEnabled &&
              <Box flex={1}>
                <Typography variant='h5' 
                  style={{ 
                    letterSpacing: 1,
                    color: theme.colors.grey,
                    textTransform: 'uppercase',
                    marginBottom: '2px'
                  }}
                >
                  {`${searched.length} records found`}
                </Typography>
                {this.createSearchChip()}
              </Box>
            }
          </Box>         
          <DataGrid
            rows={filteredData}
            columns={this.columns}
            rowHeight={40}
            disableSelectionOnClick
            
          />
        </Box>
        <DataDialog
          open={this.state.isDataDialogOpen}
          title='Broker Form'
          toast={toast}
          isProcessing={isProcessing}
          onCloseHandler={this.closeDialog}
        >
          <BrokerForm
            action={this.state.action}
            broker={this.state.broker}
            saveFormHandler={this.saveForm}
            updateFormHandler={this.updateForm}
          />
        </DataDialog>
        <DataDialog
          open={this.state.isFileLoaderDialogOpen}
          title='Import'
          toast={toast}
          isProcessing={isProcessing}          
          onCloseHandler={this.closeDialog}
        >
          <FileUploader
            name={'broker'}
          />
        </DataDialog>
      </Page>
    );
  }
}

function mapStatesToProps(state) {
  const { brokers, searched, isProcessing, toast } = state.brokerReducer;
  return {
    brokers,
    searched,
    isProcessing,
    toast
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(BrokerAction, dispatch);
}

// Promote BrokerPage from a component to a container - it needs to know
// about this new dispatch method, selectBroker
export default connect(mapStatesToProps, mapDispatchToProps)(BrokerPage);