import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Divider,
  Drawer,
  IconButton,
  List,
  Typography 
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { logoutUser } from 'services/login-service';
import { DashboardOutlined,
  BusinessCenterOutlined,
  EqualizerOutlined, 
  LocalShippingOutlined, 
  MonetizationOnOutlined, 
  PeopleOutlineOutlined, 
  PowerSettingsNewOutlined, 
  ReceiptOutlined, 
  SettingsApplicationsOutlined,
  TrainOutlined,
} from '@material-ui/icons';
import { deepOrange } from '@material-ui/core/colors';

const drawerWidth = 200;
const menuItems = [
  {
    href: '/app/intermodal',
    title: 'Intermodal',
    icon: <TrainOutlined />
  },
  {
    href: '/app/longhaul',
    title: 'OTR Dispatch',
    icon: <DashboardOutlined />
  },
  {
    href: '/app/brokers',
    title: 'Brokers',
    icon: <PeopleOutlineOutlined />
  },
  {
    href: '/app/companies',
    title: 'Companies',
    icon: <BusinessCenterOutlined />
  },
  {
    href: '/app/equipments',
    title: 'Equipments',
    icon: <LocalShippingOutlined />
  },
  {
    href: '/app/drivers',
    title: 'Drivers',
    icon: <PeopleOutlineOutlined />
  },
  {
    href: '/app/accounting',
    title: 'Accounting',
    icon: <ReceiptOutlined />
  },
  {
    href: '/app/paymanager',
    title: 'Driver Settlement',
    icon: <MonetizationOnOutlined />
  },
  {
    href: '/app/report',
    title: 'Report',
    icon: <EqualizerOutlined />
  },
  {
    href: '/app/settings',
    title: 'Settings',
    icon: <SettingsApplicationsOutlined />
  },
  {
    href: '/app/login',
    title: 'Logout',
    icon: <PowerSettingsNewOutlined/>
  },

];

const handleLogout = (evt) => {
  evt.preventDefault();
  logoutUser().then(() => {
    
    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  });
};


const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center'
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: '#fff',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#000',
    color: '#fff',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
    backgroundColor: '#000',
    color: '#fff',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  avatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    marginTop: 5,
    marginBottom: 20,
    marginLeft: 8,
  },
}));

export default function MenuDrawer() {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton color='secondary' onClick={handleDrawerToggle}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <Divider style={{backgroundColor: '#fff'}} />
        <List>
          {menuItems.map(item => (
            <ListItem key={item.title} button component={RouterLink} to={item.href} selected={item.href === location.pathname}>
              {item.title === 'Logout' ? 
                <ListItemText onClick={handleLogout}>{item.icon}</ListItemText> : 
                <ListItemText>{item.icon}</ListItemText>}
              {open === true ? <ListItemText><Typography variant='h5'>{item.title}</Typography></ListItemText>: null}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
}
