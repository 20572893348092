import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { uploadDataFile } from 'services/fileupload-service';

/**
 * 
 * @param {*} name name of the entity to be uploaded.
 * @returns 
 */

const FileUploader = ({
  name,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [error, setError] = useState(null);
  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    var f = e.target.files[0];
    if (f.size < (5e+6) && f.type === 'text/csv') { // 5MB
      setError(null);
      setSelectedFile(e.target.files[0]);
    } else {
      setSelectedFile({});
      setError('Invalid File or File is too big.');
    }
  };

  const handleUpload = () => {
    if (selectedFile && selectedFile.type === 'text/csv' && !error) {
      const formData = new FormData();
      formData.append('name', name); // driver? broker? etc,
      formData.append('file', selectedFile); // csv file to upload
      setDisabled(true);
      setIsProcessing(true);
      uploadDataFile(formData)
        .then((r) => {
          r = r || {};
          if (r.status === 200 && r.data && r.data.ok === true) {
            setMessage('File has been uploaded, your data will index shortly.');
          } else {
            setError('Failed to upload data. ' + (r.data && r.data.message || ''));
          }
        })
        .catch(() => {
          setError('Failed to upload data');
        })
        .finally(() => {
          setIsProcessing(false);
          setSelectedFile({});
          setDisabled(false);
        });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <input
          type='file'
          ref={fileInput}
          onChange={handleFileInput}
          style={{ display: 'none' }}
        />
        <Button variant='outlined' onClick={() => fileInput.current && fileInput.current.click()} color='primary'>
          Select File
        </Button>
        &nbsp;&nbsp;<span>{selectedFile.name}</span>
      </Grid>
      {isProcessing ? <CircularProgress style={{ margin: 'auto' }} /> : null}
      <Box
        display='flex'
        justifyContent='flex-end'
        p={2}>
        <Button
          variant='contained'
          color='primary'
          onClick={handleUpload}
          disabled={error || !selectedFile.type}
        >
          Upload File
        </Button>
      </Box>
    </Grid>
  );
};

FileUploader.protoTypes = {
  name: PropTypes.string
};

export default FileUploader;