import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  DialogContentText,
  DialogActions,
  Box
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import globalStyle from 'styles/GlobalStyle';
import { deleteFileAttachment, downloadFileAttachment, getFilesByOrderId, uploadFileAttachment } from 'services/order-service';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import InputField from 'components/Input-Field/input-field';

const useStyles = globalStyle;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#2196f3',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const FilesModal = forwardRef(({ order, filesData, errorData, messageData }, ref) => {
  const classes = useStyles();
  const [isProcessing, setIsProcessing] = useState(false);
  const [files, setFiles] = useState(filesData);
  const [selectedFile, setSelectedFile] = useState({});
  const [fileDescription, setFileDescription] = useState('');
  const [error, setError] = useState(errorData);
  const [message, setMessage] = useState(messageData);
  const [rowSelected, setRowSelected] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const handleRowClick = (e, uuid) => {
    setRowSelected(uuid);
  };

  const handleFileDesc = (e) => {
    setFileDescription(e.target.value);
  };

  const handleDelete = () => {
    setIsProcessing(true);
    deleteFileAttachment(order.id, rowSelected).then((res) => {
      if (res.status != 200) {
        setError('Failed to delete file.');
      }
      setRowSelected('');
    }).catch(() => {
      setError('Failed to delete file.');
    }).finally(() => {
      loadFiles();
    });
  };

  const handledownload = () => {
    downloadFileAttachment(rowSelected).then((res) => {
      if (res.status === 200 && res.data && res.data.ok) {
        window.open(res.data.link, '_blank', 'noreferrer');
      } else {
        setError('Failed to download the file.');
      }
    }).catch(() => {
      setError('Failed to download the file.');
    }).finally(() => {
    });
  };

  useImperativeHandle(ref, () => ({
    onInit(data, err) {
      if (err) {
        setError(err.message);
      } else {
        setFiles(data);
      }
      setIsProcessing(false);
    }
  }));


  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    var f = e.target.files[0];
    if (f.size < (5e+6)) { // 5MB
      setError(null);
      setSelectedFile(e.target.files[0]);
    } else {
      setSelectedFile({});
      setError('Invalid File or File is too big.');
    }
  };


  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('fileDesc', fileDescription);
      formData.append('file', selectedFile);
      formData.append('orderId', order.id);
      setIsProcessing(true);
      uploadFileAttachment(formData).then(r => {
        if (r.status === 200 && r.data && r.data.ok) {
          setError(false);
          setMessage('Uploaded successfully.');
          loadFiles();
        } else {
          setError('Failed to upload the file.');
          setIsProcessing(false);
        }
      })
        .catch(() => {
          setError('Failed to upload the file.');
          setIsProcessing(false);
        })
        .finally(() => {
          setFileDescription('');
          setSelectedFile({});
        });
    }
  };

  const loadFiles = () => {
    getFilesByOrderId(order.id).then(res => {
      if (res.status === 200 && res.data) {
        setIsProcessing(false);
        setFiles(res.data);
      } else {
        setError('Unable to get load files.');
      }
    }).catch(e => {
      setError('Unable to get load files.' + e);
    }).finally(() => {
      setTimeout(() => {
        setError('');
        setMessage('');
      }, 10000);
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        {isProcessing ? <CircularProgress style={{ margin: 'auto', marginLeft: '50%' }} /> : null}
        <Grid item xs={12} sm={12}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <input type='file' onChange={handleFileInput} ref={fileInput} style={{ display: 'none' }} />
            <Button className={classes.fileUploadSelectBtn} variant='outlined' onClick={() => fileInput.current && fileInput.current.click()} color='primary' disabled={(files.length >= 5)}>
              Select File
            </Button>
            <Typography variant='subtitle2'>{selectedFile.name}</Typography>
            <InputField
              label='File Description'
              name='fileDescription'
              onChange={handleFileDesc}
              value={fileDescription}
              required={true}
              className={classes.fileDesc}
              margin={'dense'}
            />
            <Button className={(classes.actionButton + ' ' + classes.fileUploadBtn)} onClick={handleUpload} variant='contained' color='primary' disabled={(!selectedFile.type || !fileDescription || files.length >= 5)}>
              Upload
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell component={'th'}>ID</TableCell>
                  <TableCell component={'th'} align='right'>File Name</TableCell>
                  <TableCell component={'th'} align='right'>Description</TableCell>
                  <TableCell component={'th'} align='right'>Size</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((row) => (
                  <TableRow
                    className={(rowSelected == row.uuid ? classes.isSelected : '') + ' ' + classes.fileDataRow}
                    tid={row.uuid}
                    onClick={(e) => handleRowClick(e, row.uuid)}
                    key={row.uuid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell scope='row'>{row.id}</TableCell>
                    <TableCell align='right'>{row.fileName}</TableCell>
                    <TableCell align='right'>{row.fileDesc}</TableCell>
                    <TableCell align='right'>{(row.size / 1024).toFixed(2) + ' KB'}</TableCell>
                  </TableRow>)
                )}
              </TableBody>
            </Table>
            {
              files.length == 0 && (<div style={{ width: '100%', textAlign: 'center', padding: '15px' }}>
                <h5>No Files Found. Attach a file to the load to see files here.</h5>
              </div>)
            }
          </TableContainer>

        </Grid>
        <Grid item xs={12} sm={12}>
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
            >
              <Button onClick={() => { setDeleteConfirmation(true); }} variant='outlined' color='primary' disabled={(!rowSelected)} style={{marginRight: '2px'}}>
                Delete
              </Button>
              <Button onClick={handledownload} variant='contained' color='primary' disabled={(!rowSelected)} style={{marginLeft: '2px'}}>
                Download
              </Button>
          </Box>
          {files.length >= 5 && <p style={{ display: 'inline-flex' }}>Max number of files have been attached to this load.</p>}
          <Dialog
            open={deleteConfirmation}
            onClose={() => { setDeleteConfirmation(false); }}
          >
            <DialogTitle id='alert-dialog-title'>
              {'Delete File?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {'Do you want to delete the selected file?'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { setDeleteConfirmation(false); }}>No</Button>
              <Button onClick={() => { setDeleteConfirmation(false); handleDelete(); }} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ToastHandler toast={{ message: error ? error : message }} type={error ? 'ERROR' : 'SUCCESS'} />
      </Grid>
    </React.Fragment>
  );
});

export default FilesModal;