import React, { useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { COUNTRIES, USStates, CAProvinces } from 'utils/constant.js';
import InputField from 'components/Input-Field/input-field';
import SelectField from 'components/Select-Field/select-field';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const NextStatusModal = ({
  actions,
  yard_address,
  fnOnNextStatusClick
}) => {
  const [isAddressFormVisible, setAddressFormVisible] = useState(false);
  const [command, setCommand] = useState(null);

  const formik = useFormik({
    initialValues: { ...yard_address },

    validationSchema: Yup.object().shape({
      street: Yup.string().max(100).required('City Name is required.'),
      city: Yup.string().min(2).max(50).required('City Name is required.'),
      state: Yup.string().min(2).max(25).required('State Name is required.'),
      country: Yup.string().max(25).required('Country Name is required.'),
    }),

    onSubmit: values => {
      const trimmed_data = {};
      Object.keys(values).forEach(key => trimmed_data[key] = typeof (values[key]) === 'string' ? values[key].trim() : values[key]);
      fnOnNextStatusClick(command, trimmed_data);
    },
  });

  const onNextStatusClick = (command) => {
    if (command === 'YARD_IN' || command === 'YARD_PULL' || command === 'STREET_TURN' ||
      command === 'DRYRUN' || command === 'CONTAINER_DROP') {
      setCommand(command);
      setAddressFormVisible(true);
    } else {
      fnOnNextStatusClick(command);
    }
  };

  const renderAddressForm = () => {
    return <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant='subtitle2'>Please provide destination address</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <InputField
            label='Street'
            name='street'
            value={formik.values.street}
            variant='outlined'
            fullWidth
            error={Boolean(formik.touched.street && formik.errors.street)}
            helperText={formik.touched.street && formik.errors.street}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <InputField
            label='City'
            name='city'
            value={formik.values.city}
            variant='outlined'
            fullWidth
            error={Boolean(formik.touched.city && formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectField
            label='Select Country'
            name='country'
            value={formik.values.country}
            variant='outlined'
            fullWidth
            error={Boolean(formik.touched.country && formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            options={COUNTRIES}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectField
            label='Select State/Province'
            name='state'
            select
            value={formik.values.state}
            variant='outlined'
            fullWidth
            error={Boolean(formik.touched.state && formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            options={formik.values['country'] === 'USA' ? USStates : CAProvinces}
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
        >OK
        </Button>
        <Button color='primary' onClick={() => setAddressFormVisible(false)}>Cancel</Button>
      </Box>
    </form>;
  };
  return (
    <Box>
      <Box display='flex' justifyContent={'space-evenly'}>
        {actions && actions.length === 0 ? <Typography variant='subtitle2' style={{ margin: 'auto' }}>No Next Action</Typography> :
          actions.map((status) => {
            return (
              <Button key={`leg-${status}`}
                variant='contained'
                color='primary'
                onClick={() => onNextStatusClick(status.command)}
              >
                {status.command}
              </Button>
            );
          })
        }
      </Box>
      {isAddressFormVisible ?
        <Paper
          elevation={2}
          style={{
            padding: '5px',
            marginTop: '5px',
          }}
        >
          {renderAddressForm()}
        </Paper> : null}
    </Box>
  );
};

export default NextStatusModal;