import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, getIn } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';

import InputField from 'components/Input-Field/input-field';
import 'rsuite/dist/rsuite.min.css';
import { DatePicker } from 'rsuite';
import '../../styles/rsuite-component.css';
import CustomSelectField from 'components/Select-Field/custom-select';

const OrderLocation = (props) => {
  const {
    origin,
    destination,
    locationType,
    updateFormHandler,
    companies,
  } = props;

  const OriginSchema = Yup.object().shape({
    origin: Yup.object().shape({
      name: Yup.string().required('Origin Name is required.'),
      appointment: Yup.string().required('Origin appointment is required'),
    })
  });

  const DestinationSchema = Yup.object().shape({
    destination: Yup.object().shape({
      name: Yup.string().required('Origin Name is required.'),
      appointment: Yup.string().required('Origin appointment is required'),
    })
  });

  const getLocationLabel = (location) => {
    const primaryText = location.name;
    const secondaryText = location.address && `${location.address.street},${location.address.city},${location.address.state},${location.address.country}`;
    const reactSelectObj = {
      label:<Box key={`id:${primaryText}`}>
        <Typography variant='h6'>{primaryText}</Typography>
        <Typography variant='subtitle2' style={{fontSize: '11px'}}>{secondaryText}</Typography>
      </Box>,
      value: primaryText
    };
    return reactSelectObj;
  };

  let schema = OriginSchema;
  if(locationType === 'Origin') {
    origin.name = getLocationLabel(origin.name);
  } else {
    schema = DestinationSchema;
    destination.name = getLocationLabel(destination.name);
  }

  return (
    locationType === 'Origin' ?
      <Formik
        initialValues={{ origin }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={values => {
          const originName = values.origin.name.value;
          values.origin.name = originName;          
          updateFormHandler(values.origin);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          setFieldValue          
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <CustomSelectField
                  type='LOCATION'
                  id='origins'
                  label='Origin Name'
                  name='origin.name'
                  data={companies.map(c => getLocationLabel(c))}
                  value={values.origin.name}
                  onChange={selected => setFieldValue('origin.name', selected)}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <DatePicker
                  format='MM/dd/yyyy hh:mm aa'
                  showMeridiem                    
                  showWeekNumbers
                  name='origin.appointment'
                  value={values.origin.appointment}
                  error={Boolean(
                    getIn(touched, 'origin.appointment') &&
                    getIn(errors, 'origin.appointment')
                  )}
                  helperText={Boolean(
                    getIn(touched, 'origin.appointment') &&
                    getIn(errors, 'origin.appointment')
                  )}
                  required={true}
                  onChange={(date) => setFieldValue('origin.appointment', date)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <InputField
                  type='text'
                  label='Open Time Appointment'
                  name='origin.openAppointment'
                  value={values.origin.openAppointment}
                  variant='outlined'
                  fullWidth
                  error={Boolean(touched.openAppointment && errors.openAppointment)}
                  helperText={touched.openAppointment && errors.openAppointment}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin='dense'
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <InputField
                  type='text'
                  label='PU-Number'
                  name='origin.puNumber'
                  value={values.origin.puNumber}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  error={Boolean(touched.puNumber && errors.puNumber)}
                  helperText={touched.puNumber && errors.puNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <InputField
                  type='text'
                  label='PU-Location'
                  name='origin.puLocation'
                  value={values.origin.puLocation}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  error={Boolean(touched.puLocation && errors.puLocation)}
                  helperText={touched.puLocation && errors.puLocation}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <InputField
                  label='Notes / Comments'
                  type='text'
                  name='origin.notes'
                  value={values.origin.notes}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  error={Boolean(touched.notes && errors.notes)}
                  helperText={touched.notes && errors.notes}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Box display='flex' justifyContent='flex-end' p={2}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
              >
                {'Update'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik> :
      <Formik
        initialValues={{ destination }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={values => {
          const destinationName = values.destination.name.value;
          values.destination.name = destinationName;
          updateFormHandler(values.destination);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          setFieldValue 
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <CustomSelectField
                  type='LOCATION'
                  id='destinations'
                  label='Destination Name'
                  name='destination.name'
                  data={companies.map(c => getLocationLabel(c))}
                  value={values.destination.name}
                  onChange={selected => setFieldValue('destination.name', selected)}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <DatePicker
                  format='MM/dd/yyyy hh:mm aa'
                  showMeridiem                    
                  showWeekNumbers
                  name='destination.appointment'
                  value={values.destination.appointment}
                  error={Boolean(
                    getIn(touched, 'destination.appointment') &&
                    getIn(errors, 'destination.appointment')
                  )}
                  helperText={Boolean(
                    getIn(touched, 'destination.appointment') &&
                    getIn(errors, 'destination.appointment')
                  )}
                  required={true}
                  onChange={(date) => setFieldValue('destination.appointment', date)}
                  fullWidth
                  margin='dense'
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <InputField
                  type='text'
                  label='Open Time Appointment'
                  name='destination.openAppointment'
                  value={values.destination.openAppointment}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  error={Boolean(touched.openAppointment && errors.openAppointment)}
                  helperText={touched.openAppointment && errors.openAppointment}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  dense
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <InputField
                  type='text'
                  label='Reference Number'
                  name='destination.referenceNumber'
                  value={values.destination.referenceNumber}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  error={Boolean(touched.referenceNumber && errors.referenceNumber)}
                  helperText={touched.referenceNumber && errors.referenceNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <InputField
                  label='Notes / Comments'
                  type='text'
                  name='destination.notes'
                  value={values.destination.notes}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  error={Boolean(touched.notes && errors.notes)}
                  helperText={touched.notes && errors.notes}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Box display='flex' justifyContent='flex-end' p={2}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
              >
                {'Update'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
  );
};
function mapStatesToProps(state) {
  // Whatevet is returned will show up as props
  const { companies } = state.companyReducer;
  return {
    companies,
  };
}

export default connect(mapStatesToProps)(OrderLocation);
