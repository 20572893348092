import * as InvoiceService from '../services/invoice-service';

export function resetState(state) {
  return function (dispatch) {
    dispatch(reset(state));
  };
}

export function search(params) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await InvoiceService.search(params);
      dispatch(success('SEARCH_INVOICES', response.data));
    } catch(err) {
      dispatch(error('INVOICE_ERROR', err));
    }
  };
}

export function findInvoices(status, params) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await InvoiceService.list(status, params);
      dispatch(success('FETCH_INVOICES', response.data));
    } catch(err) {
      dispatch(error('INVOICE_ERROR', err));
    }
  };
}

export function generateInvoice(invoiceIdList, date) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await InvoiceService.generateInvoice(invoiceIdList, date);
      dispatch(success('GENERATE_INVOICE', response.data));      
    } catch(err) {
      dispatch(error('INVOICE_ERROR', err));
    }
  };
}

export function unInvoice(invoiceIdList) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await InvoiceService.unInvoice(invoiceIdList);
      dispatch(success('UN_INVOICE', response.data));
    } catch(err) {
      dispatch(error('INVOICE_ERROR', err));
    }
  };
}

export function processPayment(payment) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await InvoiceService.processInvoicePayment(payment);
      dispatch(success('PROCESS_PAYMENT', response.data));
    } catch(err) {
      dispatch(error('INVOICE_ERROR', err));
    }
  };
}

export function save(invoice) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await InvoiceService.save(invoice);
      return dispatch(success('ADD_INVOICE', response.data));
    } catch (err) {
      return dispatch(error('INVOICE_ERROR', err));
    }
  };
}

export function update(invoice) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await InvoiceService.update(invoice);
      return dispatch(success('UPDATE_INVOICE', response.data));
    } catch (err) {
      return dispatch(error('INVOICE_ERROR', err));
    }
  };
}

export function getInvoiceById(invoiceId) {
  return function (dispatch) {
    return InvoiceService.getInvoiceById(invoiceId)
      .then(response => {
        dispatch(success('LOAD_INVOICE_DETAIL_SUCCESS', response.data));
      });
  };
}

export function saveCheck(invoiceId, checkData) {
  return function (dispatch) {
    dispatch(process());
    return InvoiceService.saveCheck(invoiceId, checkData)
      .then(response => dispatch(success('SAVE_INVOICE_CHECK_SUCCESS', response)))
      .catch(err => dispatch(error('SAVE_CHECK_FAILED', err)));
  };
}

function reset(type) {
  return {
    type: type,
  };
}

function process() {
  return {
    type: 'PROCESSING_INVOICE',
  };
}

function success(type, data) {
  return {
    type: type,
    payload: data,
  };
}
function error(type, err) {
  let message;
  if (err.response) {
    // Request made and server responded
    const { data } = err.response;
    message = data && data.message;
  } else if (err.request) {
    // The request was made but no response was received
    message = err.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    message = err.message;
  }
  return {
    type: type,
    payload: message,
  };
}