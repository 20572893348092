import React, { Component } from 'react';
import { Box, Button, ButtonGroup, CircularProgress, Divider, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import Page from 'components/Page';

import CTTabs from 'components/CTTabs';

import * as TenantAction from 'actions/tenant-action';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TenantToolbar from './tenant-toolbar';
import TenantSetting from './tenant-settings';

import PaymentForm from '../stripe/payment-form';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { initializeStripePayment } from 'services/tenant-service.js';
import UserForm from './user-form';
import TenantForm from './tenant-form';
import FormDialog from 'components/dialogs/DataDialog';
import Topbar from 'containers/global/Topbar';
import { DeleteOutline, EditOutlined, LockOutlined, MoreVert } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import Searchbar from 'containers/global/Searchbar';
import Notificationbar from 'containers/global/Notificationbar';

// const stripePromise = loadStripe('pk_test_51MaQ3fFKqpVXUZ6BYtZMjoB1v6ru4QLqRJeClHHALCartW9r5gHdpmWjbZa1Gn3d6AIjKMCelRX5OKUba6b8XfG700qn4WtWEx');
const stripePromise = loadStripe('pk_test_51OGOwUC7RMqqI3r7AErRxKOaeoEZJp16qxAMBWrlr5wwBpRwA3RGRyPYzNB5mFNC3MRia2T80aqblyah6z6bTVU000PR39jJAr');
class TenantPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.formState,
      action: 'Save',
      tabIndex: 0,      
      isTenantDialogOpen: false,
      isUserFormDialogOpen: false,
      isPaymentFormOpen: false,
      clientSecret: '',
      anchorEl: null,
      selectedRow: null,
    };
  }

  get formState() {
    return {
      tenant: {
        tenantId: '',
        company: '',
        email: '',
        phone: '',
        accountPlan: '',
        accountStatus: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        isActive: true,
      },
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        role: [],
      },
      reset_password: {
        password: '',
        confirmPassword: '',
      },
      tenantSetting: {
        startOrderNumber: '',
        smsNotificationEnabled: false,
        emailNotificationEnabled: false,
        driverNotificationEnabled: false,
        eodOrderReportEnabled: false,
        eodInvoiceReportEnabled: false,
        eodPaymentReportEnabled: false,
        orderStatusMessageEnabled: false,
        invoiceGeneratedMessageEnabled: false,
        driverPaymentMessageEnabled: false,
      },
    };
  }

  columns = [
    { field: 'id', headerName: 'Id' },
    {
      field: 'firstName',
      headerName: 'First name',
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      flex: 1,
    },
    { field: 'username', headerName: 'Username', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'role', headerName: 'Role', flex: 1, renderCell: (param) => {
      return param.row.role.array.forEach(element => {
        <Typography>{element}</Typography>
      });
    }},
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              size='small'
              color={'primary'}
              aria-label="more"
              aria-controls="long-menu"
              onClick={(evt) => this.handleMenuClick(evt, params.row)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.closeDialog}
            >
              <MenuItem key="edit" onClick={() => this.editUser(this.state.selectedRow)}><EditOutlined fontSize="small" color='primary' />&nbsp;&nbsp;Edit</MenuItem>
              <Divider />
              <MenuItem key="delete" onClick={() => this.deleteUser(this.state.selectedRow)}><DeleteOutline fontSize="small" color='secondary' />&nbsp;&nbsp;Delete</MenuItem>
              <Divider />
              {/* <MenuItem key="reset" disabled={!isAdmin} onClick={() => this.resetUserPassword(true)}><LockOutlined fontSize="small" color='primary' />&nbsp;&nbsp;Password Reset</MenuItem> */}
            </Menu>
          </>
        );
      },
    },
  ];

  /* Stripe Payment Dialog */
  showPaymentDialog = (tenant) => {
    const tenantPaymentModel = {
      planType: tenant.accountPlan,
      amount: tenant.paymentAmount,
    };
    initializeStripePayment(tenantPaymentModel).then(res => {
      if (res && res.status === 200) {
        this.setState({ isPaymentFormOpen: true, clientSecret: res.data.clientSecret });
      } else {
        alert('Unable to setup payment.');
      }
    });
  };

  editTenantInformation = (tenant) => {
    this.setState({
      isTenantDialogOpen: true,
      tenant: tenant,
    });
  };

  saveUser = (user) => {
    this.props.saveUser(user);
  };

  updateUser = (user) => {
    this.props.updateUser(user);
  };

  updateTenant = (tenant) => {
    this.props.updateTenant(tenant);
  };

  saveTenantSetting = (tenantSetting, scheduler) => {
    this.props.saveTenantSetting(tenantSetting);
    if (scheduler != null) {
      this.props.createSchedule(scheduler);
    } else {
      this.props.deleteSchedule();
    }
  };

  editUser = (row) => {
    this.setState({
      isUserFormDialogOpen: true,
      action: 'Update',
      user: { ...row }
    });
  };

  deleteUser = () => {
    //alert("Delete Clicked: " + row);
  };

  resetUserPassword = (passwordForm) => {
    this.props.resetPasswordByAdmin(passwordForm);
  };

  closeDialog = () => {
    this.setState({
      isTenantDialogOpen: false,
      isUserFormDialogOpen: false,
      isPaymentFormOpen: false,
      anchorEl: null,
      selectedRow: null,
      action: 'Save'
    });
    /* Reset TOAST State */
    this.props.resetState('RESET_TOAST_STATE');    
  };

  changeTab = (tabIndex) => {
    this.setState({ tabIndex });
  };

  handleMenuClick = (evt, data) => {
    this.setState({
      anchorEl: evt.currentTarget,
      selectedRow: data
    });
  };

  render() {
    const { tenant, users, isProcessing, toast, scheduler } = this.props;
    const { clientSecret } = this.state; // Stripe Payment    
    const logged_user = users && users.filter(u => u.email === localStorage.ACTIVE_USER_EMAIL)[0];
    const isAdmin = logged_user && logged_user.role === 'ADMIN' ? true : false;

    /* Stripe Payment Setting */
    const appearance = {
      theme: 'stripe',
    };
    const options = {
      clientSecret,
      appearance,
    };

    return (
      <Page title="Tenant">
        <Box display={'flex'} justifyContent={'space-between'}>
          <Topbar
            title='MY ACCOUNT'
            subTitle='Manage Your Profile'
          />
          <Notificationbar />
        </Box>
        <Box
          m='20px 0 0 0'
          height='75vh'
        >
          <TenantToolbar
            tenant={tenant}
            fnHandleEditTenantDialog={() => this.editTenantInformation(tenant)}
            fnOnPaymentHandler={() => this.showPaymentDialog(tenant)}
          />
          <Box m='20px 0 0 0'>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => this.setState({ isUserFormDialogOpen: true })}
              >
                {'New User'}
              </Button>
              <ButtonGroup
                variant='outlined'
                color='secondary'
              >
                <Button onClick={() => setFileUploadDialogOpen(true)}>
                  Import
                </Button>
                <Button onClick={this.downloadTemplate}>
                  Download Template
                </Button>
                <Button onClick={this.downloadBrokers}>Export</Button>
              </ButtonGroup>
            </Box>
            <DataGrid
              rows={users}
              columns={this.columns}
              rowHeight={40}
              disableSelectionOnClick
            />
          </Box>
          <Box style={{ margin: '10px 0' }}>
            {isProcessing ? <CircularProgress style={{ position: 'absolute', top: '50%', marginLeft: '50%' }} /> :
              <TenantSetting
                tenantSetting={tenant && tenant.tenantSetting ? tenant.tenantSetting : {}}
                scheduler={scheduler}
                toast={toast}
                fnOnSaveFormHandler={this.saveTenantSetting}
              />
            }
          </Box>
        </Box>
        <FormDialog
          open={this.state.isUserFormDialogOpen}
          title='User Form'
          onCloseHandler={this.closeDialog}
        >
          <UserForm
            action={this.state.action}
            user={this.state.user}
            saveFormHandler={this.saveUser}
            updateFormHandler={this.updateUser}
          />          
        </FormDialog>
        <FormDialog
          open={this.state.isTenantDialogOpen}
          title='Tenant Form'
          onCloseHandler={this.closeDialog}
        >
          <TenantForm
            action={'Update'}
            tenant={this.state.tenant}
            updateFormHandler={this.updateTenant}
          />        
        </FormDialog>
        {this.state.clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <PaymentForm
              open={this.state.isPaymentFormOpen}
              clientSecret={this.state.clientSecret}
              fnOnCloseDialogHandler={this.closeDialog}
            />
          </Elements>)}
      </Page>
    );
  }
}

function mapStatesToProps(state) {
  const { tenant, user, users } = state.tenantReducer;  
  const { toast, isProcessing } = state.tenantReducer;
  const { scheduler, scheduleToast, isScheduleProcessing } = state.schedulerReducer;

  return {
    tenant,
    user,
    users,
    scheduler,
    toast,
    isProcessing,
    scheduleToast,
    isScheduleProcessing,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(TenantAction, dispatch);
}

// Promote TenantPage from a component to a container - it needs to know
// about this new dispatch method, selectTenant
export default connect(mapStatesToProps, mapDispatchToProps)(TenantPage);