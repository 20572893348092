import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, getIn } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Box,
  Grid,
  TextField,
  FormLabel,
  Typography,
} from '@material-ui/core';

import InputField from 'components/Input-Field/input-field';
import 'react-widgets/dist/css/react-widgets.css';
import { Toggle } from 'rsuite';
import CustomSelectField from 'components/Select-Field/custom-select';

const OrderEquipment = (props) => {
  const {
    container,
    chassis,
    equipmentType,
    orderType,
    updateFormHandler,
    equipments,
  } = props;

  const ContainerSchema = Yup.object().shape({
    container: Yup.object().shape({
      deliveryContainerNumber: Yup.string().required('Delivery Container Number is required.'),
    })
  });

  const TrailerSchema = Yup.object().shape({
    deliveryContainerNumber: Yup.string().required('Delivery Container Number is required.'),
  });

  const schema = equipmentType === 'Container' ? ContainerSchema : TrailerSchema;

  const getEquipmentLabel = (equipment, type) => {
    let primaryText = '';
    let secondaryText = '';
    if (type === 'CONTAINER') {
      primaryText = equipment.line;
      secondaryText = `Free Days: ${equipment.freeDays}`;
    } else if (type === 'CHASSIS') {
      primaryText = equipment.company;
      secondaryText = `Rent: $${equipment.rent}`;
    }

    const reactSelectObj = {
      label:<Box>
        <Typography variant='h6'>{primaryText}</Typography>
        <Typography variant='subtitle2' style={{fontSize: '11px'}}>{secondaryText}</Typography>
      </Box>,
      value: primaryText
    };
    return reactSelectObj;
  };

  if(container && container.line) {
    container.line = getEquipmentLabel(container, 'CONTAINER');
  }
  if(chassis && chassis.company) {
    chassis.company = getEquipmentLabel(chassis, 'CHASSIS');
  }

  return (
    equipmentType === 'Container' ?
      <Formik
        initialValues={{ container }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={values => {
          if(values.container.line) {
            const line = values.container.line.value;
            values.container.line = line;
          }
          updateFormHandler(values.container);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          setFieldValue,          
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <CustomSelectField
                  type='CONTAINER'
                  id='containers'
                  label='Container Line'
                  name='container.line'
                  data={equipments.filter(e=>e.type==='CONTAINER').map(b => getEquipmentLabel(b, 'CONTAINER'))}
                  value={values.container.line}
                  onChange={selected => setFieldValue('container.line', selected)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputField
                  type='text'
                  label='Delivery Container Number'
                  name='container.deliveryContainerNumber'
                  value={values.container.deliveryContainerNumber}
                  variant='outlined'
                  fullWidth
                  error={Boolean(
                    getIn(touched, 'container.deliveryContainerNumber') &&
                    getIn(errors, 'container.deliveryContainerNumber')
                  )}
                  helperText={Boolean(
                    getIn(touched, 'container.deliveryContainerNumber') &&
                    getIn(errors, 'container.deliveryContainerNumber')
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin='dense'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id='date'
                  label='Last Free Day'
                  type='date'
                  name='container.lastFreeDate'
                  defaultValue={values.container.lastFreeDate}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth={true}
                  margin='dense'
                  variant='outlined'
                  error={Boolean(touched.lastFreeDate && errors.lastFreeDate)}
                  helperText={touched.lastFreeDate && errors.lastFreeDate}
                  style={{
                    marginTop: '0px'
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputField
                  type='text'
                  label='Pickup Container Number'
                  name='container.pickupContainerNumber'
                  value={values.container.pickupContainerNumber}
                  variant='outlined'
                  fullWidth
                  error={Boolean(
                    getIn(touched, 'container.pickupContainerNumber') &&
                    getIn(errors, 'container.pickupContainerNumber')
                  )}
                  helperText={Boolean(
                    getIn(touched, 'container.pickupContainerNumber') &&
                    getIn(errors, 'container.pickupContainerNumber')
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin='dense'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputField
                  type='text'
                  label='Booking Number'
                  name='container.bookingNumber'
                  onChange={handleChange}
                  value={values.container.bookingNumber}
                  fullWidth={true}
                  required={false}
                  disabled={orderType !== 'PICKUP'}
                  margin='dense'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabel component='legend' style={{margin: '-5px 0px 5px'}}>Is DropLocation</FormLabel>
                <Toggle
                  name='container.isCustomDropLocation'
                  value={values.container.isCustomDropLocation}
                  checkedChildren='YES'
                  unCheckedChildren='NO'
                  defaultChecked={values.container.isCustomDropLocation}
                  onChange={(val) => setFieldValue('container.isCustomDropLocation', val)}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  type='text'
                  name='container.dropLocation'
                  label='Drop Location'
                  value={values.container.dropLocation}
                  fullWidth={true}
                  disabled={container.isCustomDropLocation === 'FALSE'}
                  error={Boolean(touched.dropLocation && errors.dropLocation)}
                  helperText={touched.dropLocation && errors.dropLocation}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin='dense'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  type='text'
                  name='container.notes'
                  label='Container Notes'
                  value={values.container.notes}
                  fullWidth={true}
                  required={false}
                  error={Boolean(touched.notes && errors.notes)}
                  helperText={touched.notes && errors.notes}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin='dense'
                  size='small'
                />
              </Grid>
            </Grid>
            <Box display='flex' justifyContent='flex-end' p={2}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
              >
                {'Update'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik> :
      <Formik
        initialValues={{ chassis }}
        enableReinitialize
        onSubmit={values => {
          if(values.chassis.company) {
            const company = values.chassis.company.value;
            values.chassis.company = company;
          }
          if(values.chassis.pickupChassisCompany) {
            const company = values.chassis.pickupChassisCompany.value;
            values.chassis.pickupChassisCompany = company;
          }
          updateFormHandler(values.chassis);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <CustomSelectField
                  type='CHASSIS'
                  id='chassis'
                  label='Chassis Company'
                  name='chassis.company'
                  data={equipments.filter(e=>e.type==='CHASSIS').map(b => getEquipmentLabel(b, 'CHASSIS'))}
                  value={values.chassis.company}
                  onChange={selected => setFieldValue('chassis.company', selected)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputField
                  type='text'
                  label='Chassis Number'
                  name='chassis.chassisNumber'
                  value={values.chassis.chassisNumber}
                  variant='outlined'
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin='dense'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormLabel component='legend' style={{margin: '-5px 0px 5px'}}>Chassis Split</FormLabel>
                <Toggle
                  name='chassis.isChassisSplit'
                  value={values.chassis.isChassisSplit}
                  checkedChildren='YES'
                  unCheckedChildren='NO'
                  defaultChecked={values.chassis.isChassisSplit}
                  onChange={(val) => setFieldValue('chassis.isChassisSplit', val)}
                  fullWidth={true}
                />
              </Grid>
              {orderType === 'DROP_PICKUP' ? <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <CustomSelectField
                    type='CHASSIS'
                    id='chassis'
                    label='Pick up Chassis Company'
                    name='chassis.pickupChassisCompany'
                    data={equipments.filter(e=>e.type==='CHASSIS').map(b => getEquipmentLabel(b, 'CHASSIS'))}
                    value={values.chassis.pickupChassisCompany}
                    onChange={selected => setFieldValue('chassis.pickupChassisCompany', selected)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    type='text'
                    label='Pickup Chassis Number'
                    name='chassis.pickupChassisNumber'
                    value={values.chassis.pickupChassisNumber}
                    variant='outlined'
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin='dense'
                    size='small'
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormLabel component='legend' style={{margin: '-5px 0px 5px'}}>Pickup Chassis Split</FormLabel>
                  <Toggle
                    name='chassis.isPickupChassisSplit'
                    value={values.chassis.isPickupChassisSplit}
                    checkedChildren='YES'
                    unCheckedChildren='NO'
                    defaultChecked={values.chassis.isPickupChassisSplit}
                    onChange={(val) => setFieldValue('chassis.isPickupChassisSplit', val)}
                    fullWidth={true}
                  />
                </Grid>
              </React.Fragment> : null}
            </Grid>
            <Box display='flex' justifyContent='flex-end' p={2}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
              >
                {'Update'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
  );
};

function mapStatesToProps(state) {
  // Whatevet is returned will show up as props
  const { equipments } = state.equipmentReducer;
  return {
    equipments,
  };
}

export default connect(mapStatesToProps)(OrderEquipment);