import React from 'react';
import Select from 'react-select';

const CustomSelectField = (props) => {
  const { label, name, value, fullWidth, onBlur, onChange, data } = props;
  return (
    <Select
      id='custom-selector'
      label={label}
      name={name}
      placeholder={label}
      styles={{ menu: (props) => ({ ...props, zIndex: 1500 }) }}
      options={data}
      value={value}
      isClearable
      fullWidth={fullWidth}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
export default CustomSelectField;