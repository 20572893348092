import React, { useState } from 'react';
import {
  Button,
  Box,
  Grid,
  Typography,
  Switch,
} from '@material-ui/core';
import InputField from 'components/Input-Field/input-field';
import { DataGrid } from '@mui/x-data-grid';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';


const AssignDriver = ({
  action,
  drivers,
  rate,
  fnOnChangeDriverAvailabilityHandler,
  fnOnAssignDriverHandler,
}) => {
  
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [driverRate, setDriverRate] = useState(rate);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  
  const selectDriver = (driver) => {
    setSelectedDriver(driver);
    setConfirmationDialogOpen(true);
  };

  const assignSelectedDriver = () => {
    selectedDriver.rate = Number.parseFloat(driverRate);
    fnOnAssignDriverHandler(selectedDriver);
    setConfirmationDialogOpen(false);
  };

  const changeDriverAvailability = (driver) => {
    fnOnChangeDriverAvailabilityHandler(driver);
  };

  const handleDataChange = (e) => {
    const rate = e.target.value;
    setDriverRate(rate);
  };

  const columns = [
    { field: 'id', headerName: 'Id' },
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 1,
    },
    { field: 'driverType', headerName: 'Type', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'licenseNumber', headerName: 'LicenseNumber', flex: 1 },
    { field: 'licenseExpiryDate', headerName: 'License Exp Date', flex: 1 },
    { field: 'available', headerName: 'Available', flex: 1, 
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.available}
            onChange={() => changeDriverAvailability(params.row)}
            color="secondary"
            label="Available"
          />
      )}
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => {
        return (
          <Button 
            color='primary'
            variant='outlined'
            onClick={() => selectDriver(params.row)} 
          >Select
          </Button>
        );
      },
    },
  ];
  
  return (
    <React.Fragment>
      <Box
        m='10px 0 0 0'
        height='50vh'
      >
        <DataGrid
          rows={drivers}
          columns={columns}
          rowHeight={40}
          disableSelectionOnClick
        />
      </Box>
      {selectedDriver &&
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          title={'Assign Driver'}
          handleConfirm={() => assignSelectedDriver()}
          handleCancel={() => setConfirmationDialogOpen(false)}
          handleCloseFunc={() => setConfirmationDialogOpen(false)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              {action === 'Assign' ?
                <React.Fragment>
                  <Typography variant='subtitle2'>{`Assign New Driver: ${selectedDriver.fullName}`}</Typography>
                  <br/>
                  <br/>
                  <Typography variant='h6'>{'Please enter driver rate.'}</Typography>
                </React.Fragment>:
                <React.Fragment>
                  <Typography variant='subtitle2'>{`Change driver to: ${selectedDriver.fullName} ?`}</Typography>
                  <br/>
                  <br/>                  
                </React.Fragment>}
            </Grid>
            <br/>
            <br/>
            <Grid item xs={12} sm={12}>
              <InputField
                autoFocus
                type="number"
                label="Enter Driver Rate"
                name="driverRate"
                onChange={handleDataChange}
                value={driverRate}
                isFullWidth={true}
                valid />
            </Grid>            
          </Grid>
        </ConfirmationDialog>}   
    </React.Fragment>
  );
};

export default AssignDriver;
